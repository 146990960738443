import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";
import { HTTPMSG } from "config";
import { GuestRegisterInputs } from "./components/guest-detail/GuestDetail";
import { GuestCheckoutService } from "./guest-checkout.service";
import { setLocalStore } from "utils/encryption";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
  data: {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    expiry: "",
    token: "",
  },
};

export const guestCheckoutDetailsRegister: any = createAsyncThunk(
  "guestCheckoutRegister",
  async (payload: GuestRegisterInputs, { rejectWithValue }) => {
    try {
      const response: any = await GuestCheckoutService.guestCheckoutService(
        payload
      );
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const guestCheckoutSlice = createSlice({
  name: "guestCheckout",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [guestCheckoutDetailsRegister.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage =
        payload.message === "USER_EXIST"
          ? HTTPMSG.USER_EXIST_CHECKOUT
          : HTTPMSG[payload.message || "ERROR"];
    },
    [guestCheckoutDetailsRegister.pending]: (state) => {
      state.isFetching = true;
    },
    [guestCheckoutDetailsRegister.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = HTTPMSG[payload.message || "ERROR"];
      state.data = payload.data;
      setLocalStore({
        cname: `${window.location.host}TEMPAUTH_DETAILS`,
        cvalue: payload.data.token,
      });
    },
  },
});

export const { clearState } = guestCheckoutSlice.actions;
export const guestCheckoutSelector = (state: RootState) => state.guestCheckout;
const guestCheckoutReducer = guestCheckoutSlice.reducer;
export default guestCheckoutReducer;
