import React, { useEffect, useState } from "react";

import useClickOutside from "hooks/useClickOutside";
import { useAppDispatch, useAppSelector } from "store/store";
import { profile } from "features/profile/slices/profile.slice";

import close from "assets/images/modal-close.png";
import "./ReferAndEarn.scss";
import { setToastState } from "components/Toast/Toast.slice";
import { BASE_URL } from "api/api";
import {
  clearReferralState,
  referralInvite,
  referralSelector,
} from "./refer-and-earn.slice";
import { useTranslation } from "react-i18next";
import { DirectionCheck } from "utils/classnameSwitch";

const ReferAndEarn: React.FC<{ onClose: (status: boolean) => any }> = ({
  onClose,
}) => {
  const { data, loading } = useAppSelector(profile);
  const { isError, sendReferralSuccess, sendReferralProcessing, discount } =
    useAppSelector(referralSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const expression = "referral";

  const node = React.useRef<HTMLDivElement>(null);
  useClickOutside(node, () => onClose(false));

  const [emails, setEmails] = useState<string[]>([]);
  const [inputError, setInputError] = useState(false);
  const emailEntering = (e: any) => {
    const text = e.target.value.trim();
    !/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(text)
      ? setInputError(true)
      : setInputError(false);
    if (
      (e.code === "Space" ||
        e.code === "Enter" ||
        e.keyCode === 32 ||
        e.keyCode === 13 ||
        e.key === " " ||
        e.target.value.slice(-1) === " "
      ) &&
      text &&
      emails.length < 5 &&
      /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(text)
    ) {
      !emails.includes(text) && setEmails([...emails, text]);
      e.target.value = "";
    }
  };

  const tagSelection = (selectedEmail: string) =>
    setEmails(emails.filter((email) => email !== selectedEmail));

  const copyCouponCode = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(
      setToastState({
        type: "info",
        message: t(`${expression}.toastMsg.copy`),
        dismiss: 3500,
      })
    );
  };

  const sendInvites = () => dispatch(referralInvite(emails));

  useEffect(() => {
    if (sendReferralSuccess) {
      dispatch(
        setToastState({
          type: "success",
          message: t(`${expression}.toastMsg.success`),
          dismiss: 3500,
        })
      );
      setEmails([]);
      dispatch(clearReferralState());
    }
    // eslint-disable-next-line
  }, [dispatch, sendReferralSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(
        setToastState({
          type: "danger",
          message: t(`${expression}.toastMsg.error`),
          dismiss: 3500,
        })
      );
      dispatch(clearReferralState());
    }
    // eslint-disable-next-line
  }, [dispatch, isError]);

  return (
    <div className={`modal is-active non-member-checkout refer-and-earn`}>
      <div className="modal-background background-banner"></div>
      <div className="modal-card modal-window" ref={node as any}>
        <section className="modal-card-body mod-content">
          <button className={`close-btn`} onClick={(_) => onClose(false)}>
            <img src={close} alt="close" />
          </button>
          <h3 className="mt-4 highlight-text">{t(`${expression}.text1`)} {discount}{t(`${expression}.text12`)}</h3>
          <p className="mb-3 mt-4 grab-offer">{t(`${expression}.text2`)}</p>

          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            className={`offer-code-wrapper ${
              DirectionCheck() === "ltr" ? "" : "rtl-offer-code-wrapper"
            }`}
          >
            <span className="offer-code" style={{ fontSize: "16px" }}>
              {loading ? "---" : data.referral_code}
            </span>
            {!loading ? (
              <span
                className="copy-button ml-3"
                title="Copy"
                onClick={() =>
                  copyCouponCode(
                    `${BASE_URL}/register?referral_code=${data.referral_code}`
                  )
                }
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
              </span>
            ) : null}
          </p>

          <p className="mb-3 mt-4 grab-offer">{t(`${expression}.text3`)}</p>
          <p className="mb-3 mt-4 grab-offer">{t(`${expression}.text4`)}</p>
          <div
            className={`noresult-banner mt-2 ${
              DirectionCheck() === "ltr" ? "" : "rtl-no-result"
            }`}
            style={{
              width: "100%",
              marginTop: "-10px",
              borderRadius: "6px",
              padding: "10px 15px",
            }}
          >
            <div className="noresult-info" style={{ display: "flex" }}>
              <img
                src="/static/media/info.e66026e9.svg"
                alt="info"
                style={{ minWidth: "25px", maxWidth: "25px" }}
              />
            </div>
            <div>
              <div className="noresult-static" style={{ fontSize: "13px" }}>
                {t(`${expression}.text5`)}{" "}
                <span>{t(`${expression}.text6`)}</span>{" "}
                {t(`${expression}.text7`)}{" "}
                <span>{t(`${expression}.text8`)}</span>{" "}
                {t(`${expression}.text9`)}
              </div>
            </div>
          </div>
          <input
            id="emails"
            className={`input mt-2 pl-0 ${inputError ? "email-error" : ""}`}
            onKeyUp={emailEntering}
            placeholder={t("common.email")}
            autoComplete="false"
            dir={DirectionCheck()}
          />
          {inputError ? (
            <p
              style={{
                textAlign: DirectionCheck() === "ltr" ? "left" : "right",
              }}
              className="has-text-danger"
            >
              {t(`${expression}.emailError`)}
            </p>
          ) : null}

          {emails.length > 0 ? (
            <>
              <div className="mb-2 mt-3 p-2 tagarea">
                {emails.map((email, index) => (
                  <span
                    className={`tag ${index === 0 ? "ml-0" : ""} mr-3 mt-2`}
                    onClick={(_) => tagSelection(email)}
                  >
                    {email.length > 50 ? `${email.slice(0, 50)}...` : email}{" "}
                    <i className="fas fa-close ml-2" aria-hidden="true"></i>
                  </span>
                ))}
              </div>
              <p
                style={{
                  textAlign: DirectionCheck() === "ltr" ? "left" : "right",
                  fontSize: "13px",
                }}
                className="grab-offer"
              >
                {5 - emails.length} {t(`${expression}.text10`)}
              </p>
              <button
                className={`send-email ${
                  sendReferralProcessing ? "is-disabled" : ""
                }`}
                onClick={(_) => sendInvites()}
              >
                {t(`${expression}.text11`)}
              </button>
            </>
          ) : null}
        </section>
      </div>
    </div>
  );
};

export default ReferAndEarn;
