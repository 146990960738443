import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";
import { IAttractions } from "./attractions.interface";
import { RootState } from "store/rootReducer";

interface IFeaturedProperty {
  name: string;
  description?: string;
  image: { url: string };
}
interface AttractionsState {
  attractions: IAttractions[];
  isAttractionsFetched: boolean;
  featured_properties: IFeaturedProperty[];
  isFeaturePropertyFetch: boolean;
}

export const initialState: AttractionsState = {
  attractions: [],
  isAttractionsFetched: false,
  featured_properties: [],
  isFeaturePropertyFetch: false,
};
//Handle mutliple landing request
export const attractionsFeatured: any = createAsyncThunk(
  "featuredattractions",
  async (category: string) => {
    const rsp: any = await get((endPoints.HOME as any)[category]);
    return {
      data: rsp.data.data.attractions
    };
  }
);
export const featuredProperties: any = createAsyncThunk(
  "featuredproperties",
  async () => {
    const rsp: any = await get((endPoints.HOME.PROPERTIES));
    return {
      data: rsp.data.data.featured_properties
    };
  }
);
const attractionsSlice = createSlice({
  name: "home",
  initialState,

  reducers: {
  },
  extraReducers: {
    [attractionsFeatured.pending]: (state, action) => {
      state.isAttractionsFetched = false;
    },
    [attractionsFeatured.fulfilled]: (state, action) => {
      state.isAttractionsFetched = true;
      const { data } = action.payload;
      state.attractions = data;
    },
    [attractionsFeatured.rejected]: (state, action) => {
      state.isAttractionsFetched = true;
      state.attractions = [];
    },

    [featuredProperties.pending]: (state, action) => {
      state.isFeaturePropertyFetch = false;
    },
    [featuredProperties.fulfilled]: (state, action) => {
      state.isFeaturePropertyFetch = true;
      const { data } = action.payload;
      state.featured_properties = data;
    },
    [featuredProperties.rejected]: (state, action) => {
      state.isFeaturePropertyFetch = true;
      state.featured_properties = [];
    },
  },
});

export const attractionsSelector = (state: RootState) => state.attraction;
const attractionsReducer = attractionsSlice.reducer;
export default attractionsReducer;
