import React, { FunctionComponent } from 'react';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer } from 'recharts';
import './radialChart.scss';
import SearchSkeleton from 'features/search-property/components/SearchSkeleton/SearchSkeleton';

const style = {
    top: '50%',
    right: '5%',
    transform: 'translate(0, -30%)',
    lineHeight: '24px',
    fontSize: '14px',
};

const RadialChart: FunctionComponent<any> = ({ data, loading }) => {
    return (
        <div className="radial-chart-card">
            <h4>Website Views</h4>
            {loading ? <SearchSkeleton /> :
                <div className="radial-chart-wrap">
                    <ResponsiveContainer width="100%" height="100%" aspect={2}>
                        <RadialBarChart
                            innerRadius={70}
                            outerRadius={160}
                            barSize={20}
                            data={data}
                            startAngle={0}
                            endAngle={360}
                        >
                            <RadialBar
                                background={false}
                                dataKey="uv"
                                legendType="circle"
                            />
                            <Legend
                                iconSize={10} width={200} height={140} layout='vertical'
                                wrapperStyle={style}
                                verticalAlign='bottom'
                            />
                        </RadialBarChart>
                    </ResponsiveContainer>
                </div>
            }
        </div>
    )
}

export default RadialChart;