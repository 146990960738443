import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTPMSG } from "config";
import { RootState } from "store/rootReducer";
import { ResetPasswordService } from "./reset-password.service";

export interface ResetUserPassword {
  new_password: string;
  reenter_password: string;
  verification_id: string;
}

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  errorType: "",
  successMessage: ""
};

export const resetPassword: any = createAsyncThunk(
  "resetPassword",
  async (payload: ResetUserPassword, { rejectWithValue }) => {
    try {
      const response: any = await ResetPasswordService.resetPassword(payload);
      return response.data;
    } catch (e: any) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [resetPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorType = payload.message;
      state.errorMessage = HTTPMSG[payload.message];
    },
    [resetPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = HTTPMSG[payload.message];
    },
  },
});

export const { clearState } = resetPasswordSlice.actions;
export const resetPasswordSelector = (state: RootState) => state.reset;
const resetPasswordReducer = resetPasswordSlice.reducer;
export default resetPasswordReducer;
