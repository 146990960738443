import React from "react";
import { useState } from "react";
import './TextField.scss';
import i18n from "locale/translation";
interface TextFieldProps {
  label: string;
  name: string;
  type: string;
  value?: string;
  placeholder: string;
  errors?: any;
  register: any;
  disabled?: boolean;
  defaultValue?: string;
  rules: any;
  min?: number;
  max?: number;
  icon?: 'check' | 'show' | 'hide' | 'none';
  isReadOnly?: boolean;
  customError?: string; // NOTE: Condition to display custom error message should be handled in parent
  passwordVisibibility?: (payload: boolean) => any;
  handleOnChange?: () => void;
  className?: string;
}

const displayErrorMessage = (error: string, customError:string, label: string, rules: any, min?:number, max?:number) => {

  if (customError.trim()) return <p className="help is-danger">{customError}</p>;
  else {
    switch(error) {
      case "required": 
        return <p className="help is-danger">{label} {i18n.t('errmsg.require')}</p>;
      case "maxLength": 
        return <p className="help is-danger">
                {label} {i18n.t('errmsg.maximum')} {rules.maxLength} {i18n.t('errmsg.char')}
              </p>;
      case "minLength": 
        return <p className="help is-danger">
                {label} {i18n.t('errmsg.minimum')} {rules.minLength} {i18n.t('errmsg.char')}
              </p>;
      case "max": 
        return <p className="help is-danger">
              {label} {i18n.t('errmsg.limit')} {max}
            </p>;
      case "min": 
        return <p className="help is-danger">
              {label}{i18n.t('errmsg.atleast')} {min}
            </p>;
      case "pattern": 
        return <p className="help is-danger">{label} {i18n.t('errmsg.invalid')}</p>
      default: 
        return null;
    }
  }
}

const TextField: React.FC<TextFieldProps> = ({
  name,
  label,
  type,
  value,
  placeholder,
  errors,
  register,
  rules,
  icon,
  isReadOnly,
  customError,
  disabled,
  defaultValue,
  min,
  max,
  handleOnChange,
  passwordVisibibility,
  className
}) => {

  const generateTextFieldIcon = (icon: string) => {
    switch(icon) {
      case 'check': 
        return <span className="icon is-right">
                <i className="fa fa-check"></i>
              </span>;
      case 'show':
        return <span className="icon is-right" onClick={() => passwordVisibibility && passwordVisibibility(false)}>
                <i className="fa fa-eye"></i>
              </span>
      case 'hide':
        return <span className="icon is-right" onClick={() => passwordVisibibility && passwordVisibibility(true)}>
                  <i className="fa fa-eye-slash"></i>
              </span>
      default: 
        return null
    }
  }

  const [focusBorder, setFocusBorder] = useState(false);
  const [moveLabel, setMoveLabel] = useState(true);
  const fieldActive = (value: string) => {
    // If text-field has value, label should remain visible onBlur
    value.trim() ? setMoveLabel(false) : setMoveLabel(true);
    setFocusBorder(false);
  }

  return (
    <div className={`field text-field mt-2 ${isReadOnly ? "no-pointer-events": ""} ${className ? className:""}`}>
      <label 
        className={`label ${(moveLabel === false || focusBorder || value || defaultValue) ? 
          `show ${(errors[name] || customError) ? "has-text-danger" : `${focusBorder ? "has-text-primary" : ""}` }` : 'hide'}`
      }>{label}</label>
      <div className="control has-icons-right">
        <input
          placeholder={!focusBorder ? placeholder : ""}
          type={type}
          disabled= {disabled}
          value={value}
          className={`input ${(errors[name] || customError) ? "is-danger" : (focusBorder ? "is-primary" : "")}`}
          {...register(name, { ...rules })}
          onFocus={_ => setFocusBorder(true)} 
          onBlur={(e) => fieldActive(e.target.value)}
          defaultValue={defaultValue}
          readOnly={isReadOnly ? true : false}
          min={min ? min : null}
          max={max ? max : null}
        />
        {/* Display custom error if provided else, display default errors */}
        {displayErrorMessage(errors[name] && errors[name].type, customError || "", label, rules, min, max)}
        
        {/* Generate icon to display to the right of the input field */}
        {generateTextFieldIcon(icon || "none")}
      </div>
    </div>

  );
};
export default TextField;
