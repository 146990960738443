import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { DirectionCheck } from "utils/classnameSwitch";
import { useAppDispatch, useAppSelector } from "store/store";
import { setToastState } from "components/Toast/Toast.slice";
import { clearState, forgotPasswordSelector, forgotUserPassword } from "../forgot-password.slice";

import TextField from "components/Inputs/TextField";
import AuthLayout from "components/Layout/AuthLayout/AuthLayout";

import './ForgotPasswordComponent.scss';
import { Context } from "components/app/Wrapper";

type Inputs = {
  email: string;
};

const ForgotPasswordComponent: React.FC = () => {
  const context: any = useContext(Context);
  const { t } = useTranslation()
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const dispatch = useAppDispatch();
  
  const { isFetching, isSuccess, isError, errorMessage, successMessage } =
    useAppSelector(forgotPasswordSelector);

  const onSubmit: SubmitHandler<Inputs> = (data) => dispatch(forgotUserPassword(data));

  useEffect(() => {
    if (isError) {
      dispatch(setToastState({type: 'danger', message: errorMessage, dismiss: 3500}));
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(setToastState({type: 'success', message: successMessage, dismiss: 3500}));
      dispatch(clearState());
    }
  }, [isError, isSuccess, dispatch, errorMessage, successMessage, context.locale]);

  return (
    <AuthLayout>
        <form onSubmit={handleSubmit(onSubmit)} dir={DirectionCheck()}>
            <span className="form-heading">  {t("fgtpwd.title")} </span>
            <span className="info-text"> {t("fgtpwd.text")} </span>
            <TextField
            label={t('common.email')}
            placeholder={t('common.email')}
            register={register}
            name="email"
            type="email"
            errors={errors}
            rules={{
                required: true,
                pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            }}
            />
            <div className="field is-grouped">
              <div className="control">
                  <button
                  className={`button is-link login-btn ${isFetching ? "is-loading" : ""}`}
                  type="submit">
                  {t("common.submit")}
                  </button>
              </div>
            </div>
        </form>
        <div className="register-link">
        {t("register.alreadyAccnt")} <span>
            <Link to={{
                pathname: `/login`,
            }}> {t("register.gotologin")}</Link></span>
        </div>
    </AuthLayout>
  );
};

export default ForgotPasswordComponent;
