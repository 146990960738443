import React from 'react';
import { useAppSelector } from 'store/store';
import { ownerSelector } from '../owner.slice';
import PdfViewer from './components/pdf-viewer/PdfViewer';
import ReportOptions from './components/report-options/ReportOptions';
import NoUnits from '../no-units/NoUnits';

import './OwnerReport.scss';

const OwnerReport: React.FC = () => {
    const { data, isSuccess } = useAppSelector(ownerSelector);

    if (isSuccess && data.length === 0) {
        return <NoUnits />
    }

    return <div className="dashboard-main report-grid mb-4">
        <div className="w-25">
            <ReportOptions />
        </div>
        <div className="w-75">
            <PdfViewer />
        </div>
    </div>
}

export default OwnerReport;
