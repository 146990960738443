import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from 'services/api.service';
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { HTTPMSG } from 'config/index';
import { IOwnerPropertyList, IOwnerReportProps } from "./owner.interface";

const initialData: IOwnerPropertyList[] = [];
export const NO_UNITS_DATA = "UNIT_DATA_NOT_FOUND";

export const initialState = {
  status: "pending",
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  data: initialData,
  dates: {
    from: "",
    to: ""
  },
  isReportFetching: false,
  isReportSuccess: false,
  isReportError: false,
  hasReportFetched: false,
  mailSent: false,
  pdf: {
    data: "",
    raw_data: ""
  },
  sendEmailFetching: false,
  sendEmailSuccess: false,
  sendEmailError: false,
};

export const ownerProperties: any = createAsyncThunk(
  "owner/properties",
  async (_, { rejectWithValue }) => {
    try {
      const response: any = await get(endPoints.DASHBOARD.GETUNITS);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const generateOwnerReport: any = createAsyncThunk(
  "owner/reports",
  async (data: IOwnerReportProps, { rejectWithValue }) => {
    try {
      const response: any = await post(data, endPoints.DASHBOARD.REPORTS);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const sendReportAsEmail: any = createAsyncThunk(
  "owner/reportsEmail",
  async (data: IOwnerReportProps, { rejectWithValue }) => {
    try {
      const response: any = await post(data, endPoints.DASHBOARD.REPORTS);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const ownerSlice = createSlice({
  name: "owner",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isReportSuccess = false;
      state.isReportFetching = false;
      state.isReportError = false;
      return state;
    },
    setDates: (state, { payload }) => {
      state.dates = payload;
    },
    setMailSent: (state, { payload }) => {
      state.mailSent = payload;
    },
    resetRawData: (state) => {
      state.pdf.raw_data = "";
    },
    resetSendEmailState: (state) => {
      state.sendEmailError = false;
      state.sendEmailFetching = false;
      state.sendEmailSuccess = false;
    }
  },
  extraReducers: {
    [ownerProperties.rejected]: (state, { payload }) => {
      state.errorMessage = HTTPMSG[payload.message];
      state.isFetching = false;
      state.isError = true;
    },
    [ownerProperties.pending]: (state) => {
      state.isFetching = true;
    },
    [ownerProperties.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.status = "fulfilled";
      state.data = payload.data;
      state.isSuccess = true;
    },
    [generateOwnerReport.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
      state.isReportFetching = false;
      state.isReportError = true;
    },
    [generateOwnerReport.pending]: (state) => {
      state.isReportFetching = true;
    },
    [generateOwnerReport.fulfilled]: (state, {payload }) => {
      state.isReportFetching = false;
      state.status = "fulfilled";
      state.isReportSuccess = true;
      const { data } = payload;
      state.pdf.data = data.data;
      state.pdf.raw_data = data.raw_data;
      state.hasReportFetched = true;
    },
    [sendReportAsEmail.rejected]: (state, { payload }) => {
      state.errorMessage = HTTPMSG[payload.message || "ERROR"];
      state.sendEmailFetching = false;
      state.sendEmailError = true;
    },
    [sendReportAsEmail.pending]: (state) => {
      state.sendEmailFetching = true;
    },
    [sendReportAsEmail.fulfilled]: (state, {payload }) => {
      state.sendEmailFetching = false;
      state.sendEmailSuccess = true;
    }
  },
});

export const { clearState, setDates, setMailSent, resetRawData, resetSendEmailState } = ownerSlice.actions;
export const ownerSelector = (state: RootState) => state.owner;
const ownerReducer = ownerSlice.reducer;
export default ownerReducer;
