import React, { useState, useEffect } from "react";
import "../../Reservation.scss";
import { format, getYear, getMonth, subDays } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setSelectedDate } from "../../reservation.slice";
import { useAppDispatch } from "store/store";
import { setToastState } from "components/Toast/Toast.slice";

const DatePickerOwner: React.FC<any> = () => {
  const [startDate, setStartDate] = useState(subDays(new Date(), 45)); // Load reservations of 45 days
  const dispatch = useAppDispatch();
  const [endDate, setEndDate] = useState(new Date());
  const [toggleDatePicker, setToggleDatePicker] = useState(false);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const startdate = format(new Date(startDate), "yyyy-MM-dd");
    const enddate = !endDate ? "" : format(new Date(endDate), "yyyy-MM-dd");
    if (startdate !== enddate) {
      if (endDate) {
        dispatch(setSelectedDate({ from: startdate, to: enddate }));
        setToggleDatePicker(false);
      }
    } else
      dispatch(
        setToastState({
          type: "danger",
          message: "Start date and end date cannot be same",
          dismiss: 3500,
        })
      );
  }, [startDate, endDate, dispatch]);

  var startyear = +format(new Date(0), "yyyy"), // Oldest UTC time - 1970
    endyear = +format(new Date(), "yyyy") + 10; // User can select upto 10 years into future

  const range = (start: number, end: number, length = end - start + 1) =>
    Array.from({ length }, (_, i) => start + i);

  const years = range(startyear, endyear);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="owner-datepicker-container">
      <div className="mr-3">Date </div>
      <div className="owner-scheduler-datepicker">
        <span
          className="datepicker-value"
          onClick={() => {
            setToggleDatePicker(!toggleDatePicker);
          }}
        >
          {format(new Date(startDate), "dd LLL, yyyy")} -{" "}
          {format(new Date(endDate as any), "dd LLL, yyyy")}
        </span>
        {toggleDatePicker && (
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value as any)}
                >
                  {years.map((option: any) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            closeOnScroll={true}
            inline
            onChange={onChange}
            onClickOutside={(_) => setToggleDatePicker(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DatePickerOwner;
