import React from "react";
import "./SearchDropDown.scss";
import { AutoComplete } from "./AutoComplete";
import { classSwitch } from "utils/classnameSwitch";
interface SearchDropDownProps {
  onClick?: (e: any) => void;
  title: string;
  toggleView: any;
  children: any;
  subtitle: string;
  image?: any;
  contentClass?: string;
  selectedValue?: string;
  name?: string;
}
const SearchDropDownAC: React.FC<SearchDropDownProps> = ({
  onClick,
  title,
  toggleView,
  children,
  subtitle,
  image,
  contentClass,
  selectedValue,
  name,
}) => {
  return (
    <>
      <div className="search-bar dropdown is-active ">
        <div className="dropdown-trigger">
          <button
            onClick={onClick}
            className={`dropdown-button button search-dropdown-btn autocomplete-btn ${toggleView === name ? 'focused-btn' : ''}`}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <span className={`fw-500 dropdown-title ${toggleView === name ? 'focused-text' : ''}`}>{title}</span>
            <span
              className={`fw-500 dropdown-subtitle ${selectedValue === "" ? "reg" : "selected-body"
                }`}
            >
              <img src={image} alt="Location Icon" className={classSwitch("mr-3")}></img>

              <AutoComplete></AutoComplete>
            </span>
          </button>
        </div>
        {toggleView === name && (
          <div className="dropdown-menu locations-menu-dd " id="dropdown-menu" role="menu">
            <div className={`dropdown-content search-dropdown ${contentClass}`}>
              {children}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchDropDownAC;
