import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ResetPasswordComponent from "./components/ResetPasswordComponent/ResetPasswordComponent";
import ResetPasswordPopup from "./components/ResetPasswordPopup/ResetPasswordPopup";
import { PageProps } from "interfaces/page.interface";
import { emailDecode } from "utils/encryption";

export interface ResetPasswordProps {
  email: string;
  verification_id: string;
}

const ResetPassword: React.FC<PageProps> = (props) => {
  const { search } = useLocation();
  const [continueToBrowser, setcontinueToBrowser] = useState(false);
  
  const agent: string = navigator.userAgent.toLowerCase(), url = '/reset-password/' + search;
  const [email, verification_id] = search.split("&").map(param => param.split("=").pop());

  useEffect(() => {
    localStorage.getItem("setBrowserRedirect") && setcontinueToBrowser(true)
  }, []);

  if ((agent.includes('iphone') || agent.includes('android')) && !continueToBrowser) {
    return <ResetPasswordPopup url={url} />;
  }
  else {
    localStorage.removeItem("setBrowserRedirect")
    return <ResetPasswordComponent email={emailDecode(email as string) || ''} verification_id={verification_id || ''} />;
  }
};

export default ResetPassword;
