import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { get } from "services/get.service";
import { RootState } from "store/rootReducer";
import { ActiveDropDown } from "utils/enum";
interface selectionPayload {
    category?: keyof typeof ActiveDropDown;
    value?: any;
    master?: string;
    type?: string;
    lat?: any | undefined;
    long?: any | undefined;
    formattedD?: any;
    id?: string | null;
}
interface SearchState {
    isNowActive: string;
    locations: any;
    checkin: any;
    guests: any;
    isFeaturedFetched: boolean;
    featuredstays: any;
    locationsLists: any;
    isSearching: boolean;
    locationstype?: string;
    checkout: any;
    filters: any;
    mobFilters: any;
    resetFilterFlag: boolean;
    extendStay: boolean;
    priceMembers: any;
    isSearchActive: boolean;
}
export const initialState: SearchState = {
    isNowActive: "",
    locations: {
        currentLocation: "",
        showLocations: false,
        lat: '',
        long: '',
        id: ''
    },
    checkin: {
        currentFrom: "",
        showCheckIn: false,
    },
    checkout: {
        currentTo: "",
        showCheckOut: false,
    },
    guests: {
        currentGuests: "",
        showGuest: false,
    },
    isFeaturedFetched: false,
    featuredstays: [],
    locationsLists: [],
    isSearching: false,
    locationstype: '',
    filters: {},
    mobFilters: {},
    resetFilterFlag: false,
    extendStay: false,
    priceMembers: {},
    isSearchActive: false
};
export const getFeaturedStay: any = createAsyncThunk(
    "featured",
    async () => {
        const rsp: any = await get(endPoints.FEATURED_STAY);
        return {
            data: rsp.data.data.room_categories

        };
    }
);
export const getAmenities: any = createAsyncThunk(
    "amenities",
    async () => {
        const rsp: any = await get(endPoints.AMENITIES);
        return {
            data: rsp.data.data.featured_staycations

        };
    }
);
const searchSlice = createSlice({
    name: "search",
    initialState,

    reducers: {
        updateSelection: (state, { payload }: PayloadAction<selectionPayload>) => {
            switch (payload.master) {
                case "locations":
                    state.locations[payload.category as string] = payload.value;
                    state.locationstype = payload.type;
                    state.locations.lat = payload.lat;
                    state.locations.long = payload.long;
                    state.locations.id = payload.id;
                    break;
                case "checkin":
                    state.checkin[payload.category as string] = payload.value;
                    state.checkin.formattedD = payload.formattedD;
                    break;
                case "checkout":
                    state.checkout[payload.category as string] = payload.value;
                    state.checkout.formattedD = payload.formattedD;
                    break;
                case "guests":
                    state.guests[payload.category as string] = payload.value;
                    break;
            }
        },
        resetAll: (state) => {
            state.locations.currentLocation = '';
            state.locations.lat = '';
            state.locations.long = '';
            state.checkin.currentFrom = '';
            state.checkin.formattedD = '';

            state.checkout.currentTo = '';
            state.checkout.formattedD = '';

            state.guests.currentGuests = '';
        },
        currentlyActive: (state, { payload }) => {
            state.isNowActive = payload;
        },
        updatePredictions: (state, { payload }) => {
            state.locationsLists = payload;
        },
        activeSearch: (state, { payload }) => {
            state.isSearching = payload;
        },
        activeFilters: (state, { payload }) => {
            state.filters = payload;
        },
        activeMobFilters: (state, { payload }) => {
            state.mobFilters = payload;
        },
        updateResetFilter: (state, { payload }) => {
            state.resetFilterFlag = payload
        },
        updateExtendStay: (state, { payload }) => {
            state.extendStay = payload;
        },
        updatePrice: (state, { payload }) => {
            state.priceMembers = payload;
        },
        setSearchActiveStatus: (state, { payload }) => {
            state.isSearchActive = payload;
        }
    },

    extraReducers: {
        [getFeaturedStay.pending]: (state, action) => {
            state.isFeaturedFetched = false;
        },
        [getFeaturedStay.fulfilled]: (state, action) => {
            state.isFeaturedFetched = true;
            const { data } = action.payload;
            state.featuredstays = data;
        },
        [getFeaturedStay.rejected]: (state, action) => {
            state.isFeaturedFetched = true;
            state.featuredstays = [];
        },
    },
});

export const { updateSelection, currentlyActive, updateExtendStay, updatePrice, updatePredictions, activeSearch, activeFilters, activeMobFilters, updateResetFilter, resetAll, setSearchActiveStatus } = searchSlice.actions;
export const searchSelector = (state: RootState) => state.searchproperty;
const searchReducer = searchSlice.reducer;
export default searchReducer;
