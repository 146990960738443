import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { DirectionCheck } from "../../../../../../utils/classnameSwitch";
import { useAppDispatch, useAppSelector } from "store/store";
import {
    updatePredictions,
    searchSelector,
    activeSearch,
    currentlyActive,
    updateSelection
} from "features/search-property/searchproperty.slice";
import {
    locationsSelector,
} from "features/locations/locations.slice";
import { Context } from "components/app/Wrapper";

export const AutoComplete = () => {
    const context: any = useContext(Context);
    const dispatch = useAppDispatch();
    const { locations, locationstype } =
        useAppSelector(searchSelector);

    const { locationList } =
        useAppSelector(locationsSelector);
    const [value, setValue] = useState("");
    const {
        placePredictions,
        getPlacePredictions,
    } = useGoogle({
        apiKey: process.env.REACT_APP_MAP_TOKEN,
    });
    useEffect(() => {
        if (locations.currentLocation) setValue(locations.currentLocation);
    }, [locations.currentLocation]);
    useEffect(() => {
        const predicted = [...placePredictions];
        if (placePredictions.length) {
            locationList.map((item) => {
                if (item.name.toLowerCase().includes(value.toLowerCase())) {
                    const popularlocation: any = {
                        structured_formatting: {
                            main_text: item.name,
                            secondary_text: "United Arab Emirates",
                        },
                    };
                    predicted.unshift(popularlocation);
                }
                return predicted
            });
            dispatch(updatePredictions(predicted));
        }
        else {
            if (value && predicted.length !== 0) {
                dispatch(updatePredictions([]));
            }
        }
    }, [placePredictions, locationList, dispatch, value, context.locale]);
    useEffect(() => {
        if (value) {
            dispatch(activeSearch(true));
        } else {
            dispatch(updatePredictions([]));
            dispatch(activeSearch(false));
        }
    }, [value, locationstype, dispatch, context.locale]);
    const { t } = useTranslation();
    return (
        <>
            <input
                autoComplete="off"
                id="autocomplete-i"
                className="search-autocomplete"
                style={{ color: "black" }}
                value={value}
                placeholder={t("search.locations")}
                onKeyDown={(e) => {
                    if (e.keyCode === 32) {
                        setTimeout(() => {
                            dispatch(currentlyActive("LOCATIONS"));
                        }, 800)
                    }
                }}
                onChange={(evt) => {
                    dispatch(currentlyActive("LOCATIONS"));
                    getPlacePredictions({
                        input: evt.target.value,
                        componentRestrictions: { country: "ae" },
                    });
                    setValue(evt.target.value);
                    dispatch(
                        updateSelection({
                            category: "currentLocation",
                            value: "",
                            master: "locations",
                            lat: "",
                            long: "",
                        })
                    );
                }}
            />
            {value && <span className={`icon ${DirectionCheck() === 'rtl' ? '' : 'is-right'}`}id="closeicon" onClick={() => {
                setValue(''); dispatch(
                    updateSelection({
                        category: "currentLocation",
                        value: "",
                        master: "locations",
                        lat: "",
                        long: "",
                    })
                );
            }}>
                <i className="fas fa-times-circle"></i>
            </span>}
        </>
    );
};
