interface IAttractions {
  name: string;
  description?: string;
  image: string;
}

export const attractions1Carousel: IAttractions[] = [
  {
    name: "Burj Khalifa",
    image: "attractions1",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Atlantis",
    image: "attractions2",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Marina Mall",
    image: "attractions3",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Autodrome",
    image: "attractions4",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Ski Dubai",
    image: "attractions5",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Water Sports",
    image: "attractions6",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Sky Dive Dubai",
    image: "attractions7",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Aquaventures",
    image: "attractions8",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
];
export const attractions2Carousel: IAttractions[] = [
  {
    name: "Ski Dubai",
    image: "attractions5",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Water Sports",
    image: "attractions6",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Sky Dive Dubai",
    image: "attractions7",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Aquaventures",
    image: "attractions8",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Burj Khalifa",
    image: "attractions1",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Atlantis",
    image: "attractions2",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Marina Mall",
    image: "attractions3",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
  {
    name: "Autodrome",
    image: "attractions4",
    description:
      "Exilirating fast cars. Ultimate driving thrill. Amazing venue.All this at Dubai Autodrome!",
  },
];
