import React from "react";
import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { setToastState } from "components/Toast/Toast.slice";
import { useAppDispatch, useAppSelector } from "store/store";
import { offerListSelector, fetchOffers } from "features/offers/offers-list.slice";

import { getLocalStore } from "utils/encryption";
import EvenCarouselComponent from "../carousels/even-carousels/EvenCarousel";
import MonthlyDealsCarousel from "../carousels/monthly-deals/MonthlyDeals";
import OddCarouselComponent from "../carousels/odd-carousels/OddCarousel";

import './OffersComponent.scss';
import { IOffers } from "features/offers/offers.interface";
import { OFFER_MAPPING } from "features/offers/offers.config";
import { Link, useLocation } from "react-router-dom";
import { Context } from "components/app/Wrapper";
import { DirectionCheck } from "utils/classnameSwitch";

const OffersComponent: React.FC = () => {
    const { t } = useTranslation();
    const context: any = useContext(Context);
    const { isLogin } = { isLogin: Boolean(getLocalStore(`${window.location.host}AUTH_DETAILS`)) };
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { offers, mOffers, errorMessage } = useAppSelector(offerListSelector);

    useEffect(() => {
        dispatch(fetchOffers());
    }, [dispatch, context.locale])

    useEffect(() => {
        if (errorMessage) {
            dispatch(setToastState({type: 'danger', message: errorMessage, dismiss: 3500}));
        }
    }, [dispatch, errorMessage]);

    const fetchCategoryData = (type: string) => offers.filter((f:IOffers) => f.type === type);
  
    return (
        <>
            <div className="layout-banner layout-banner-img layout-banner-mask flex-align">
                <div className="layout-grid">
                    <h3 className="offers-heading">{t('offers.title')}</h3>
                    <p className="offers-description">{t('offers.subtitle')}</p>
                </div>
            </div>

            {!isLogin && <div className="layout-grid" dir={DirectionCheck()}>
                <h3 className="offers-section-heading">{t('offers.stayMore')}</h3>
                <div className="redirect-user">
                    <div className="redirect-user-content">
                        <p className="offer-banner-text">
                            <span className="emphasize-offer">{t('offers.enjoy')}</span> {t('offers.coupon')} <span className="emphasize-offer">{t('offers.member')}</span>
                        </p>
                        <div className="flex-center-y">
                            <Link to={{
                                pathname: `/register`,
                                state: {
                                    prevPath: location.pathname,
                                },
                            }} className="primary-button">{t('offers.reg')}</Link>
                            <span className="or">or</span>
                            <Link to={{
                                pathname: `/login`,
                                state: {
                                    prevPath: location.pathname,
                                },
                            }} className="non-primary-button">{t('offers.login')}</Link>
                        </div>
                    </div>
                    <div className="redirect-user-img"></div>
                </div>
            </div>}

            <MonthlyDealsCarousel offers={mOffers} />
            {fetchCategoryData("flash_deal").length ? <OddCarouselComponent title={t(OFFER_MAPPING["flash_deal"] || "offers.new")} offers={fetchCategoryData("flash_deal")}/> : null}
            {fetchCategoryData("seasonal").length ? <EvenCarouselComponent title={t(OFFER_MAPPING["seasonal"] || "offers.new")} offers={fetchCategoryData("seasonal")} />: null}
            {fetchCategoryData("stay_and_get_free").length ? <OddCarouselComponent title={t(OFFER_MAPPING["stay_and_get_free"] || "offers.new")} offers={fetchCategoryData("stay_and_get_free")} /> : null}
        </>
    );
};

export default OffersComponent;
