import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";

export const initialState = {
  isVisible: false,
  type: '',
  message: '',
  dismiss: 3000
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    clearToastState: (state) => {
        state.isVisible = false;
        state.type = '';
        state.message = '';
        state.dismiss = 3000;
        return state;
    },
    setToastState: (state, action) => {
        state.isVisible = true;
        state.type = action.payload.type;
        state.message = action.payload.message;
        state.dismiss = action.payload.dismiss || 3000;
        return state;
    }
  }
});

export const { clearToastState, setToastState } = toastSlice.actions;
export const toastSelector = (state: RootState) => state.toast;
const toastReducer = toastSlice.reducer;
export default toastReducer;
