import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";

import { HTTPMSG } from "config";
import { ContactUsProps, ContactUsService } from "./Contact.service";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: ""
};

export const contactUs: any = createAsyncThunk(
  "contactUs",
  async (payload: ContactUsProps, { rejectWithValue }) => {
    try {
      const response: any = await ContactUsService.contactUs(payload);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [contactUs.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = HTTPMSG[payload.message];
    },
    [contactUs.pending]: (state) => {
      state.isFetching = true;
    },
    [contactUs.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = HTTPMSG[payload.message];
    },
  },
});

export const { clearState } = contactUsSlice.actions;
export const contactUsSelector = (state: RootState) => state.contactUsReducer;
const contactUsReducer = contactUsSlice.reducer;
export default contactUsReducer;
