import React, { FunctionComponent, useEffect, useState } from "react";
import "./multi-date.scss";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { setDates } from "features/owner/owner.slice";
import { useAppDispatch } from "store/store";

export interface IDateRange {
  from: string;
  to: string;
}

const MultiDatePicker: FunctionComponent<{
  selectedDate: (dates: IDateRange) => any;
  minStartDate?: Date;
  maxEndDate?: Date;
}> = ({
  selectedDate,
  minStartDate = new Date(0), // Oldest UTC
  maxEndDate = moment().add(10, "y").toDate(),
}) => {
  const prev = new Date();
  prev.setDate(1);
  const previousMonth = prev.setMonth(prev.getMonth() - 1);
  const [startDate, setStartDate] = useState<any>(new Date(previousMonth));
  const [endDate, setEndDate] = useState<any>(new Date());
  const today = new Date();
  today.setFullYear(today.getFullYear() - 2);
  //   const previousTwoYear = today.toDateString();
  const dispatch = useAppDispatch();

  useEffect(() => {
    sendDate();
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const sendDate = () => {
    const currentMonth = new Date().getMonth() + 1;
    const endDateMonth = new Date(endDate).getMonth() + 1;
    const lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    let endDateVal = new Date();
    endDateVal = lastDay;
    selectedDate({
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDateVal, "yyyy-MM-dd"),
    });
  };

  // Initialize dates for owner reports
  useEffect(() => {
    dispatch(
      setDates({
        from: format(startDate, "yyyy-MM-dd"),
        to: format(endDate, "yyyy-MM-dd"),
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="date-range">
      <span>From: </span>

      <DatePicker
        wrapperClassName="datePicker"
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        // startDate={startDate}
        // endDate={endDate}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        minDate={minStartDate}
        maxDate={maxEndDate}
      />

      <span className="to">To: </span>

      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        // startDate={startDate}
        // endDate={endDate}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        minDate={startDate} // prevent selection of dates less than start date
        maxDate={maxEndDate}
      />
    </div>
  );
};

export default MultiDatePicker;
