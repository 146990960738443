import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTPMSG } from "config";
import { RootState } from "store/rootReducer";
import { ReferralService } from "./refer-and-earn.service";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  isReferralActive: false,
  sendReferralProcessing: false,
  sendReferralSuccess: false,
  errorMessage: "",
  errorType: "",
  successMessage: "",
  discount: null
};

export const isReferralActive: any = createAsyncThunk(
  "referral/isReferralActive",
  async (_, { rejectWithValue }) => {
    try {
      const response: any = await ReferralService.isReferralActive();
      return response.data;
    } catch (e: any) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const referralInvite: any = createAsyncThunk(
  "referral/referralInvite",
  async (payload: string[], { rejectWithValue }) => {
    try {
      const response: any = await ReferralService.referralInvite(payload);
      return response.data;
    } catch (e: any) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

const referralSlice = createSlice({
  name: "referralSlice",
  initialState,
  reducers: {
    clearReferralState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.sendReferralProcessing = false;
      state.sendReferralSuccess = false;
      return state;
    },
  },
  extraReducers: {
    [isReferralActive.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorType = payload.message;
      state.errorMessage = HTTPMSG[payload.message];
    },
    [isReferralActive.pending]: (state) => {
      state.isFetching = true;
    },
    [isReferralActive.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isReferralActive = payload.message === "REFERRAL_ACTIVE" ? true : false;
      state.discount = payload?.data?.discount ? payload?.data?.discount : null;
    },
    [referralInvite.rejected]: (state, { payload }) => {
      state.sendReferralProcessing = false;
      state.isError = true;
      state.errorType = payload.message;
      state.errorMessage = HTTPMSG[payload.message];
    },
    [referralInvite.pending]: (state) => {
      state.sendReferralProcessing = true;
    },
    [referralInvite.fulfilled]: (state, { payload }) => {
      state.sendReferralProcessing = false;
      state.sendReferralSuccess = true;
      state.successMessage = HTTPMSG[payload.message];
    },
  },
});

export const { clearReferralState } = referralSlice.actions;
export const referralSelector = (state: RootState) => state.referral;
const referralReducer = referralSlice.reducer;
export default referralReducer;
