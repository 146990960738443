import TextField from 'components/Inputs/TextField';
import { setToastState } from 'components/Toast/Toast.slice';
import { changePassword, changePasswordStatus, clearState } from 'features/profile/slices/changePassword.slice';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/store';
import { checkPassword } from 'utils/utility';
import './ChangePassword.scss';

type Inputs = {
    current_password: string;
    new_password: string;
    reenter_password: string;
}

interface ChangePasswordProps {
    email: string
    hide: () => void;
}

const ChangePassword: FunctionComponent<ChangePasswordProps> = ({ email, hide }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
    const { error, errorMessage, successMessage, success } = useAppSelector(changePasswordStatus);

    useEffect(() => {
        if (error) {
            dispatch(setToastState({ type: 'danger', message: errorMessage, dismiss: 3500 }));
            dispatch(clearState());
        }
        if (success) {
            dispatch(clearState());
            dispatch(setToastState({ type: 'success', message: successMessage, dismiss: 3500 }));
            hide();
        }
    }, [error, errorMessage, successMessage, success, dispatch, hide]);

    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [currentPasswordShown, setcurrentPasswordShown] = useState(false);
    const [newPasswordShown, setnewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');

    const validateNewPassword = (pwd: string) => {
        const { errorMsg, isValid } = checkPassword(pwd);
        setNewPasswordError(errorMsg);
        return isValid;
    }
    const validateCurrentPassword = (pwd: string) => {
        const { errorMsg, isValid } = checkPassword(pwd);
        setCurrentPasswordError(errorMsg);
        return isValid;
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {

        data?.new_password === data?.reenter_password ? setPasswordMismatch(false) : setPasswordMismatch(true);
        data?.new_password === data?.reenter_password && dispatch(changePassword({ ...data, email }))
    };

    return (
        <div className="change-pwd">
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    label={t('personalInfo.currentpwd')}
                    placeholder={t('personalInfo.currentpwd')}
                    register={register}
                    name="old_password"
                    type={currentPasswordShown ? "text" : "password"}
                    errors={errors}
                    icon={currentPasswordShown ? "show" : "hide"}
                    rules={{
                        validate: validateCurrentPassword
                    }}
                    customError={t(currentPasswordError)}
                    passwordVisibibility={(status) => setcurrentPasswordShown(status)}
                />
                <TextField
                    label={t('resetpwd.new')}
                    placeholder={t('resetpwd.new')}
                    register={register}
                    name="new_password"
                    type={newPasswordShown ? "text" : "password"}
                    errors={errors}
                    icon={newPasswordShown ? "show" : "hide"}
                    rules={{
                        validate: validateNewPassword
                    }}
                    customError={t(newPasswordError)}
                    passwordVisibibility={(status) => setnewPasswordShown(status)}
                />
                <TextField
                    label={t('personalInfo.confirmPwd')}
                    placeholder={t('personalInfo.confirmPwd')}
                    register={register}
                    name="reenter_password"
                    type={confirmPasswordShown ? "text" : "password"}
                    errors={errors}
                    icon={confirmPasswordShown ? "show" : "hide"}
                    rules={{
                        required: true,
                        minLength: 6
                    }}
                    customError={passwordMismatch ? t('common.pwdMiss') : ""}
                    passwordVisibibility={(status) => setConfirmPasswordShown(status)}
                />
                <button className="save-btn is-link" type="submit">{t('personalInfo.save')}</button>
            </form>
        </div>
    )
}

export default ChangePassword;


