import { API } from "api/api";
import { endPoints } from "api/endPoints";
import { emailEncode } from "utils/encryption";
import { GuestRegisterInputs } from "./components/guest-detail/GuestDetail";

const guestCheckoutService = (data: GuestRegisterInputs) => {
  const body = data;
  return new Promise((resolve, reject) => {
    API.post(endPoints.USERS.GUEST_CHECKOUT, body)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
};

const verifyOtpService = (data: {email: string; otp: string}) => {
  const body = data;
  return new Promise((resolve, reject) => {
    API.post(endPoints.USERS.VERIFY_OTP, body)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
};

const resendOtpService = (email: string) => {
  return new Promise((resolve, reject) => {
    API.get(`${endPoints.USERS.VERIFY_OTP}?resend=true&email=${emailEncode(email)}`)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
};

export const GuestCheckoutService = {
    guestCheckoutService: guestCheckoutService,
    verifyOtpService: verifyOtpService,
    resendOtpService: resendOtpService
};