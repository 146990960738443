import danger from 'assets/icons/toast/danger.svg';
import info from 'assets/icons/toast/info.svg';
import success from 'assets/icons/toast/success.svg';
import warning from 'assets/icons/toast/warning.svg';
import close from 'assets/icons/toast/close.svg';

export const ToastIcons: {type: string; iconUrl: string}[] = [
    {
        type: 'success',
        iconUrl: success
    },
    {
        type: 'info',
        iconUrl: info
    },
    {
        type: 'warning',
        iconUrl: warning
    },
    {
        type: 'danger',
        iconUrl: danger
    }
];

export const closeButton = close;
