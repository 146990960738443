import React,{FunctionComponent, useContext} from 'react';
import switchToOwner from 'assets/icons/switchToOwner.svg';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import './Switch.scss';
import { useAppDispatch, useAppSelector } from 'store/store';
import { profile } from 'features/profile/slices/profile.slice';
import { setToastState } from 'components/Toast/Toast.slice';
import { Context } from 'components/app/Wrapper';

const SwitchToOwner: FunctionComponent<any> = ({isNotOwner}) => {
    const context: any = useContext(Context);
    const history =  useHistory();
    const {t} = useTranslation();
    const { data } = useAppSelector(profile);
    const dispatch = useAppDispatch();
    const handleNavigation = () => {
      if(data?.is_email_verified){
        history.push(`${isNotOwner? '/book-a-stay' : '/owner'}`);
        context.selectLanguage('en')
      } else {
        dispatch(setToastState({ type: 'danger', message: 'toast.switchErr', dismiss: 3500 }));
      }
    }
    return(
        <div className="switch-to-owner" onClick={handleNavigation}>
        <div className="icon">
          <img src={switchToOwner} alt="switch"/>
          </div>
          <div className="button-content">
            <span className="top-content"> {t('landing.switch')}</span>
            <span className="owner-content">{isNotOwner ? t('landing.guest') : t('landing.owner')}</span>
          </div>
      </div>
    )
}

export default SwitchToOwner;