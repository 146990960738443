import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { IOffers } from "./offers.interface";
import { HTTPMSG } from "config";

interface OffersListState {
  offers: IOffers[];
  mOffers: IOffers[];
  offerTypes: any[];
  isOffersFetched: boolean;
  errorMessage: string;
}

export const initialState: OffersListState = {
  offers: [],
  mOffers: [],
  offerTypes: [],
  isOffersFetched: false,
  errorMessage: "",
};

export const fetchOffers: any = createAsyncThunk(
    "fetchOfferList",
    async (isFeatured: boolean) => {
      const url = isFeatured ? `${endPoints.OFFERS.ALL}?featured=${isFeatured}`: `${endPoints.OFFERS.ALL}`, 
      response: any = await get(url);
      return {
        data: response.data
      };
    }
);

const offersListSlice = createSlice({
  name: "offersList",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOffers.pending]: (state, { payload }) => {
        state.isOffersFetched = false;
    },
    [fetchOffers.rejected]: (state, { payload }) => {
        state.isOffersFetched = false;
        state.offers = [];
        state.errorMessage = HTTPMSG[payload.message];
    },
    [fetchOffers.fulfilled]: (state, { payload }) => {
        state.isOffersFetched = true;
        const { data } = payload.data;
        let mOffersData:any = []
        if (data.length > 0) {
          // Get all offer types
          state.offerTypes = [...new Set(data.map((f: IOffers)=> f.type))];
          data.find((f: any) => f.type === "flash_deal" ? f : "") && mOffersData.push(data.find((f: any) => f.type === "flash_deal" ? f : ""));
          data.find((f: any) => f.type === "seasonal" ? f : "") && mOffersData.push(data.find((f: any) => f.type === "seasonal" ? f : ""));
          data.find((f: any) => f.type === "stay_and_get_free" ? f : "") && mOffersData.push(data.find((f: any) => f.type === "stay_and_get_free" ? f : ""));
          state.mOffers = mOffersData;
        }
        state.offers = data;
    }
  },
});

export const offerListSelector = (state: RootState) => state.offers;
const offerListReducer = offersListSlice.reducer;
export default offerListReducer;
