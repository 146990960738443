export const LOCALES = [
  { lanugage: "عربى", locale: "ar" },
  { lanugage: "English", locale: "en" },
];

export const HTTPMSG: { [index: string]: string } = {
  RESET_SUCCESS: "toast.resetsuccess",
  INTERNAL_ERROR: "toast.internalerror",
  PASSWORDS_DO_NOT_MATCH: "toast.nomatchpwd",
  INVALID_EMAIL: "toast.invalidemail",
  FORGOT_PASSWORD_MAIL_SENT: "toast.forgetpwdmailsent",
  USER_DOES_NOT_EXIST: "toast.usernotexist",
  USER_EXIST: "toast.userexist",
  SIGNUP_SUCCESS: "toast.signupproceed",
  USER_AUTHENTICATION_ERROR: "toast.pwdinvalid",
  CONTACTUS_SAVE_SUCCESS: "toast.contactsuccess",
  RESET_LINK_EXPIRED: "toast.resetlinkexpire",
  WRONG_OLD_PASSWORD: "toast.wrongoldpwd",
  USER_ALREADY_SUBSCRIBED: "toast.alreadyuser",
  ERROR: "toast:general",
  PROFILE_UPDATE_SUCCESS: "toast.profileupdate",
  VERIFICATION_MAIL_SENT: "toast.verificationsent",
  INVALID_PASSWORD: "toast.invalidpassword",
  EMAIL_VERIFICATION_SUCCESS: "toast.verificationsuccess",
  OLD_AND_NEW_PASSWORD_SAME: "toast.oldnewpwdsame",
  ROOM_CATEGORY_INVALID: "toast.nodetails",
  COUPON_DOES_NOT_EXIST: "toast.nocoupon",
  ROOM_UNAVAILABLE: "toast.unavailable",
  EMAIL_ALREADY_VERIFIED: "toast.verifyemail",
  OTP_SUCCESS: "toast.verifyphone",
  OTP_INITIATED: "toast.sendotp",
  OTP_FAILED: "toast.failotp",
  OTP_EXPIRED: "toast.expireotp",
  WRONG_OTP: "toast.wrongotp",
  OTP_DOES_NOT_EXIST: "toast.nootp",
  LIMIT_EXCEEDED: "toast.limitexceed",
  INVALID_DATES: "toast.dateinvalid",
  BOOK_FAILED: "toast.bookingfail",
  PROPERTY_DETAILS_SUBMITTED: "toast.propsubmit",
  PROPERTY_EXIST: "toast.propexist",
  GENERAL_ERROR: "toast.general",
  GENERAL_SUCCESS: "toast.success",
  SUBSCRIBED: "toast.subscribed",
  AGENT_DETAILS_SUBMITTED:
    "Details submitted successfully. We'll get in touch with you soon",
  USER_EXIST_CHECKOUT: "toast.userExistCheckout"
};

export const HTTPMSGCHECK: { [index: string]: string } = {
  OTP_EXPIRED: "Failed to authenticate. Expired OTP",
  OTP_SUCCESS: "Phone number has been verified successfully",
  LIMIT_EXCEEDED: "Resent limit exceeded. Please try after sometime",
  OTP_FAILED: "Failed to send verification code. Please try again later",
  ROOM_UNAVAILABLE:
    "Room is unavailable for the selected dates. Please adjust dates",
};
