import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from 'services/api.service';
import { endPoints } from 'api/endPoints';
import { HTTPMSG } from "config";
import { RootState } from 'store/rootReducer';

export interface Request {
  verification_id: string;
}

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  errorType: "",
  successMessage: ""
};

export const verifyEmailReq: any = createAsyncThunk(
  "verifyEmail",
  async (payload: Request, { rejectWithValue }) => {
    try {
      const response: any = await post(payload, endPoints.USERS.EMAILVERIFICATION);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const verifyEmailSlice = createSlice({
  name: "verifyEmail",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [verifyEmailReq.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorType = payload.message;
      state.errorMessage = HTTPMSG[payload.message];
    },
    [verifyEmailReq.pending]: (state) => {
      state.isFetching = true;
    },
    [verifyEmailReq.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = HTTPMSG[payload.message];
    },
  },
});

export const { clearState } = verifyEmailSlice.actions;
export const verifyEmailSelector = (state: RootState) => state.verifyEmail;
const verifyEmailReducer = verifyEmailSlice.reducer;
export default verifyEmailReducer;