import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";
import { HTTPMSG } from "config";
import { GuestCheckoutService } from "./guest-checkout.service";
import { setLocalStore } from "utils/encryption";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
  data: {
    expiry_time: "",
    token: ""
  }
};

export const verifyOtp: any = createAsyncThunk(
  "verifyOtp",
  async (payload: { email: string; otp: string }, { rejectWithValue }) => {
    try {
      const response: any = await GuestCheckoutService.verifyOtpService(payload);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const verifyOtpSlice = createSlice({
  name: "verifyOtpSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [verifyOtp.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = HTTPMSG[payload.message || "ERROR"];
    },
    [verifyOtp.pending]: (state) => {
      state.isFetching = true;
    },
    [verifyOtp.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = HTTPMSG[payload.message || "ERROR"];
      state.data = payload.data;
      setLocalStore({ cname: `${window.location.host}TEMPAUTH_DETAILS`, cvalue: payload.data.token });
    },
  },
});

export const { clearState } = verifyOtpSlice.actions;
export const verifyOtpSelector = (state: RootState) => state.verifyOtp;
const verifyOtpReducer = verifyOtpSlice.reducer;
export default verifyOtpReducer;
