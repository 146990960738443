import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { post } from "services/api.service";

const initialState: any = {
  loading: false,
  error: false,
  status: "pending",
  data: null,
  selecteddate: {
    from: "",
    to: "",
  },
  selectedstatus: "",
  searchedQuery: "",
};

export const getReservationList: any = createAsyncThunk(
  "getReservationList",
  async (payload: Request, { rejectWithValue }) => {
    try {
      const response: any = await post(payload, endPoints.RESERVATIONS.LIST);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const reservationSlice = createSlice({
  name: "getReservationsSlice",
  initialState: initialState,
  reducers: {
    setSelectedDate: (state, { payload }) => {
      state.selecteddate = payload;
    },
    updateStatus: (state, { payload }) => {
      state.selectedstatus = payload;
    },
    updateSearchText: (state, { payload }) => {
      state.searchedQuery = payload;
    },
    clearFilters: (state) => {
      state.searchedQuery = '';
      state.selectedstatus = '';
    },
  },
  extraReducers: {
    [getReservationList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
    },
    [getReservationList.pending]: (state) => {
      state.loading = true;
    },
    [getReservationList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.status = "fulfilled";
    },
  },
});

export const { setSelectedDate, updateStatus, updateSearchText, clearFilters } =
  reservationSlice.actions;
export const getReservations = (state: RootState) => state.reservation;
const reservationReducer = reservationSlice.reducer;
export default reservationReducer;
