import React from "react";
import styles from "./Jumbotron.module.scss";
import { createMarkup } from "utils/utility";
interface IJumbotron {
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  classes?: string;
  isContentHtml?: boolean;
}
const Jumbotron: React.FC<IJumbotron> = ({
  title,
  subtitle1,
  subtitle2,
  classes,
  isContentHtml
}) => {
  return (
    <>
      <div className={`${styles.jumbotron} section ${classes} layout-banner-img layout-banner-mask`} style={{minHeight: "240px"}}>
        {isContentHtml ? <div className={`${styles.title}`} dangerouslySetInnerHTML={createMarkup(title)}/> : <div className={`${styles.title}`}>{title}</div>}
        <div className={styles.subtitle}>
          {isContentHtml ?
            <p dangerouslySetInnerHTML={createMarkup(subtitle1)} /> :
            <p>{subtitle1}</p>}
          <p>{subtitle2}</p>
        </div>
      </div>
    </>
  );
};

export default Jumbotron;
