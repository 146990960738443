import { API } from "api/api";
import { endPoints } from "api/endPoints";
import { PropertyFormInputs } from "./components/PropertyForm/PropertyForm";

const listPropertyService = (data: PropertyFormInputs) => {
  const body = data;
  return new Promise((resolve, reject) => {
    API.post(endPoints.HOME.LIST_PROPERTY, body)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
    });
  });
};

export const ListPropertyService = {
    listPropertyService: listPropertyService
};
