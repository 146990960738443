import { API } from "api/api";
import { endPoints } from "api/endPoints";
import { ResetUserPassword } from "./reset-password.slice"

const resetPassword = (data:ResetUserPassword) => {
    const body = data;
    return new Promise((resolve, reject) => {
        API.post(endPoints.USERS.RESET_PASSWORD, body)
        .then((response) => resolve(response))
        .catch((error) => {
            reject(error);
        });
    });
}

export const ResetPasswordService = {
    resetPassword: resetPassword
}
