import React from "react";

import NotFound from "components/NotFound/NotFound";

import Login from "features/login/Login";
import Register from "features/register/Register";
import Home from "features/home/Home";
import Profile from "features/profile/Profile";
import ResetPassword from "features/reset-password/ResetPassword";
import ForgotPassword from "features/forgot-password/ForgotPassword";
import Locations from "features/locations/Locations";
import VerifyEmail from "features/verify-email/VerifyEmail";
import Offers from "features/offers/Offers";
import Owner from "features/owner/Owner";

export interface IRoute {
  auth?: boolean;
  component?: React.FC<any>;
  exact?: boolean;
  path: string;
  noHeader?: boolean;
  noFooter?: boolean;
  imports: any;
  noPopup?: boolean;
}

const ROUTES: IRoute[] = [
  {
    auth: false,
    component: Home,
    exact: true,
    path: "/home",
    imports: import("features/home/Home"),
    noHeader: false,
    noFooter: false,
  },
  {
    auth: false,
    component: Login,
    exact: true,
    path: "/login",
    imports: import("features/login/Login"),
    noHeader: true,
    noFooter: true,
  },
  {
    auth: false,
    component: ResetPassword,
    exact: false,
    path: "/reset-password",
    imports: import("features/reset-password/ResetPassword"),
    noHeader: true,
    noFooter: true,
    noPopup: true,
  },
  {
    auth: false,
    component: VerifyEmail,
    exact: false,
    path: "/verify-email",
    imports: import("features/verify-email/VerifyEmail"),
    noPopup: true,
  },
  {
    auth: false,
    component: Register,
    exact: false,
    path: "/register",
    imports: import("features/register/Register"),
    noHeader: true,
    noFooter: true,
  },
  {
    auth: false,
    component: ForgotPassword,
    exact: true,
    path: "/forgot-password",
    imports: import("features/forgot-password/ForgotPassword"),
    noHeader: true,
    noFooter: true,
  },
  {
    auth: false,
    component: NotFound,
    path: "/not-found",
    imports: import("components/NotFound/NotFound"),
    noHeader: false,
    noFooter: false,
    noPopup: true,
  },
  {
    auth: false,
    component: Home,
    path: "/",
    imports: import("features/home/Home"),
    noHeader: false,
    noFooter: false,
  },
  {
    auth: true,
    component: Profile,
    exact: true,
    path: "/profile",
    imports: import("features/profile/Profile"),
  },
  {
    auth: false,
    path: "/contact-us",
    imports: import("features/contact-us/Contact"),
    noHeader: false,
    noFooter: false,
  },
  {
    auth: false,
    path: "/about-us",
    imports: import("features/about-us/AboutUs"),
    noHeader: false,
    noFooter: false,
  },
  {
    auth: false,
    path: "/our-services",
    imports: import("features/our-services/OurServices"),
    noFooter: false,
  },
  {
    auth: false,
    path: "/book-a-stay",
    imports: import("features/search-property/SearchProperty"),
    noFooter: false,
  },
  {
    auth: false,
    path: "/locations",
    component: Locations,
    imports: import("features/locations/Locations"),
    noHeader: false,
    noFooter: false,
  },
  {
    auth: false,
    path: "/search-results",
    imports: import("features/search-result/SearchResult"),
    noHeader: false,
    noFooter: false,
  },
  {
    auth: false,
    path: "/offers",
    exact: true,
    component: Offers,
    imports: import("features/offers/Offers"),
    noHeader: false,
    noFooter: false,
  },
  {
    auth: false,
    path: "/stay-details",
    exact: false,
    imports: import("features/stay-details/StayDetails"),
    noHeader: false,
    noFooter: false,
  },
  {
    auth: false,
    path: "/guest-checkout",
    exact: true,
    imports: import("features/guest-checkout/GuestCheckout"),
    noHeader: true,
    noFooter: true,
    noPopup: true,
  },
  {
    path: "/book-room",
    exact: true,
    imports: import("features/payment/BookRoom"),
    noHeader: false,
    noFooter: false,
    noPopup: true,
  },
  {
    path: "/payment-success",
    exact: true,
    imports: import("features/payment-success/PaymentSuccess"),
    noHeader: false,
    noFooter: false,
    noPopup: true,
  },

  {
    path: "/list-property",
    exact: true,
    imports: import("features/list-property/ListProperty"),
    noHeader: false,
    noFooter: false,
  },
  {
    path: "/partner-with-us",
    exact: true,
    imports: import("features/be-our-partner/ListProperty"),
    noHeader: false,
    noFooter: false,
  },
  {
    path: "/owner",
    exact: false,
    component: Owner,
    imports: import("features/owner/Owner"),
    noHeader: false,
    noFooter: true,
    noPopup: true,
  },
  {
    path: "/cancellation",
    exact: true,
    imports: import("features/cancellation/Cancellation"),
    noHeader: false,
    noFooter: false,
  },
  {
    path: "/payment-and-refund",
    exact: true,
    imports: import("features/payment-refund/PaymentRefund"),
    noHeader: false,
    noFooter: false,
  },
  {
    path: "/check-in",
    exact: false,
    imports: import("features/check-in/CheckInComponent"),
    noHeader: false,
    noFooter: false,
  },
  {
    path: "/privacy",
    exact: true,
    imports: import("features/privacy/Privacy"),
    noHeader: false,
    noFooter: false,
  },
];

export default ROUTES;
