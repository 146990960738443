/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import QuickSearch from "./components/QuickSearch/QuickSearch";
import AreasBanner from "./components/AreasBanner/AreasBanner";
import Offers from "./components/Offers/Offers";
import Attractions from "./components/Attractions/Attractions";
import Testimonials from "./components/Testimonials/Testimonials";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { clearState } from "features/payment/checkout.slice";
import { useAppDispatch } from "store/store";
import { resetAll } from "features/search-property/searchproperty.slice";
import useLocalStorage from "hooks/useLocalStorage";
import { clearStayDetails } from "features/stay-details/stay-details.slice";
import PopupImg from "../../assets/images/popup-img.png";

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const AppStoreContent: React.FC<{ onClick: () => any }> = ({
  onClick,
}) => {
  const [agent] = useState(navigator.userAgent.toLowerCase());
  const isDesktop = !agent.includes("android") && !agent.includes("iphone");

  return (
    <>
      <p className="display-text">
        Download our mobile app and get access to latest offers and access our
        self-service features
      </p>

      <div style={{ marginTop: "18px" }} className="popup-img">
        <div className="popup__phone">
          <img
            src={PopupImg}
            alt="staycae app"
            width={200}
            style={{ minHeight: "250px" }}
          />
        </div>
        <div className="popup__buttons">
          <p className="download-app-text">
            Download our mobile app and get access to latest offers and access
            our self-service features
          </p>
          {agent.includes("android") || isDesktop ? (
            <a
              href="https://play.google.com/store/apps/details?id=com.staycae.staycae"
              target="_blank"
              className="app-store android"
              rel="noreferrer"
            ></a>
          ) : null}

          {agent.includes("iphone") || isDesktop ? (
            <a
              href="https://apps.apple.com/in/app/staycae/id1588672194"
              className={`app-store iOS ${isDesktop ? "mt-4" : ""}`}
              target="_blank"
              rel="noreferrer"
            ></a>
          ) : null}

          <button className="skip-btn" onClick={onClick}>
            Skip Now
          </button>
        </div>
      </div>
    </>
  );
};

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetAll());
    dispatch(clearState());
    dispatch(clearStayDetails());
  }, [dispatch]);
  // eslint-disable-next-line
  const [contDir, setContDir] = useLocalStorage("contentDir", "ltr");

  return (
    <div dir={contDir} className="app-store-wrapper">
      <QuickSearch />
      <AreasBanner />
      <Offers />
      <Attractions />
      <Testimonials />
      <ScrollToTop refID="home-attractions" />
    </div>
  );
};

export default Home;
