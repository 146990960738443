import React, { useEffect, useState } from "react";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  CellUnits,
} from "react-big-scheduler";
import "./StaycaeScheduler.scss";
import withDragDropContext from "./withDnDContext";

import "react-big-scheduler/lib/css/style.css";
import { getReservations } from "../../reservation.slice";
import { useAppSelector } from "store/store";
import { format, compareAsc } from "date-fns";

let resource = [
  {
    id: "r1",
    name: "BUB/MAJ/704A/1BR-NHO",
    parentId: "r0",
  },
  {
    id: "r2",
    name: "BUB/AVA/605A/STU-NHO",
    parentId: "r3",
  },
  {
    id: "r3",
    name: "BUB/CEL/704A/1BR-NHO",
    parentId: "r1",
  },
];

let event = [
  {
    id: 1,
    start: "2017-12-18 09:30:00",
    end: "2017-12-19 23:30:00",
    resourceId: "r1",
    title: "Booking ID - 213456",
    bgColor: "#D9D9D9",
    type: 1,
  },
  {
    id: 2,
    start: "2017-12-18 12:30:00",
    end: "2017-12-26 23:30:00",
    resourceId: "r2",
    title: "Booking ID - 213456",
    resizable: false,
    type: 2,
  },
  {
    id: 3,
    start: "2017-12-19 12:30:00",
    end: "2017-12-20 23:30:00",
    resourceId: "r3",
    title: "Booking ID - 213456",
    movable: false,
    type: 3,
  },
  {
    id: 11,
    start: "2017-12-21 18:30:00",
    end: "2017-12-22 23:30:00",
    resourceId: "r1",
    title: "Booking ID - 213456",
    type: 2,
  },
];

const StaycaeScheduler: React.FC<any> = (props) => {
  const [resources] = useState(resource);
  const [events] = useState(event);
  const { selecteddate } = useAppSelector(getReservations);

  const getCustomDate = (schedulerData: any, num: any, date = undefined) => {

    let startDate = "2021-06-01",
      endDate = "2021-11-01",
      cellUnit = CellUnits.Day;
    if ((selecteddate.from && selecteddate.to) !== "") {
      startDate = format(new Date(selecteddate.from), "yyyy-MM-dd");
      endDate = format(new Date(selecteddate.to), "yyyy-MM-dd");
      cellUnit = CellUnits.Day;
    }

    return {
      startDate,
      endDate,
      cellUnit,
    };
  };
  let schedulerData = new SchedulerData(
    format(new Date(), "yyyy-MM-dd"),
    ViewTypes.Custom,
    false,
    false,
    {
      resourceName: "UNITS",
      schedulerWidth: "90%",
      eventItemLineHeight: 60,
      dayResourceTableWidth: 300,
      monthResourceTableWidth: 300,
      yearResourceTableWidth: 300,
      weekResourceTableWidth: 300,
      agendaResourceTableWidth: 300,
      calendarPopoverEnabled: false,
      headerEnabled: false,
      views: [
        {
          viewName: "Day",
          viewType: ViewTypes.Day,
          showAgenda: false,
          isEventPerspective: false,
        },
        {
          viewName: "Week",
          viewType: ViewTypes.Week,
          showAgenda: false,
          isEventPerspective: false,
        },
        {
          viewName: "Month",
          viewType: ViewTypes.Month,
          showAgenda: false,
          isEventPerspective: false,
        },
        {
          viewName: "Year",
          viewType: ViewTypes.Year,
          showAgenda: false,
          isEventPerspective: false,
        },
      ],
    },
    {
      getCustomDateFunc: getCustomDate,
    }
  );

  schedulerData.setResources(resources);
  schedulerData.setEvents(events);
  
  useEffect(() => {
    if (props?.reservations?.length > 0) {
      const reservationslist = props.reservations;
      const eventlist = props.reservations;
      const resourceslist = reservationslist
        .map((item: any, index: any) => {
          return {
            id: `r${index}`,
            name: item.room.name,
          };
        })
        .reduce((acc: any, { name, id }: { name: any; id: any }) => {
          acc[name] = { name: name, id: "" };
          acc[name].id = id;

          return acc;
        }, []);
      const resources = Object.values(resourceslist);
      const events = eventlist.map((item: any, index: any) => {
        return {
          id: `${index}`,
          start: format(new Date(item.arrival_date), "yyyy-MM-dd"),
          end: format(new Date(item.departure_date), "yyyy-MM-dd"),
          resourceId: resourceslist[item.room.name].id,
          title: `Booking ID - ${item.reservation_id}`,
          movable: false,
          type:
            compareAsc(new Date(item.departure_date), new Date()) === -1
              ? 1
              : compareAsc(new Date(item.arrival_date), new Date()) === 1
              ? 3
              : 2,
        };
      });
      schedulerData.setResources(resources as any);
      schedulerData.setEvents(events);
      setViewModel(schedulerData);
    }

    // eslint-disable-next-line
  }, [props]);

  const [viewModel, setViewModel] = useState(schedulerData);

  const eventItemTemplateResolver = (
    schedulerData: any,
    event: any,
    bgColor: any,
    isStart: any,
    isEnd: any,
    mustAddCssClass: any,
    mustBeHeight: any,
    agendaMaxEventWidth: any
  ) => {
    let borderWidth = isStart ? "4" : "0";
    let borderColor = "rgba(0,139,236,1)",
      backgroundColor = "#80C5F6";
    let titleText = schedulerData.behaviors.getEventTextFunc(
      schedulerData,
      event
    );
    if (!!event.type) {
      borderColor =
        event.type === 1
          ? " #a42420"
          : event.type === 2
          ? "#70b730"
          : "#f39b3e";
      backgroundColor =
        event.type === 1 ? "#a42420" : event.type === 3 ? "#f39b3e" : "#70b730";
    }
    let divStyle: any = {
      borderLeft: borderWidth + "px solid " + borderColor,
      borderRight: borderWidth + "px solid " + borderColor,
      borderRadius: "30px",
      backgroundColor: backgroundColor,
      paddingLeft: "20px",
      height: "30px",
    };
    if (!!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{ marginLeft: "4px", lineHeight: `${30}px` }}>
          {titleText}
        </span>
      </div>
    );
  };
  const prevClick = (schedulerData: any) => {
    schedulerData.prev();
    schedulerData.setEvents(events);
    setViewModel(schedulerData);
  };

  const nextClick = (schedulerData: any) => {
    schedulerData.next();
    schedulerData.setEvents(events);
    setViewModel(schedulerData);
  };

  const onViewChange = (schedulerData: any, view: any) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(events);

    setViewModel(schedulerData);
  };

  const onSelectDate = (schedulerData: any, date: any) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(events);

    setViewModel(schedulerData);
  };

  const eventItemPopoverTemplateResolver = (
    schedulerData: any,
    eventItem: any,
    title: any,
    start: any,
    end: any,
    statusColor: any
  ) => {
    return (
      <div style={{ width: "300px" }}>
        <div className="ant-row-flex ant-row-flex-middle">
          <div className="ant-col ant-col-2">
            <div
              className="status-dot"
              style={{
                backgroundColor:
                  eventItem.type === 1
                    ? "#a42420"
                    : eventItem.type === 3
                    ? "#f39b3e"
                    : "#70b730",
              }}
            />
          </div>
          <div className="overflow-text ant-col ant-col-22 overflow-text">
            <span className="header2-text" title={title}>
              {title}
            </span>
          </div>
        </div>
        <div className="ant-row-flex ant-row-flex-middle">
          <div className="ant-col ant-col-2">
            <div />
          </div>
          <div className="ant-col ant-col-22">
            <span
              className="header1-text"
              style={{
                color:
                  eventItem.type === 1
                    ? "#a42420"
                    : eventItem.type === 3
                    ? "#f39b3e"
                    : "#70b730",
              }}
            >
              {start.format("MMM-DD")} - {end.format("MMM-DD")}
            </span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {!props.loading ? (
        <div>
          <Scheduler
            schedulerData={viewModel}
            prevClick={prevClick}
            nextClick={nextClick}
            onSelectDate={onSelectDate}
            onViewChange={onViewChange}
            eventItemTemplateResolver={eventItemTemplateResolver}
            eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
          />
        </div>
      ) : (
        null
      )}
    </div>
  );
};

export default withDragDropContext(StaycaeScheduler);
