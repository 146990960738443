import React from "react";

import "./CouponLoader.scss";

const CouponLoader: React.FC = () => {
  return (
    <>
      <article className={`message coupons-grid coupons-loader mt-3`}>
        <div className={`message-header`} data-action="collapse">
          <div className="accordian-text">
            <div className="coupons-content">
              <div className="name"></div>
              <div className="copy-loader"></div>
            </div>
            <div className="discount"></div>
          </div>
          <div className="validity">
            <div className="valid-until"></div>
            <div className="date"></div>
          </div>
          <div className={`play-button`}></div>
        </div>
      </article>
      <hr />
      <article className={`message coupons-grid coupons-loader mt-3`}>
        <div className={`message-header`} data-action="collapse">
          <div className="accordian-text">
            <div className="coupons-content">
              <div className="name"></div>
              <div className="copy-loader"></div>
            </div>
            <div className="discount"></div>
          </div>
          <div className="validity">
            <div className="valid-until"></div>
            <div className="date"></div>
          </div>
          <div className={`play-button`}></div>
        </div>
      </article>
    </>
  );
};

export default CouponLoader;
