import { setToastState } from "components/Toast/Toast.slice";
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import "./VerifyEmail.scss";
import {
  clearState,
  verifyEmailReq,
  verifyEmailSelector,
} from "./verifyEmail.slice";
import { updateSelection } from "features/search-property/searchproperty.slice";
import { format } from "date-fns";
import { updateGuestNumber } from "features/search-property/components/AddGuest/addguest.slice";
import SearchSkeleton from "features/search-property/components/SearchSkeleton/SearchSkeleton";
import { Context } from "components/app/Wrapper";
import {
  setCurrentRoom,
  setPrice,
  setPriceSelected,
  setStayDetails,
} from "features/stay-details/stay-details.slice";
import useLocalStorage from "hooks/useLocalStorage";

const VerifyEmail: React.FC<any> = (props) => {
  const context: any = useContext(Context);
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [navigateUrl, setNavigateUrl] = useState("");
  const { isSuccess, isError, errorMessage, successMessage } =
    useAppSelector(verifyEmailSelector);

  // eslint-disable-next-line
  const [registerFlow, setRegisterFlow] = useLocalStorage(
    "registerFlow",
    "false"
  );

  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    dispatch(verifyEmailReq({ verification_id: id }));
    const room_category_slug = new URLSearchParams(search).get(
      "room_category_slug"
    );
    navigateTo(id, room_category_slug);
    // eslint-disable-next-line
  }, [dispatch, search, context.locale]);

  const navigateTo = (id: string | null, room_category_slug: string | null) => {
    if (room_category_slug) {
      updateCheckIn(new URLSearchParams(search).get("arrival_date"));
      updateCheckOut(new URLSearchParams(search).get("departure_date"));
      updateGuestCount(new URLSearchParams(search).get("guests"));
      history.push(`/stay-details/${room_category_slug}`);
    } else {
      setNavigateUrl("/");
    }
  };

  const updateCheckIn = (checkIn: any) => {
    const formattedDate = format(new Date(checkIn), "dd LLL, yyyy");
    const requestDate = format(new Date(checkIn), "yyyy-MM-dd");
    dispatch(
      updateSelection({
        category: "currentFrom",
        value: formattedDate,
        formattedD: requestDate,
        master: "checkin",
      })
    );
  };

  const updateCheckOut = (checkOut: any) => {
    const formattedDate = format(new Date(checkOut), "dd LLL, yyyy");
    const requestDate = format(new Date(checkOut), "yyyy-MM-dd");
    dispatch(
      updateSelection({
        category: "currentTo",
        value: formattedDate,
        formattedD: requestDate,
        master: "checkout",
      })
    );
  };

  const updateGuestCount = (guestCount: any) => {
    dispatch(updateGuestNumber({ type: "adults", count: Number(guestCount) }));
    dispatch(
      updateSelection({
        category: "currentGuests",
        value: {
          adult: Number(guestCount),
        },
        master: "guests",
      })
    );
  };

  useEffect(() => {
    if (registerFlow === "true") {
      dispatch(
        setPriceSelected(JSON.parse(localStorage.getItem("priceSelected")!))
      );
      dispatch(setCurrentRoom(localStorage.getItem("currentRoom")));
      dispatch(setPrice(JSON.parse(localStorage.getItem("price")!)));
      const checkin = JSON.parse(localStorage.getItem("checkin")!);
      const checkout = JSON.parse(localStorage.getItem("checkout")!);
      const guests = JSON.parse(localStorage.getItem("guests")!);

      dispatch(
        updateSelection({
          category: "currentFrom",
          formattedD: checkin.formattedD,
          value: checkin.currentFrom,
          master: "checkin",
        })
      );
      dispatch(
        updateSelection({
          category: "currentTo",
          formattedD: checkout.formattedD,
          value: checkout.currentTo,
          master: "checkout",
        })
      );
      dispatch(
        updateSelection({
          category: "currentGuests",
          value: {
            adult: guests.currentGuests.adult,
          },
          master: "guests",
        })
      );
      dispatch(setStayDetails(JSON.parse(localStorage.getItem("stayDetail")!)));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(
        setToastState({ type: "danger", message: errorMessage, dismiss: 5000 })
      );
      dispatch(clearState());
      history.push(navigateUrl);
    }
    if (isSuccess) {
      dispatch(
        setToastState({
          type: "success",
          message: successMessage,
          dismiss: 5000,
        })
      );
      dispatch(clearState());
      if (localStorage.getItem("registerFlow") === '"true"') {
        history.push(
          `/stay-details/${
            JSON.parse(localStorage.getItem("stayDetail")!)?.slug
          }`
        );
      } else history.push(navigateUrl);
    }
    // eslint-disable-next-line
  }, [isError, isSuccess, errorMessage, successMessage]);

  return (
    <div className="container container-height has-text-centered p-auto">
      <SearchSkeleton></SearchSkeleton>
    </div>
  );
};

export default VerifyEmail;
