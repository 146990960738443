import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";

import { ForgotPasswordState } from "./forgot-password.interface";
import { HTTPMSG } from "config";
import {
  ForgotPasswordService,
  ForgotPasswordUserProps,
} from "./forgot-password.service";

export const initialState: ForgotPasswordState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

export const forgotUserPassword: any = createAsyncThunk(
  "forgotPassword",
  async (payload: ForgotPasswordUserProps, { rejectWithValue }) => {
    try {
      const response: any = await ForgotPasswordService.forgotPassword(payload);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [forgotUserPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = HTTPMSG[payload.message];
    },
    [forgotUserPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [forgotUserPassword.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = HTTPMSG[payload.message];
    },
  },
});

export const { clearState } = forgotPasswordSlice.actions;
export const forgotPasswordSelector = (state: RootState) =>
  state.forgotPasswordReducer;
const forgotPasswordReducer = forgotPasswordSlice.reducer;
export default forgotPasswordReducer;
