import { multiSelectSelector } from "components/multi-select/multi-select.slice";
import { setToastState } from "components/Toast/Toast.slice";
import useClickOutside from "hooks/useClickOutside";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getReservations, updateStatus } from "../../reservation.slice";
import "./ReservationDD.scss";

interface ReservationDDProps {
  onClick?: () => void;
  onSearch?: (status: boolean) => any;
  title: string;
  image?: any;
  contentClass?: string;
  selectedValue?: string;
  name?: string;
}
const ReservationDD: React.FC<ReservationDDProps> = ({
  onClick,
  title,
  image,
  contentClass,
  selectedValue,
  name,
}) => {
  const statusList = ["Confirmed", "Arrived", "Departed"];
  const [toggleDropDown, setToggleReservationDropDown] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(
    "Select a Reservation Status"
  );
  const dispatch = useAppDispatch();
  const { selectedstatus } = useAppSelector(getReservations);
  const { options } = useAppSelector(multiSelectSelector);
  useEffect(() => {
    if (selectedstatus === "") {
      setSelectedStatus("Select a Reservation Status");
    }
  }, [selectedstatus]);

  const node = useRef<any>();
  useClickOutside(node, () => {
    setToggleReservationDropDown(false);
  });

  return (
    <>
      <div>Status</div>
      <div
        className="search-bar dropdown is-active owner-reservation-selection"
        ref={node}
      >
        <div
          className="dropdown-trigger owner-scheduler-datepicker owner-dropdown"
          onClick={() => setToggleReservationDropDown(!toggleDropDown)}
        >
          {selectedStatus}
          <span className="icon is-small ml-2 toggle-icon">
            {toggleDropDown ? (
              <i className="fas fa-angle-up" aria-hidden="true"></i>
            ) : (
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            )}
          </span>
        </div>
        {toggleDropDown && (
          <div id="dropdown-menu" role="menu">
            <div
              className={`dropdown-content search-dropdown owner-reservation ${contentClass}`}
              style={{ marginTop: "0.15rem" }}
            >
              {statusList.map((item: any, index: number) => (
                <div
                  className={`item autoc-item ${
                    selectedstatus === item ? "active" : ""
                  }`}
                  key={index}
                  onClick={(e: any) => {
                    // Update status only if units are selected
                    if (options.length) {
                      setSelectedStatus(e.target.innerText);
                      setToggleReservationDropDown(!toggleDropDown);
                      dispatch(updateStatus(e.target.innerText));
                    } else
                      dispatch(
                        setToastState({
                          type: "danger",
                          message: "Please select units first!",
                          dismiss: 3000,
                        })
                      );
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReservationDD;
