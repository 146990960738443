import React, { Suspense } from "react";

interface Props {
  errorRender?: string;
}

interface State {
  error: boolean;
}

class ErrorBountary extends React.Component<Props, State> {
  constructor(props: object) {
    super(props);
    this.state = { error: false };
  }
  static getDerivedStateFromError() {
    return { error: true };
  }
  render() {
    if (this.state.error) {
      return this.props.errorRender ? (
        <span>{this.props.errorRender}</span>
      ) : (
          <span>Loading failed! Please reload.</span>
      );
    }
    return this.props.children;
  }
}

const ErrorHandler: React.FC<{
  children: React.ReactChild;
  fallBack?: Function;
  cusError?: string;
}> = ({ children, fallBack, cusError }) => {
  return (
    <ErrorBountary errorRender={cusError}>
      <Suspense
        fallback={
          fallBack ? (
            fallBack()
          ) : (
              <div></div>
          )
        }
      >
        {children}
      </Suspense>
    </ErrorBountary>
  );
};

export default ErrorHandler;
