import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import "features/search-property/SearchProperty.scss";
import SearchDropDown from "./Search-DropDown/SearchDropDown";
import { Calendar } from "react-date-range";
import { useAppDispatch, useAppSelector } from "store/store";
import calendar from "assets/icons/search/calendar.svg";
import { format } from "date-fns";
import add from 'date-fns/add'
import {
    currentlyActive,
    updateSelection,
    searchSelector,
} from "features/search-property/searchproperty.slice";
import useClickOutside from "hooks/useClickOutside";
import { useLocation } from "react-router-dom";
import { updateDates } from "features/stay-details/stay-details.slice";
import { Context } from "components/app/Wrapper";

const CheckOutDropDown: React.FC = () => {
    const context: any = useContext(Context);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isNowActive, checkout, checkin } = useAppSelector(searchSelector);
    const [show, setCheckout] = useState(false);
    const node = React.useRef<HTMLDivElement>(null);
    const toggle = () => {
        setCheckout(!show);
    }
    const location = useLocation().pathname;
    let [tommorow, setTommorow] = useState(new Date());
    checkin.currentFrom ? tommorow = new Date(checkin.currentFrom) : tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    useEffect(() => {
        if (show) {
            dispatch(currentlyActive("CHECKOUT"));
        } else {
            dispatch(currentlyActive(""));
        }
    }, [show, dispatch, context.locale]);

    useEffect(() => {
        checkin.currentFrom ? setTommorow(new Date(checkin.currentFrom)) : setTommorow(new Date());
        if (checkin.currentFrom && new Date(checkout.currentTo) <= new Date(checkin.currentFrom)) {
            const fromdatenew = add(new Date(checkin.currentFrom), {
                days: 1
            })
            const formattedDate = format(new Date(fromdatenew), "dd LLL, yyyy");
            const requestDate = format(new Date(fromdatenew), 'yyyy-MM-dd');
            dispatch(
                updateSelection({
                    category: "currentTo",
                    value: formattedDate,
                    formattedD: requestDate,
                    master: "checkout",
                })
            );

        }
        // eslint-disable-next-line
    }, [checkin.currentFrom, dispatch]);


    useClickOutside(node, () => { setCheckout(false); })
    const handleValueChange = (date: any) => {
        const formattedDate = format(new Date(date), "dd LLL, yyyy");
        const requestDate = format(new Date(date), 'yyyy-MM-dd');
        // setToDate(date);
        dispatch(
            updateSelection({
                category: "currentTo",
                value: formattedDate,
                formattedD: requestDate,
                master: "checkout",
            })
        );
        toggle();
        if (location.includes('/stay-details')) {
            dispatch(updateDates(false));
        }
    };
    return (
        <div ref={node} dir='ltr' className="checkIn">
            <SearchDropDown
                name="CHECKOUT"
                title={t('search.to')}
                onClick={toggle}
                toggleView={isNowActive}
                subtitle={checkout.currentTo || t('search.checkOut')}
                image={calendar}
                selectedValue={checkout.currentTo}
            >
                <Calendar
                    onChange={(item: any) => handleValueChange(item)}
                    date={tommorow as any}
                    minDate={tommorow}
                />
            </SearchDropDown>
        </div>
    );
};

export default CheckOutDropDown;
