import { API } from "api/api";
import { endPoints } from "api/endPoints";

const isReferralActive = () => {
    return new Promise((resolve, reject) => {
        API.get(endPoints.REFERRAL.ACTIVE)
        .then((response) => resolve(response))
        .catch((error) => {
            reject(error);
        });
    });
}

const referralInvite = (data: string[]) => {
    return new Promise((resolve, reject) => {
        API.post(endPoints.REFERRAL.REFER_FRIEND, {email: data})
        .then((response) => resolve(response))
        .catch((error) => {
            reject(error);
        });
    });
}

export const ReferralService = {
    isReferralActive: isReferralActive,
    referralInvite: referralInvite
}
