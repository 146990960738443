import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { API } from "api/api";
import { RootState } from "store/rootReducer";
interface ResultState {
  isFetchingSearchResult: boolean;
  searchResultList: any;
  amenities: any;
  search_depth: any;
  total_count: number;
  previousLang: string
}
export const initialState: ResultState = {
  isFetchingSearchResult: false,
  searchResultList: [],
  amenities: [],
  search_depth: {},
  total_count: 0,
  previousLang: ''
};
export const getSearchResult: any = createAsyncThunk(
  "getresult",
  async (params: any) => {
    let obj:any = {...params};
    delete params.lang;
    const rsp: any = await API.get(endPoints.SEARCH, {
      params
    });
    return {
      data: rsp.data.data,
      lang: obj.lang
    };
  }
);
export const getAmenities: any = createAsyncThunk(
  "getamenities",
  async (params) => {
    const rsp: any = await API.get(endPoints.AMENITIES, {
      params
    });
    return {
      data: rsp.data.data

    };
  }
);
const searchresultSlice = createSlice({
  name: "resultlist",
  initialState,

  reducers: {

    clearSearchResult: (state) => {
      state.isFetchingSearchResult = false;
      state.searchResultList = [];
    },
  },

  extraReducers: {
    [getSearchResult.pending]: (state, action) => {
      state.isFetchingSearchResult = false;
    },
    [getSearchResult.fulfilled]: (state, action) => {
      state.isFetchingSearchResult = true;
      const { data, lang } = action.payload;
      if (state.previousLang !== lang){
      state.searchResultList = [];
      }
      state.previousLang = lang;
      state.searchResultList = [...state.searchResultList, ...data.room_categories];
      state.search_depth = data.search_depth;
      state.total_count = data.total_count;
    },
    [getSearchResult.rejected]: (state, action) => {
      state.isFetchingSearchResult = true;
      state.searchResultList = [];
      state.search_depth = {};
      state.total_count = 0
    },
    [getAmenities.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.amenities = data;
    },
    [getAmenities.rejected]: (state, action) => {
      state.amenities = [];
    },
  },
});

export const { clearSearchResult } = searchresultSlice.actions;
export const searchresultSelector = (state: RootState) => state.searchresults;
const searchResultReducer = searchresultSlice.reducer;
export default searchResultReducer;
