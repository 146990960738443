/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import WishlistCard from "./wishlisted/WishlistCard";
import { useAppDispatch, useAppSelector } from "store/store";
import { getWishlists, wishListProfileSelector } from "./wishlists.slice";

import "./Wishlists.scss";
import nowishes from "assets/icons/no-wish.svg";
import WishListLoader from "features/search-result/components/ResultCard/components/WishList/components/WishListLoader/WishListLoader";
import { Context } from "components/app/Wrapper";

const Wishlists: React.FC = (props) => {
  const { t } = useTranslation();
  const context: any = useContext(Context);
  const dispatch = useAppDispatch();
  const { fetchedWishlist, wishlists, fetchingWishlist } = useAppSelector(
    wishListProfileSelector
  );

  useEffect(() => {
    dispatch(getWishlists());
  }, [dispatch, context.locale]);

  if (fetchedWishlist && wishlists.length === 0) {
    return (
      <div className="coupons-wrapper no-result-coupon">
        <img src={nowishes} alt="No items in wishlist" />
        <h3>{t("wishlist.nolist")}</h3>
        {/* <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod
        </p> */}
        <a href="/">{t("notFound.btn")}</a>
      </div>
    );
  }

  return (
    <>
      <div className="coupons-wrapper wishlist-wrap">
        <div className="coupons-section">
          <div className="coupons-header-grid corner-items">
            <span className="title">{t("profile.wishlist")}</span>
            {/* <button className="edit-btn"><i className="fas fa-pencil-alt"></i> REFER AND EARN</button> */}
          </div>
        </div>

        {/* Coupons list */}
        <div id="accordian_parent" className="wishlist-wrapper-di">
          {fetchingWishlist ? (
            <WishListLoader />
          ) : (
            wishlists.map((wishlist, index) => (
              <WishlistCard {...wishlist} />
            ))
          )}
        </div>
      </div>
    </>
  );
};
export default Wishlists;
