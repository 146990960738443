import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import defaultImg from "assets/images/offers/offer2.png";
import "./MonthlyDeals.scss";
import Timer from "../../timer/Timer";
import { IData, IOffers } from "features/offers/offers.interface";
import { OFFER_MAPPING } from "features/offers/offers.config";
import { formattedTitle } from "features/offers/offers-utils";
import { activeFilters, resetAll } from "features/search-property/searchproperty.slice";
import { useAppDispatch } from "store/store";
import { useHistory } from "react-router-dom";
import { clearSearchResult } from "features/search-result/searchresult.slice";
import { DirectionCheck } from "utils/classnameSwitch";
import { format } from "date-fns";

const MonthlyDealsCarousel: React.FC<IData> = ({
  offers
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const basicSetting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
  };

  let carouselSettings = {};
  if (!offers.length)
    return null;
  else if (offers.length === 1) {
    carouselSettings = {
      ...basicSetting,
      slidesToShow: 1
    };
  }
  else if (offers.length === 2) {
    carouselSettings = {
      ...basicSetting,
      slidesToShow: 2,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
  }
  else {
    carouselSettings = {
      ...basicSetting,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1441,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
  }
  const showOfferDetails = (id: any) => {
    const params: any = {};
    params['offer_id'] = id;
    params['page'] = null;
    dispatch(clearSearchResult());
    dispatch(resetAll());
    dispatch(activeFilters(params));
    history.push("/search-results");
  }

  const buildStayText = (data: IOffers) => {
    let text = "";
    if (data?.arrival_date_gt && data?.departure_date_lt) {
      text = `${t('offers.fromTxt')} ${format(new Date(data?.arrival_date_gt), "LLL dd")} ${t('offers.toText')} ${format(new Date(data.departure_date_lt), "LLL dd")}`;
      text = data?.length_of_stay ? `${t('offers.losTxt1')} ${data.length_of_stay} ${t('offers.losTxt3')} ${text}` : `${t('offers.losTxt1')} ${text}`;
      return text;
    }
    if (data?.length_of_stay) {
      text = `${t('offers.losTxt1')} ${data.length_of_stay} ${t('offers.losTxt2')}`
    }
    return text;
  }
  
  return (
    <>
      <h3 className="layout-grid offer-sections-header" dir={DirectionCheck()}>
        {t('offers.monthlyDeal')}
      </h3>
      <div className="monthly-deal-wrapper" dir={DirectionCheck()}>
        <div className={`monthly-layout-grid ${offers.length === 1 ? 'single-card-align' : ''} ${offers.length === 2 ? 'two-card-align ' : 'multiple-cards'}`}>
          <Slider {...carouselSettings}>
            {offers.map((data, key) => {
              return <div className="offers-wrapper" key={key} onClick={() => { showOfferDetails(data.id) }}>
                <div className="offers-inner-wrapper">
                  <div className="deal-img" style={{ backgroundImage: `url(${data.image || defaultImg})` }}>
                    <p className="category">{t(OFFER_MAPPING[data.type])}</p>
                  </div>
                  <div className="deal-content">
                    {formattedTitle(data.title || t('offers.hurry'), data.highlight_text, "deal-title", "deal-offer", false)}
                    {formattedTitle(data.description_1, data.highlight_text, "deal-subtitle", "deal-offer", true)}
                    {((data.arrival_date_gt && data.departure_date_lt) || data.length_of_stay) ? <p className="desc-green">{buildStayText(data)}</p> : null}
                    {data.expiry ? <Timer expiryTimestamp={new Date(data.expiry).getTime()} /> : null}
                  </div>
                </div>
              </div>
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default MonthlyDealsCarousel;
