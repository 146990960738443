import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";

export const initialState = {
  isCancellationFetched: false,
  cancellation: {
    maintitle: "",
    maintitledesc: "",
    subtitle: "",
    fields: []
  },
  meta: {
    content: "",
    keyWord: "",
    name: ""
  }
};

export const cancellationData: any = createAsyncThunk(
  "cancellation",
  async () => {
    const rsp: any = await get((endPoints.STATIC.CANCELLATION));
    return {
      data: rsp.data.data
    };
  }
);
const cancellationSlice = createSlice({
  name: "cancellation",
  initialState,
  reducers: {
  },
  extraReducers: {
    [cancellationData.pending]: (state, action) => {
      state.isCancellationFetched = false;
    },
    [cancellationData.fulfilled]: (state, action) => {
      state.isCancellationFetched = true;
      const { data } = action.payload;
      state.cancellation = data.data;
      state.meta = data.meta;
    },
    [cancellationData.rejected]: (state, action) => {
      state.isCancellationFetched = true;
      state.cancellation = initialState.cancellation;
      state.meta = initialState.meta;
    },
  },
});

export const cancellationSelector = (state: RootState) => state.cancellation;
const cancellationReducer = cancellationSlice.reducer;
export default cancellationReducer;
