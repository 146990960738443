import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "components/Inputs/TextField";
import AuthLayout from "components/Layout/AuthLayout/AuthLayout";
import SocialMediaWrapper from "components/SocialMediaWrapper/SocialMediaWrapper";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import { DirectionCheck } from "../../utils/classnameSwitch";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelector, clearState } from "store/slices/auth.slice";
import { registerRequest as RegisterInputs } from "./register.interface";
import { registerUser, socialMediaLogin } from "store/slices/auth.slice";
import { checkPassword, clearBookingStates } from "utils/utility";
import { setToastState } from "components/Toast/Toast.slice";

import "./Register.scss";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import {
  stayDetailSelector,
  updateDates,
} from "features/stay-details/stay-details.slice";
import { guestCheckoutSelector } from "features/guest-checkout/guest-checkout.slice";
import { checkoutSelector } from "features/payment/checkout.slice";
import useLocalStorage from "hooks/useLocalStorage";
import { updateSelection } from "features/search-property/searchproperty.slice";

interface RegisterPageProps {
  history: {
    push: (path: string) => any;
  };
  location: {
    state: {
      prevPath: string;
    };
  };
}

const Register: React.FC<RegisterPageProps> = (props) => {
  const { t } = useTranslation();
  const { state } = props.location;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [passwordError, setPasswordError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterInputs>();
  const [captcha, setCaptcha] = useState();
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [phone, setPhone] = useState("");
  // eslint-disable-next-line
  const [resetPasswordFlow, setResetPasswordFlow] = useLocalStorage(
    "resetPasswordFlow",
    "false"
  );
  // eslint-disable-next-line
  const [registerFlow, setRegisterFlow] = useLocalStorage(
    "registerFlow",
    "false"
  );

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    userData,
    successMessage,
  } = useAppSelector(userSelector);
  const { currentRoom } = useAppSelector(stayDetailSelector);

  const { isFetching: checkoutFetch } = useAppSelector(guestCheckoutSelector);
  const { fetchingReserID } = useAppSelector(checkoutSelector);

  const { search } = useLocation();
  const [referralCode] = useState(search ? search.split("=").pop() : "");

  useEffect(() => {
    if (isError) {
      dispatch(
        setToastState({ type: "danger", message: errorMessage, dismiss: 3500 })
      );
      dispatch(clearState());
    }
    if (isSuccess) {
      const { is_social_media_login }: any = userData;
      if (!is_social_media_login) {
        if (successMessage === "SIGNUP_SUCCESS_REFERRAL_GRANTED") {
          dispatch(
            setToastState({
              type: "success",
              message: t("register.toastMsgs.successfulReferral"),
              dismiss: 3500,
            })
          );
        } else if (successMessage === "SIGNUP_SUCCESS_REFERRAL_EXPIRED") {
          dispatch(
            setToastState({
              type: "success",
              message: t("register.toastMsgs.expiredReferral"),
              dismiss: 3500,
            })
          );
        } else
          dispatch(
            setToastState({
              type: "success",
              message: t("register.toastMsgs.defaultSignup"),
              dismiss: 3500,
            })
          );
      }
      if (state?.prevPath.includes("/stay-details") && currentRoom.trim()) {
        dispatch(updateDates(false));
        history.push(state.prevPath);
        dispatch(clearState());
      } else {
        history.push(state?.prevPath || "/");
        dispatch(clearState());
      }
    }
    // eslint-disable-next-line
  }, [
    isError,
    isSuccess,
    dispatch,
    errorMessage,
    history,
    state?.prevPath,
    currentRoom,
    userData,
    successMessage,
  ]);

  const captchaChange = (value: any) => {
    setCaptcha(value);
  };

  const onSubmit: SubmitHandler<RegisterInputs> = (data: any) => {
    setSubmitted(true);
    if (captcha && phone?.length >= 10 && phone?.length < 15) {
      data.phone_number = phone;
      dispatch(
        registerUser(
          referralCode?.trim() ? { ...data, referral_code: referralCode } : data
        )
      );
      setSubmitted(false);
    }
  };

  const [passwordShowIcon, setPasswordShowIcon] = useState(false);
  const togglePasswordVisiblity = (status: boolean) =>
    setPasswordShowIcon(status);

  const onSocialMediaLogin = (props: any) => {
    dispatch(socialMediaLogin(props));
  };

  const validatePassword = (pwd: string) => {
    const { errorMsg, isValid } = checkPassword(pwd);
    setPasswordError(t(errorMsg));
    return isValid;
  };

  const clearDates = () => {
    dispatch(
      updateSelection({
        category: "currentFrom",
        value: "",
        formattedD: "",
        master: "checkin",
      })
    );
    dispatch(
      updateSelection({
        category: "currentTo",
        value: "",
        formattedD: "",
        master: "checkout",
      })
    );
  };

  const navigateToGuest = () => {
    dispatch(updateDates(false));
    // Storage Clean up
    if (resetPasswordFlow === "true" || registerFlow === "true") {
      clearDates();
      clearBookingStates();
    }
    history.push(state?.prevPath || "/");
  };

  return (
    <AuthLayout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="register-screen"
        dir={DirectionCheck()}
      >
        <div className="row">
          <TextField
            label={t("register.firstname")}
            placeholder={t("register.firstname")}
            register={register}
            name="first_name"
            type="text"
            errors={errors}
            rules={{
              required: true,
              maxLength: 25,
            }}
            className="reg-first-child"
          />
          <TextField
            label={t("register.lastname")}
            placeholder={t("register.lastname")}
            register={register}
            name="last_name"
            type="text"
            errors={errors}
            rules={{
              required: true,
              maxLength: 25,
            }}
          />
        </div>
        <TextField
          label={t("register.emailaddr")}
          placeholder={t("register.emailaddr")}
          register={register}
          name="email"
          type="email"
          errors={errors}
          rules={{
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          }}
        />

        <div className="field text-field ">
          <PhoneInput
            containerClass={`${
              phone?.length < 4 && isSubmit ? "apply-error" : ""
            } 
            ${
              phone?.length > 4 &&
              (phone?.length < 10 || phone?.length >= 15) &&
              isSubmit
                ? "apply-error"
                : ""
            }`}
            inputClass={"input phone-no"}
            country={"ae"}
            value={phone}
            countryCodeEditable={false}
            onChange={(phone) => setPhone(phone as any)}
            defaultMask=".................."
            placeholder={t("register.phone")}
            specialLabel={t("register.phone")}
            preferredCountries={["ae"]}
            inputProps={{
              name: "phone",
              required: true,
            }}
          />
          {phone?.length < 4 && isSubmit && (
            <p className="help is-danger">{t("errmsg.phonerequire")}</p>
          )}
          {phone?.length > 4 &&
            (phone?.length < 10 || phone?.length >= 15) &&
            isSubmit && (
              <p className="help is-danger">{t("errmsg.phoneinvalid")}</p>
            )}
        </div>

        <TextField
          label={t("common.pwd")}
          placeholder={t("common.pwd")}
          register={register}
          name="password"
          type={passwordShowIcon ? "text" : "password"}
          errors={errors}
          icon={passwordShowIcon ? "show" : "hide"}
          rules={{
            validate: validatePassword,
          }}
          customError={passwordError}
          passwordVisibibility={togglePasswordVisiblity}
        />
        <div className="mt-4">
          <ReCAPTCHA
            sitekey="6Lc9qRscAAAAAMxA1tFC5KQaj02N30Z5l9_3eedi"
            onChange={captchaChange}
          />
        </div>
        {!captcha && isSubmitted && (
          <p className="help is-danger mb-3"> {t("errmsg.human")}</p>
        )}

        <div className="field is-grouped">
          <div className="control mt-5">
            <button
              onClick={() => {
                setSubmit(true);
              }}
              className={`button is-link login-btn ${
                passwordError ? "mt-4" : ""
              } ${
                isFetching || fetchingReserID || checkoutFetch
                  ? "is-loading"
                  : ""
              }`}
              type="submit"
            >
              {t("stayDetails.reg")}
            </button>
          </div>
        </div>
      </form>
      <SocialMediaWrapper onSocialMediaBtnClick={onSocialMediaLogin} />
      <div className="guest-link p-0">
        <button className="continue-as-guest" onClick={navigateToGuest}>
          {" "}
          {t("login.continue")}
        </button>
      </div>
      <div className="register-link">
        {t("register.alreadyAccnt")}{" "}
        <span>
          <Link
            to={{
              pathname: `/login`,
            }}
          >
            {" "}
            {t("register.gotologin")}
          </Link>
        </span>
      </div>
    </AuthLayout>
  );
};

export default Register;
