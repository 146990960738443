import React, { FunctionComponent } from 'react';
import './pieChart.scss';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import SearchSkeleton from 'features/search-property/components/SearchSkeleton/SearchSkeleton';

const PieChartCard: FunctionComponent<any> = ({ loading, data }) => {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#b27e7d','#af82f1','#ed4f75','#4f1362','#579005'];
    const renderLabel = (entry: { name: any;value: any }) => {
        return `${entry.name}: AED ${entry.value} `;
    }
    return (
        <div className="piechart-card">
            <h4>Maintenance Costs</h4>
            {loading ? <SearchSkeleton /> :
                <div className="pie-card">
                    <ResponsiveContainer width="100%" height="95%" aspect={2}>
                        <PieChart >
                            <Pie
                                dataKey="pieData"
                                isAnimationActive={false}
                                data={data}
                                fill="#8884d8"
                                paddingAngle={2}
                                label={renderLabel}
                            >
                                {data.map((entry: any, index: number) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            }

        </div>
    )
}

export default PieChartCard;