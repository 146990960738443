import React from "react";

import ProfileLayout from "./ProfileLayout";

import "./Profile.scss";

const Profile: React.FC = () => {
  return <ProfileLayout />;
};

export default Profile;
