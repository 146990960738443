import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";

const encKey:string = "St@yc@e";

interface UtilProps {
  cname: string;
  cvalue: string;
}

export const setLocalStore = ({ cname, cvalue }: UtilProps) => {
  if (cvalue) {
    const encryptedData = AES.encrypt(JSON.stringify(cvalue), encKey);
    localStorage.setItem(cname, encryptedData.toString());
  }
};

export const getLocalStore = (cname: string) => {
  if (cname === "i18nextLng") return localStorage.getItem(cname);
  let decryptedData = null;
  const cnameval = localStorage.getItem(cname);
  const bytes = cnameval && AES.decrypt(cnameval, encKey);
  decryptedData = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export const emailEncode = (email: string) => {
  if (email) {
    return encodeURIComponent(email);
  }
}

export const emailDecode = (email: string) => {
  if (email) {
    return decodeURIComponent(email);
  }
}
