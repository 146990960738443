export const initialOccupancyChartData = [
    ["Type", "", { role: "style" },{ role: 'annotation',}],
    ["Total Reservations",  0, "color: #F05C24", "0 Reservations"],
    ["Longer Stay Reservations",  0, "color: #42B9E6","0 Reservations"],
    ["Shorter Stay Reservations", 0, "color: #57B95A","0 Reservations"],
    ["Total Nights Occupied", 0, "color: #EE4A85","0 Nights"],
    ["Revenue Earned",0,"color: #3B62AC","AED 0"]
  ]

  export const initialViewData = [
    {
        name: "0 Room Category Views",
        uv:  20,
        fill: '#3B62AC',
    },
    {
        name: `0 Website Visitors`,
        uv:  20,
        fill: '#71CEF7',
    },
]
