import React, { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import NotFound from "components/NotFound/NotFound";
import ErroroHandler from "components/ErrorHandler/ErrorHandler";
import ROUTES from "./routes";
import Header from "components/Header/Header";
import { getLocalStore } from "utils/encryption";
import { AppStoreContent, delay } from "features/home/Home";
import Modal from "components/Modal/Modal";

const Router: React.FC<{}> = () => {
  const loginUrl = "/login";
  const home = "/";
  let Footer = lazy(() => import("components/Footer/Footer"));

  const checkAuthenticated = () => {
    return Boolean(getLocalStore(`${window.location.host}AUTH_DETAILS`));
  };

  const isOwnerRouteAccessible = () => {
    const roles = localStorage.getItem(`${window.location.host}roles`);
    const email_verified =
      localStorage.getItem(`${window.location.host}email_verified`) === "true"
        ? true
        : false;
    return roles?.includes("2") && email_verified;
  };

  const [showPopup, togglePopup] = useState(false);
  const togglePhoneVerifyPopup = () => {
    togglePopup(!showPopup);
    sessionStorage.setItem("closeAppPopup", "true");
  };

  const SessionPopup = () => (
    <div className="app-store-wrapper">
      <Modal
        isShown={showPopup}
        hide={togglePhoneVerifyPopup}
        modalContent={<AppStoreContent onClick={togglePhoneVerifyPopup} />}
        title="Download the App."
        hideCloseBtn={true}
      />
    </div>
  );

  // Create fake delay of 1s for popup to appear
  useEffect(() => {
    (async function () {
      await delay(2000);
      togglePopup(
        sessionStorage.getItem("closeAppPopup") === "true"
          ? false
          : navigator.userAgent.toLowerCase().includes("iphone")
          ? false
          : true
      );
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      {ROUTES.map(
        ({
          auth,
          component,
          path,
          imports,
          noHeader,
          noFooter,
          noPopup = false,
          ...rest
        }) => {
          let Child = lazy(() => imports);
          return (
            <Route
              key={path}
              exact
              path={path}
              {...rest}
              render={(props) => {
                // Reset password route
                if (props.match.path.includes("/reset-password")) {
                  // Redirect if no search parameter (or) Redirect if no email or id is found in parameter
                  if (
                    !props.location.search.trim() ||
                    (props.location.search &&
                      (props.location.search.includes("email") === false ||
                        props.location.search.includes("id") === false))
                  ) {
                    return <Redirect to={home as string} />;
                  }
                }
                // Registration route
                if (props.match.path.includes("/register")) {
                  const { pathname, search } = props.location;
                  // Redirect if path is not exact match for empty parameter (or) Redirect if no referral code is found in parameter
                  if (
                    (!search && pathname !== "/register") ||
                    (search &&
                      props.location.search.includes("referral_code") === false)
                  ) {
                    return <Redirect to={home as string} />;
                  }
                }
                // Web check-in route
                if (props.match.path.includes("/check-in")) {
                  const { search } = props.location;
                  // Redirect if no search parameter (or) Redirect if no id is found in parameter
                  if (
                    !search.trim() ||
                    (search && search.includes("id") === false)
                  ) {
                    return <Redirect to={home as string} />;
                  }
                }
                if (auth && !checkAuthenticated()) {
                  return <Redirect to={loginUrl as string} />;
                } else if (
                  (auth && checkAuthenticated()) ||
                  (!auth && !checkAuthenticated()) ||
                  (!auth && checkAuthenticated())
                ) {
                  if (
                    checkAuthenticated() &&
                    (props.location.pathname === "/login" ||
                      props.location.pathname === "/register")
                  ) {
                    return <Redirect to={home as string} />;
                  } else if (
                    !checkAuthenticated() &&
                    (props.location.pathname === "/login" ||
                      props.location.pathname === "/register")
                  ) {
                    return (
                      <Suspense fallback={<div></div>}>
                        <ErroroHandler>
                          <Child {...props}></Child>
                        </ErroroHandler>
                        {!noPopup &&
                          showPopup &&
                          !props.match.path.includes("/check-in") && (
                            <SessionPopup />
                          )}
                      </Suspense>
                    );
                  } else {
                    if (path.includes("owner") && !isOwnerRouteAccessible()) {
                      return <Redirect to={home as string} />;
                    } else {
                      return (
                        <React.Fragment>
                          {!noHeader && <Header></Header>}

                          <Suspense fallback={<div></div>}>
                            <ErroroHandler>
                              <Child {...props}></Child>
                            </ErroroHandler>
                            {!noPopup &&
                              showPopup &&
                              !props.match.path.includes("/check-in") && (
                                <SessionPopup />
                              )}
                          </Suspense>
                          {!noFooter && (
                            <Suspense fallback={<div></div>}>
                              <ErroroHandler>
                                <Footer></Footer>
                              </ErroroHandler>
                            </Suspense>
                          )}
                        </React.Fragment>
                      );
                    }
                  }
                }
              }}
            />
          );
        }
      )}
      <Route
        render={() => (
          <ErroroHandler>
            <>
              <Header />
              <NotFound />
              <Footer />
            </>
          </ErroroHandler>
        )}
      />
    </Switch>
  );
};

export default Router;
