import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { download } from "services/api.service";
import { API } from "api/api";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { BookingProps } from "./booking.interface";
import fileDownload from 'js-file-download';

interface BookingsState {
  fetchedBooking: boolean;
  bookings: BookingProps;
  fetchingBooking: boolean;
  isCancelled: boolean;
  cancelMessage: string;
  invoiceError: boolean;
}

export const initialState: BookingsState = {
  fetchedBooking: false,
  bookings: {
    past_bookings: [],
    upcoming_bookings: []
  },
  fetchingBooking: false,
  isCancelled: false,
  cancelMessage: '',
  invoiceError: false
};

export const getBookings: any = createAsyncThunk(
  "mybookings",
  async (id: string) => {
    const rsp: any = await API.get(endPoints.BOOKING);
    return {
      data: rsp.data.data,
    };
  }
);
export const downloadInvoice: any = createAsyncThunk(
  "downloadinvoices",
  async (id: string) => {
    const rsp: any = await download("/api/payment/download_invoice/?reservation_id=" + id);
    fileDownload(rsp.data, "Booking Invoice - " + id + ".pdf");
    return {
      data: rsp.data
    }
  }
);
export const manageCancelBooking: any = createAsyncThunk(
  "manageCancelBooking",
  async (params: any) => {
    const rsp: any = await API.get(endPoints.CANCELBOOKING, {
      params
    });
    return {
      data: rsp.data,
    };
  }
);
const bookingsSlice = createSlice({
  name: "manageMyBookings",
  initialState,
  reducers: {
    clearInvoiceError: (state) => {
      state.invoiceError = false;
    }
  },
  extraReducers: {
    [getBookings.rejected]: (state, { payload }) => {
      state.fetchingBooking = false;
      state.fetchedBooking = true;
      state.bookings = {
        past_bookings: [],
        upcoming_bookings: []
      };
    },
    [getBookings.pending]: (state) => {
      state.fetchingBooking = true;
    },
    [getBookings.fulfilled]: (state, { payload }) => {
      state.fetchingBooking = false;
      state.fetchedBooking = true;
      state.bookings = {
        past_bookings: payload.data.past_bookings,
        upcoming_bookings: payload.data.upcoming_bookings,
      };
    },

    [manageCancelBooking.rejected]: (state, { payload }) => {
      state.isCancelled = false;
      state.cancelMessage = '';
    },
    [manageCancelBooking.pending]: (state) => {
      state.isCancelled = true;
      state.cancelMessage = '';
    },
    [manageCancelBooking.fulfilled]: (state, { payload }) => {
      state.isCancelled = false;
      state.cancelMessage = '';
    },
    [downloadInvoice.rejected]: (state, { payload }) => {
      state.invoiceError = true;
    }
  },
});

export const { clearInvoiceError } = bookingsSlice.actions;
export const bookingProfileSelector = (state: RootState) =>
  state.profilebooking;
const bookingProfileReducer = bookingsSlice.reducer;
export default bookingProfileReducer;
