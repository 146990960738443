import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTPMSG } from "config";
import { RootState } from "store/rootReducer";
import { CouponsData } from "./coupons.interface";
import { CouponsService } from "./coupons.service";

const couponsInitialData: CouponsData[] = [];
const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  coupons: couponsInitialData,
  fetchComplete: false,
  selectedCouponId: ""
};

export const fetchCoupons: any = createAsyncThunk(
  "coupons",
  async (payload, { rejectWithValue }) => {
    try {
      const response: any = await CouponsService.fetchCoupons(payload);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const couponsSlice = createSlice({
  name: "allCoupons",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      state.coupons = couponsInitialData;
      return state;
    },
    setSelectedCoupon: (state, {payload}) => {
      state.selectedCouponId = payload;
      return state;
    },
    clearSelectedCoupon: (state) => {
      state.selectedCouponId = "";
      return state;
    },
    setActiveCoupons: (state, { payload }) => {
      state.coupons = payload;
      return state;
    }
  },
  extraReducers: {
    [fetchCoupons.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = HTTPMSG[payload?.message || 'ERROR'];
    },
    [fetchCoupons.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchCoupons.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.coupons = payload.data.coupons;
      state.fetchComplete = true;
    },
  },
});

export const { clearState, setSelectedCoupon, clearSelectedCoupon, setActiveCoupons } = couponsSlice.actions;
export const couponsSelector = (state: RootState) => state.couponsReducer;
const couponsReducer = couponsSlice.reducer;
export default couponsReducer;
