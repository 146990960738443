import { getLocalStore } from "../utils/encryption";
import { AxiosError, AxiosRequestConfig } from "axios";

// Add a request interceptor
export function onRequest(config: AxiosRequestConfig) {
  const auth = getLocalStore(`${window.location.host}AUTH_DETAILS`) || getLocalStore(`${window.location.host}TEMPAUTH_DETAILS`);
  var TokenAuth = "Token " + auth;

  if (auth) {
    config.headers = {
      ...config.headers,
      Authorization: TokenAuth,
    };
  }

  config.headers = {
    ...config.headers, 
    'Accept-Language': getLocalStore('i18nextLng')
  }

  return config;
}

export function onRequestError(error: AxiosError) {
  // Do something with request error
  return Promise.reject(error);
}
