import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { post} from "services/api.service";

const intialState: any = {
    loading: false,
    error: false,
    status: 'pending',
    data: null
}

export const getDateDependData: any = createAsyncThunk(
    "getDateDependData", async (payload: Request, { rejectWithValue }) => {
        try {
            const response: any = await post(payload, endPoints.DASHBOARD.DATEDEPEND);
            return response.data;
        } catch (e:any) {
            return rejectWithValue(e.response.data);
        }
    })


const getDateDataSlice = createSlice({
    name: 'getDateDependData',
    initialState: intialState,
    reducers: {
        clearState: (state) => {
            return intialState;
        },
    },
    extraReducers: {
        [getDateDependData.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
        },
        [getDateDependData.pending]: (state) => {
            state.loading = true;
        },
        [getDateDependData.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.status = "fulfilled";
        }
    }
});

export const { clearState } = getDateDataSlice.actions;
export const getDateDataValue = (state: RootState) => state.getDataDate;
const getDateDataReducer = getDateDataSlice.reducer;
export default getDateDataReducer;
