import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { get} from "services/api.service";

const intialState: any = {
    loading: false,
    error: false,
    status: 'pending',
    data: null
}

export const getUnits: any = createAsyncThunk(
    "getUnits", async (payload: Request, { rejectWithValue }) => {
        try {
            const response: any = await get(endPoints.DASHBOARD.GETUNITS);
            return response.data;
        } catch (e:any) {
            return rejectWithValue(e.response.data);
        }
    })


const getUnitsSlice = createSlice({
    name: 'getUnits',
    initialState: intialState,
    reducers: {
        clearState: (state) => {
            return intialState;
        },
    },
    extraReducers: {
        [getUnits.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
        },
        [getUnits.pending]: (state) => {
            state.loading = true;
        },
        [getUnits.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.status = "fulfilled";
        }
    }
});

export const { clearState } = getUnitsSlice.actions;
export const getUnitsValue = (state: RootState) => state.getUnits;
const getUnitsReducer = getUnitsSlice.reducer;
export default getUnitsReducer;
