import { combineReducers } from "@reduxjs/toolkit";

import contactUsReducer from "features/contact-us/Contact.slice";
import forgotPasswordReducer from "features/forgot-password/forgot-password.slice";
import resetPasswordReducer from "features/reset-password/reset-password.slice";
import authReducer from "store/slices/auth.slice";
import testimonialReducer from "features/home/components/Testimonials/testimonails.slice";
import attractionReducer from "features/home/components/Attractions/attractions.slice";
import toastReducer from "components/Toast/Toast.slice";
import locationsReducer from "features/home/components/AreasBanner/locations.slice";
import offerReducer from "features/home/components/Offers/offers.slice";
import homeReducer from "features/home/home.slice";
import verifyNowReducer from "features/profile/slices/verifyNow.slice";
import changePasswordReducer from "features/profile/slices/changePassword.slice";
import profileReducer from "features/profile/slices/profile.slice";
import locationsPageReducer from "features/locations/locations.slice";
import searchReducer from "features/search-property/searchproperty.slice";
import guestReducer from "features/search-property/components/AddGuest/addguest.slice";
import verifyEmailReducer from "features/verify-email/verifyEmail.slice";
import offerListReducer from "features/offers/offers-list.slice";
import couponsReducer from "features/profile/components/coupons/coupons.slice";
import searchResultReducer from "features/search-result/searchresult.slice";
import stayDetailReducer from "features/stay-details/stay-details.slice";
import fetchPropertiesReducer from "features/search-result/slices/fetchAllProperties.slice";
import wishListReducer from "features/search-result/components/ResultCard/components/WishList/wishlist.slice";
import guestCheckoutReducer from "features/guest-checkout/guest-checkout.slice";
import verifyOtpReducer from "features/guest-checkout/verify-phone.slice";
import resendOtpReducer from "features/guest-checkout/resend-otp.slice";
import wishListProfileReducer from "features/profile/components/wishlist/wishlists.slice";
import bookingProfileReducer from "features/profile/components/booking/booking.slice";
import checkoutReducer from "features/payment/checkout.slice";
import paymentReducer from "features/payment/components/payment.slice";
import listPropertyReducer from "features/list-property/list-property.slice";
import getUnitsReducer from "features/owner/dashboard/slices/getUnits.slice";
import getDateDataReducer from "features/owner/dashboard/slices/getDateData.slice";
import getDataReducer from "features/owner/dashboard/slices/getData.slice";
import multiSelectReducer from "components/multi-select/multi-select.slice";
import ownerReducer from "features/owner/owner.slice";
import getAnalyticsReducer from "features/owner/dashboard/slices/getAnalytics.slice";
import singleSelectReducer from "components/UnitsDropdown/units-dropdown.slice";
import reservationReducer from "features/owner/reservation/reservation.slice";
import aboutUsReducer from "features/about-us/components/aboutUs.slice";
import referralReducer from "features/profile/components/refer-and-earn/refer-and-earn.slice";
import cancellationReducer from "features/cancellation/cancellation.slice";
import paymentRefundReducer from "features/payment-refund/payment-refund.slice";
import ourServicesReducer from "features/our-services/our-services.slice";
import guestWebCheckInReducer from "features/check-in/check-in.slice";
import getMaintenanceDataReducer from "features/owner/dashboard/slices/getMaintenaceData.slice";

const rootReducer = combineReducers({
  auth: authReducer,
  forgotPasswordReducer: forgotPasswordReducer,
  reset: resetPasswordReducer,
  contactUsReducer: contactUsReducer,
  toast: toastReducer,
  testimonial: testimonialReducer,
  attraction: attractionReducer,
  locations: locationsReducer,
  featuredOffers: offerReducer,
  home: homeReducer,
  profile: profileReducer,
  changePassword: changePasswordReducer,
  verificationNow: verifyNowReducer,
  locationsReducer: locationsPageReducer,
  verifyEmail: verifyEmailReducer,
  searchproperty: searchReducer,
  addguest: guestReducer,
  offers: offerListReducer,
  couponsReducer: couponsReducer,
  searchresults: searchResultReducer,
  stayDetail: stayDetailReducer,
  fetchAllProperties: fetchPropertiesReducer,
  wishlist: wishListReducer,
  guestCheckout: guestCheckoutReducer,
  verifyOtp: verifyOtpReducer,
  resendOtp: resendOtpReducer,
  profilewishlist: wishListProfileReducer,
  profilebooking: bookingProfileReducer,
  checkout: checkoutReducer,
  payment: paymentReducer,
  listProperty: listPropertyReducer,
  getUnits: getUnitsReducer,
  getDataDate: getDateDataReducer,
  getUnitData: getDataReducer,
  multiSelect: multiSelectReducer,
  analytics: getAnalyticsReducer,
  owner: ownerReducer,
  singleSelect: singleSelectReducer,
  reservation: reservationReducer,
  aboutUs: aboutUsReducer,
  referral: referralReducer,
  cancellation: cancellationReducer,
  paymentRefund: paymentRefundReducer,
  ourServices: ourServicesReducer,
  guestWebCheckIn: guestWebCheckInReducer,
  maintenanceData: getMaintenanceDataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
