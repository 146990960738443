import React from "react";
import "./Button.scss";
export interface ButtonProps {
  buttonText: string;
  isPrimary: boolean;
  classes? :string;
  onClick?: (e?: any) => any;
}
const Button: React.FC<ButtonProps> = ({ buttonText, isPrimary, classes, onClick }) => {
  return (
    <button className={`staycae-btn button ${isPrimary ? 'is-primary' :'is-secondary'} ${classes}`} onClick={onClick}>{buttonText}</button>
  );
};

export default Button;
