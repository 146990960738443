import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import English from 'locale/en';
import Arabic from 'locale/ar';

i18n
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    keySeparator: '.',
    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      en: {
        translations: English
      },
      ar: {
        translations: Arabic
      }
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations'
  });

export default i18n;

