import "./Skeleton.scss";

import React from "react";

interface SkeletonProps {
  classes?: string;
  img?: boolean;
  onClick?: () => void;
}
const SearchSkeleton: React.FC<SkeletonProps> = ({ classes, img }) => {
  return (
    <li className={`skeleton-item ${classes}`}>
      <div>{img && <div className="skeleton-img" />}</div>
      <div className="skeleton-info">
        <p className="skeleton-name" />
        <p className="skeleton-email" />
      </div>
    </li>
  );
};

export default SearchSkeleton;
