import React from "react";
import { useTranslation } from "react-i18next";
import "./NotFound.scss";
import notfound from "assets/images/not-found.png";
const NotFound: React.FC = () => {
  const { t } = useTranslation();
  return(
    <div className="container-main align-center" id="not-found">
     <img src={notfound} alt="404" />
     <div className="">
       <h2>404</h2>
       <h6>{t('notFound.title')} </h6>
       <p> {t('notFound.paraText')} 
       
       </p>
       <a href="/">
        <button className="button is-primary" >
               {t('notFound.btn')} 
          </button>
      </a>
     </div>
    </div>
  );
  
};

export default NotFound;
