import React from "react";

import OffersComponent from "./components/OffersComponent/OffersComponent";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

import "./styles/layouts.scss";

const Offers: React.FC = () => {
  
  return (
    <div style={{marginBottom: "133px"}}>
      <OffersComponent />
      <ScrollToTop refID="" />
    </div>
  );
};

export default Offers;
