import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";

export const initialState = {
  isDataFetched: false,
  paymentRefund: {
    maintitle: "",
    maintitledesc: "",
    subtitle: "",
    fields: []
  },
  meta: {
    content: "",
    keyWord: "",
    name: ""
  }
};

export const paymentRefundData: any = createAsyncThunk(
  "paymentRefund",
  async () => {
    const rsp: any = await get((endPoints.STATIC.PAYMENT_REFUND));
    return {
      data: rsp.data.data
    };
  }
);
const paymentRefundSlice = createSlice({
  name: "paymentRefund",
  initialState,
  reducers: {
  },
  extraReducers: {
    [paymentRefundData.pending]: (state, action) => {
      state.isDataFetched = false;
    },
    [paymentRefundData.fulfilled]: (state, action) => {
      state.isDataFetched = true;
      const { data } = action.payload;
      state.paymentRefund = data.data;
      state.meta = data.meta;
    },
    [paymentRefundData.rejected]: (state, action) => {
      state.isDataFetched = true;
      state.paymentRefund = initialState.paymentRefund;
      state.meta = initialState.meta;
    },
  },
});

export const paymentRefundSelector = (state: RootState) => state.paymentRefund;
const paymentRefundReducer = paymentRefundSlice.reducer;
export default paymentRefundReducer;
