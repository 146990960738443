import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { registerRequest } from "features/register/register.interface";
import { get, post } from "services/api.service";
import { endPoints } from "api/endPoints";
import { AuthState } from "interfaces/auth.interface";
import { RootState } from "store/rootReducer";
import { HTTPMSG } from "config/index";
import { setLocalStore } from "utils/encryption";
import { clearBookingStates } from "utils/utility";

interface requestType {
  token: string;
  type: string;
}

export const initialState: AuthState = {
  users: [],
  loading: false,
  error: false,
  isLogin: false,
  userData: null,
  status: "pending",
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  successMessage: "",
};
//for login
export const loginUser: any = createAsyncThunk(
  "login",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response: any = await post(payload, endPoints.USERS.LOGIN);
      return response.data;
    } catch (e: any) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const logOut: any = createAsyncThunk(
  "logOut",
  async (payload: any, { rejectWithValue }) => {
    localStorage.removeItem(`${window.location.host}AUTH_DETAILS`);
    localStorage.removeItem(`${window.location.host}TEMPAUTH_DETAILS`);
    localStorage.removeItem(`${window.location.host}userId`);
    localStorage.removeItem(`${window.location.host}email_verified`);
    localStorage.removeItem(`${window.location.host}roles`);
    clearBookingStates();
    window.location.reload();
    const response: any = await get(endPoints.USERS.LOGOUT);
    return response.data;
  }
);

//for socialMedia
export const socialMediaLogin: any = createAsyncThunk(
  "socialMediaLogin",
  async (payload: requestType, { rejectWithValue }) => {
    const { type, token } = payload;
    let endPoint =
      type === "google"
        ? endPoints.USERS.LOGINVIAGOOGLE
        : endPoints.USERS.LOGINVIAFACEBOOK;
    try {
      const response: any = await post({ token }, endPoint);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

//for signup
export const registerUser: any = createAsyncThunk(
  "register",
  async (payload: registerRequest, { rejectWithValue }) => {
    try {
      const response: any = await post(payload, endPoints.USERS.SIGNUP);
      return response.data;
    } catch (e: any) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    //login
    [loginUser.rejected]: (state, { payload }) => {
      state.errorMessage = HTTPMSG[payload.message];
      state.isFetching = false;
      state.isError = true;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.status = "fulfilled";
      state.userData = payload.data;
      state.isLogin = true;
      state.isSuccess = true;
      setLocalStore({
        cname: `${window.location.host}AUTH_DETAILS`,
        cvalue: payload.data.token,
      });
      localStorage.setItem(
        `${window.location.host}userId`,
        payload.data.user_id
      );
      localStorage.setItem(
        `${window.location.host}roles`,
        payload.data.role.join()
      );
      localStorage.setItem(
        `${window.location.host}email_verified`,
        payload.data.is_email_verified
      );
    },
    //socialmedia
    [socialMediaLogin.rejected]: (state, { payload }) => {
      state.errorMessage = HTTPMSG[payload.message];
      state.isFetching = false;
      state.isError = true;
    },
    [socialMediaLogin.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [socialMediaLogin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.status = "fulfilled";
      state.userData = payload.data;
      state.isLogin = true;
      state.isSuccess = true;
      setLocalStore({
        cname: `${window.location.host}AUTH_DETAILS`,
        cvalue: payload.data.token,
      });
      localStorage.setItem(
        `${window.location.host}userId`,
        payload.data.user_id
      );
      localStorage.setItem(
        `${window.location.host}roles`,
        payload.data.role.join()
      );
    },
    //register
    [registerUser.rejected]: (state, { payload }) => {
      state.errorMessage = HTTPMSG[payload.message];
      state.isFetching = false;
      state.isError = true;
    },
    [registerUser.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.status = "fulfilled";
      state.userData = payload.data;
      state.isLogin = true;
      state.isSuccess = true;
      state.successMessage = payload.message;
      setLocalStore({
        cname: `${window.location.host}AUTH_DETAILS`,
        cvalue: payload.data.token,
      });
      localStorage.setItem(`${window.location.host}userId`, payload.data.id);
    },
    [logOut.fulfilled]: (state, { payload }) => {},
  },
});

export const { clearState } = authSlice.actions;
export const userSelector = (state: RootState) => state.auth;
const authReducer = authSlice.reducer;
export default authReducer;
