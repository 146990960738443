import { stayDetailSelector } from "features/stay-details/stay-details.slice";
import React from "react";
import { useAppSelector } from "store/store";
import LoginComponent from "./components/LoginComponent/LoginComponent";
interface LoginPageProps {
  history: {
    push: (path: string) => any;
  };
  location: {
    state : {
      prevPath: string;
      from: string;
    }
  }
}

const Login: React.FC<LoginPageProps> = (props) => {
  const {state} = props.location;
  const onSubmit = (payload: any) => {};
  const { currentRoom } = useAppSelector(stayDetailSelector);
  
  if (state?.prevPath?.includes('/stay-details') && currentRoom.trim()) {
    return <LoginComponent onFormSubmit={onSubmit} currentPath= {state.prevPath} />;
  } else if (state?.from?.includes('/check-in')) {
    return <LoginComponent onFormSubmit={onSubmit} currentPath= {state.from} />;
  }

  return <LoginComponent onFormSubmit={onSubmit} currentPath= {state?.prevPath || '/'} />;
};

export default Login;