import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";
interface GuestState {
  adults: number;
  children: number;
  infants: boolean;
}
export const initialState: GuestState = {
  adults: 2,
  children: 0,
  infants: false,
};

const guestSlice = createSlice({
  name: "guest",
  initialState,
  reducers: {
    increment: (state, { payload }) => {
      switch (payload) {
        case "adults":
          state.adults = state.adults + 1;
          break;
        case "children":
          state.children = state.children + 1;
          break;
      }
    },
    decrement: (state, { payload }) => {
      switch (payload) {
        case "adults":
          state.adults = state.adults - 1;
          break;
        case "children":
          state.children = state.children - 1;
          break;
      }
    },
    toggleInfant: (state) => {
      state.infants = !state.infants;
    },
    updateGuestNumber: (state, { payload }) => {
      switch (payload.type) {
        case "adults":
          state.adults = payload.count;
          break;
        case "children":
          state.children = payload.count;
          break;
      }
    },
    resetGuestDropdown: (state) => {
      ({
        adults: state.adults,
        children: state.children,
        infants: state.infants,
      } = initialState);
    },
  },
});

export const { increment, decrement, toggleInfant, updateGuestNumber, resetGuestDropdown } =
  guestSlice.actions;
export const guestSelector = (state: RootState) => state.addguest;
const guestReducer = guestSlice.reducer;
export default guestReducer;
