import "./Attractions.scss";
import AttractionsCarousel from "./AttractionsCarousel";
import { useAppDispatch, useAppSelector } from "store/store";
import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { attractionsFeatured, attractionsSelector } from "./attractions.slice";
import FeaturedProperties from "./FeaturedProperties";
import { attractions2Carousel } from "./attractions-mock";
import { Context } from "components/app/Wrapper";

const Attractions: React.FC = () => {
  const context: any = useContext(Context);
  const dispatch = useAppDispatch();
  const { attractions, isAttractionsFetched } =
  useAppSelector(attractionsSelector);
  useEffect(() => {
    dispatch(attractionsFeatured("ATTRACTIONS"));
  }, [dispatch, context.locale]);
  const { t } = useTranslation();
  return (
    <div className="section home-attractions is-fluid" id="home-attractions">
      <h1 className="has-text-centered all-title">
        {t('home.attractions.title')}
      </h1>
      {isAttractionsFetched && (
        <AttractionsCarousel
          attractions={attractions}
          title={t('home.attractions.subtitle')}
        ></AttractionsCarousel>
      )}
      <FeaturedProperties
        attractions={attractions2Carousel}
        title={t('home.attractions.adventure')}
      ></FeaturedProperties>
    </div>
  );
};

export default Attractions;
