import React, {
  useEffect,
  useState,
  useContext
} from "react";
import { useTranslation } from "react-i18next";
import "./Header.scss";
import { useLocation } from "react-router";
import avatar from "assets/images/profile.png";
import favicon from "assets/icons/favicon.png";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import logoutIcon from "assets/images/logout.png";
import profileIcon from "assets/images/profileIcon.png";
import { getLocalStore } from "utils/encryption";
import { clearState, logOut } from "store/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "store/store";
import { DirectionCheck } from "../../utils/classnameSwitch";
import logo from "../../assets/images/logo.png";

import {
  fetchUserDetails,
  profile,
  isOwnerDashboard,
} from "features/profile/slices/profile.slice";
import ResponsiveHeader from "./responsive-header/ResponsiveHeader";
import HeaderOptions from "./header-options/HeaderOptions";
import Loader from "react-loader-spinner";
import useClickOutside from "hooks/useClickOutside";
import SwitchToOwner from "./switchToOwner/SwitchToOwner";
import { Context } from "components/app/Wrapper";
import OwnerHeaderOption from "./owner-header-option/OwnerHeaderOption";
import { searchSelector, activeFilters } from "features/search-property/searchproperty.slice";
import { updateDates } from "features/stay-details/stay-details.slice";

export const images = require.context("assets/icons", false, /\.png$/);

const Header: React.FC = () => {
  const context: any = useContext(Context);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const [profileDropDownActive, setProfileDropDownStatus] = useState(false);
  const { loading, data, isOwner, inOwnerDashboard } = useAppSelector(profile);
  const { isLogin } = {
    isLogin: Boolean(getLocalStore(`${window.location.host}AUTH_DETAILS`)),
  };
  const node = React.useRef<HTMLDivElement>(null);
  const [isNotOwnerRoute, setOwnerRoute] = useState<boolean>(false);
  const onLogout = () => {
    dispatch(clearState());
    dispatch(logOut());
  };
  const { locations, checkin, guests, checkout } = useAppSelector(searchSelector);
  useEffect(() => {
    if (
      location.pathname.includes("owner") ||
      (inOwnerDashboard && location.pathname.includes("profile"))
    ) {
      dispatch(isOwnerDashboard(true));
      setOwnerRoute(true);
    } else {
      dispatch(isOwnerDashboard(false));
      setOwnerRoute(false);
    }
    // eslint-disable-next-line
  }, [location.pathname, context.locale]);

  useEffect(() => {
    isLogin && dispatch(fetchUserDetails());
  }, [dispatch, isLogin, context.locale]);

  const navigateToProfile = () => {
    history.push("/profile");
  };

  const [showResponsive, setResponsive] = useState(false);

  useClickOutside(node, () => {
    setProfileDropDownStatus(false);
  });

  const setContextLanguage = () => {
    if(window.location.pathname.includes('search-results')) {
      const params: any = {};
      params['locations'] = locations.currentLocation ?? null;
      params['latitude'] = locations.lat ?? null;
      params['longitude'] = locations.long ?? null;
      params['from'] = checkin.formattedD ?? null;
      params['to'] = checkout.formattedD ?? null;
      params['guests'] = guests.currentGuests.adult > 0 ? guests.currentGuests.adult : null;
      params['page'] = 1;
      params['location'] = locations.id ?? null;
      dispatch(activeFilters(params));
    }
    if(window.location.pathname.includes('stay-details'))
    dispatch(updateDates(false))
    let changeLang = context.locale === 'ar' ? 'en' : 'ar';
    context.selectLanguage(changeLang)
  }
  useEffect(() => {
    if(window.location.pathname.includes('owner/reservation')) {
      document.body.classList.add("reservation");
    }
    else{
      document.body.classList.remove("reservation");
    }
    // eslint-disable-next-line
    }, [window.location.pathname])
  return (
    <nav dir={DirectionCheck()}
      className="navbar container is-fluid staycae-nav pt-4"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img
            className="brand-logo"
            alt="Brand Logo"
            src={logo}
            width="112"
            height="28"
          />
        </a>

        <span
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          onClick={(_) => setResponsive(true)}
        >
          <img
            alt="brand-logo"
            src={favicon}
            style={{ width: "35px", height: "35px" }}
          ></img>
        </span>
      </div>

      {showResponsive && (
        <ResponsiveHeader
          onClick={(status) => setResponsive(status)}
          onLogout={onLogout}
        />
      )}

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-flex">
          {
            isNotOwnerRoute ? <OwnerHeaderOption onClick={(status) => setResponsive(status)} /> : <HeaderOptions />
          }
          {!isNotOwnerRoute ?
            <div className="navbar-item has-dropdown lang-menu" onClick={() => setContextLanguage()}>
              <a className="navbar-link" href="#!">
                <img src={images(`./${context.locale === 'en' ? 'ar' : 'en'}.png`).default} alt="locales"></img>
                {context.locale === 'en' ? 'عربى' : 'en'}
              </a>
            </div>
            : null
          }
          {isOwner && <SwitchToOwner isNotOwner={isNotOwnerRoute} />}
        </div>
        <div className="">
          <div className="navbar-item">
            {isLogin ? (
              <div
                className={`dropdown ${profileDropDownActive && "is-active"}`}
              >
                <div className="dropdown-trigger">
                  <button
                    className="img-btn"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu3"
                    onClick={() =>
                      setProfileDropDownStatus(!profileDropDownActive)
                    }
                  >
                    {loading ? (
                      <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={48}
                        width={48}
                      />
                    ) : (
                      <img
                        className="user-avatar"
                        src={data?.profile_picture || avatar}
                        alt="profile"
                        onError={(e: any) => e.target.src = avatar}
                      />
                    )}
                    <i className="fas fa-caret-down"></i>
                  </button>
                </div>
                <div
                  className="dropdown-menu"
                  id="dropdown-menu3"
                  role="menu"
                  style={{ right: 0, left: "unset" }}
                  ref={node as any}
                >
                  <div className="dropdown-content">
                    <button
                      className="dropdown-item logout"
                      onClick={navigateToProfile}
                    >
                      <div className="dropdown-link-item">
                        <img
                          className="d-icon"
                          src={profileIcon}
                          alt="profileImg"
                        />
                        <span> {t("common.profile")}</span>
                      </div>
                    </button>
                    <button className="dropdown-item logout" onClick={onLogout}>
                      <div className="dropdown-link-item">
                        <img className="d-icon" src={logoutIcon} alt="logout" />
                        <span>{t("common.logout")}</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="buttons">
                <Link
                  to={{
                    pathname: `/login`,
                    state: {
                      prevPath: location.pathname,
                    },
                  }}
                  className="button com-border-r fw-500 login-header"
                >
                  {t("landing.register")}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
