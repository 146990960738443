import React from "react";
import { useState } from "react";
import i18n from "locale/translation";
import './TextArea.scss';

interface TextAreaProps {
  label: string;
  name: string;
  placeholder: string;
  errors?: any;
  register: any;
  rules: any;
  customError?: string; // NOTE: Condition to display custom error message should be handled in parent
}

const displayErrorMessage = (error: string, customError:string, label: string, rules: any) => {
  if (customError.trim()) return <p className="help is-danger">{customError}</p>;
  else {
    switch(error) {
      case "required": 
        return <p className="help is-danger">{label || 'Message'} {i18n.t('errmsg.require')}</p>;
      case "maxLength": 
        return <p className="help is-danger">
                {label} {i18n.t('errmsg.maximum')} {rules.maxLength} {i18n.t('errmsg.char')}
              </p>;
      case "minLength": 
        return <p className="help is-danger">
                {label} {i18n.t('errmsg.minimum')} {rules.minLength} {i18n.t('errmsg.char')}
              </p>;
      case "pattern": 
        return <p className="help is-danger">{label} {i18n.t('errmsg.invalid')}</p>
      default: 
        return null;
    }
  }
}

const TextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  placeholder,
  errors,
  register,
  rules,
  customError,
}) => {

  const [focusBorder, setFocusBorder] = useState(false);
  const [moveLabel, setMoveLabel] = useState(true);
  const fieldActive = (value: string) => {
    // If text-field has value, label should remain visible onBlur
    value.trim() ? setMoveLabel(false) : setMoveLabel(true);
    setFocusBorder(false);
  }

  return (
    <div className={`field text-area mt-2`}>
      <label 
        className={`label ${(moveLabel === false || focusBorder) ? 
          `show ${(errors[name] || customError) ? "has-text-danger" : `${focusBorder ? "has-text-primary" : ""}` }` : 'hide'}`
      }>{label}</label>
      <div className="control has-icons-right">
        <textarea
          placeholder={!focusBorder ? placeholder : ""}
          className={`textarea is-medium ${(errors[name] || customError) ? "is-danger" : (focusBorder ? "is-primary" : "")}`}
          {...register(name, { ...rules })}
          onFocus={_ => setFocusBorder(true)} 
          onBlur={(e) => fieldActive(e.target.value)}
        ></textarea>
        {/* Display custom error if provided else, display default errors */}
        {displayErrorMessage(errors[name] && errors[name].type, customError || "", label, rules)}
      </div>
    </div>

  );
};
export default TextArea;
