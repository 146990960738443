import React from "react";
interface ISectionProps {
  title?: string;
  subtitle: string;
  others: string;
  children?: any;
}
const SubSections: React.FC<ISectionProps> = ({
  title,
  subtitle,
  others,
  children,
}) => {
  return (
    <>
      <div className="result-card-sections">
        <div className="result-card-std-title">{title}</div>
        <div className="result-card-std-subtitle">
          {children} 
        </div>
      </div>
    </>
  );
};

export default SubSections;
