import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { post} from "services/api.service";

const initialState: any = {
    loading: false,
    error: false,
    status: 'pending',
    data: null
}

export const getMaintenanceData: any = createAsyncThunk(
    "getMaintenanceData", async (payload: any, { rejectWithValue }) => {
        try {
            const response: any = await post(payload,endPoints.DASHBOARD.MAINTENANCE);
            return response.data;
        } catch (e:any) {
            return rejectWithValue(e.response.data);
        }
    })


const getMaintenanceDataSlice = createSlice({
    name: 'getMaintenanceData',
    initialState: initialState,
    reducers: {
        clearState: (state) => {
            return initialState;
        },
    },
    extraReducers: {
        [getMaintenanceData.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
        },
        [getMaintenanceData.pending]: (state) => {
            state.loading = true;
        },
        [getMaintenanceData.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.status = "fulfilled";
        }
    }
});

export const { clearState } = getMaintenanceDataSlice.actions;
export const getMaintenanceDataValue = (state: RootState) => state.maintenanceData;
const getMaintenanceDataReducer = getMaintenanceDataSlice.reducer;
export default getMaintenanceDataReducer;
