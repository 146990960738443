import React, { FunctionComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList, ResponsiveContainer } from 'recharts';
import './barChart.scss';
import SearchSkeleton from 'features/search-property/components/SearchSkeleton/SearchSkeleton';

const BarChartGraph: FunctionComponent<any> = ({ data, filterDate, loading }) => {

    const formatDate = (date: any) => {
        if (date) {
            return new Date(date).toLocaleDateString('en-us', { year: "numeric", month: "short" })
        }
    }

    return (
        <div className="bar-chart-graph">

            <h4>Reservation Stats</h4>
            <div className="chart-wrap">
                {loading ? <SearchSkeleton></SearchSkeleton> :
                    <>
                        <div className="chart-details">Number of bookings received from {formatDate(filterDate.from)} to {formatDate(filterDate.to)} </div>
                        <div className="chart-contain">
                            <ResponsiveContainer width="99%" height="100%" aspect={3}>
                                <BarChart
                                    width={1100}
                                    height={168}
                                    data={data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid horizontal={true} vertical={false} />
                                    <XAxis dataKey="date" />
                                    <YAxis type="number" axisLine={false} />
                                    <Bar dataKey="bookings" fill="#42B9E6" barSize={5}>
                                        <LabelList dataKey="bookings" position="top" /> </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </>}
            </div>
        </div>

    );
}

export default BarChartGraph;
