// import React, { useContext, useEffect } from "react";
import React, { useEffect } from "react";

import TopBar from "components/TopBar/TopBar";
import Tabs from "./components/tabs/Tabs";
import './Profile.scss';
import PersonalInformation from "./components/personal-information/PersonalInformation";
import Coupons from "./components/coupons/Coupons";
// import { useAppDispatch, useAppSelector } from "store/store";
import { useAppSelector } from "store/store";
// import { fetchLoyaltyPoints, profile } from "./slices/profile.slice";
import { profile } from "./slices/profile.slice";
import Wishlists from "./components/wishlist/Wishlists";
import Bookings from "./components/booking/Booking";
import { DirectionCheck } from "../../utils/classnameSwitch";
// import { Context } from "components/app/Wrapper";
interface IProfileLayout {
    hasRightContent?: boolean;
}

const fetchComponent = (selectedTab: string) => {
    switch (selectedTab) {
        case "personal-information":
            return <PersonalInformation />;
        case "coupons":
            return <Coupons />;
        case "wishlist":
            return <Wishlists />;
        case "bookings":
            return <Bookings />;
        default:
            return <PersonalInformation />;
    }
}

const ProfileLayout: React.FC<IProfileLayout> = () => {
    const { selectedTab } = useAppSelector(profile);
    // const dispatch = useAppDispatch();
    // const context: any = useContext(Context);

    useEffect(
        () => {
            document.body.style.overflow = "visible";
            return () => {
                document.body.style.overflow = "hidden";
            };
        },
        []
    );
    
    // useEffect(() => {
    //     dispatch(fetchLoyaltyPoints());
    // }, [dispatch, context.locale]);

    return (
        <section className="profile-wrapper"   dir={DirectionCheck()}>
            <TopBar isRightContent={true} />
            <div className="detail-wrapper content-wrap">
                <div className="left-content">
                    <Tabs />
                </div>
                <div className="right-content">
                    {fetchComponent(selectedTab)}
                </div>
            </div>
        </section>
    )
}
export default ProfileLayout;
