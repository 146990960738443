import React from "react";
import { useTranslation } from "react-i18next";
import "./AddGuest.scss";
import minus from "assets/icons/search/minus.svg";
import plus from "assets/icons/search/add.svg";
import {
  guestSelector,
  increment,
  decrement,
} from "./addguest.slice";
import {
  updateSelection,
} from "features/search-property/searchproperty.slice";
import { useAppDispatch, useAppSelector } from "store/store";

const AddGuest: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { adults } = useAppSelector(guestSelector);
  return (
    <>
      <div className="addguest">
        <div className="addguest-container">
          <div className="addguest-title-container">
            <div className="addguest-title">{t('search.guests')}</div>
          </div>
          <div className="addguest-counter-container">
            <div
              className="addguest-counter"
              onClick={() => {
                if (adults !== 1) dispatch(decrement("adults"));
              }}
            >
              <img alt="alt filler" src={minus}></img>
            </div>
            <div className="addguest-value">{adults}</div>
            <div
              className="addguest-counter"
              onClick={() => {
                if (adults <= 9) {
                  dispatch(increment("adults"));
                  dispatch(
                    updateSelection({
                      category: "currentGuests",
                      value: {
                        adult: adults
                      },
                      master: "guests",
                    })
                  );
                }
              }}
            >
              <img alt="alt filler" src={plus}></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGuest;
