import React from "react";

import './ResetPasswordPopup.scss';

interface ResetPasswordPopupProps {
    url: string;
}

const ResetPasswordPopup: React.FC<ResetPasswordPopupProps> = (props) => {
    const mobileRedirect = process.env.REACT_APP_MOBILE_BASEURL + props.url, browserRedirect = process.env.REACT_APP_WEBSITE_BASEURL + props.url;

    const browserRedirection = () => {
        localStorage.setItem("setBrowserRedirect", "true");
        window.location.href = browserRedirect;
    }

    return <div className="popup">
        <h3 className="heading">Already have Staycae App installed?</h3>
        <button className="popup-btn" type="button" onClick={_ => window.location.href = mobileRedirect}>Open app</button>
        <button type="button" className="popup-continue-btn" onClick={_ => browserRedirection()}>Continue in Browser</button>
    </div>
}

export default ResetPasswordPopup;
