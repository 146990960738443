import axios, { AxiosRequestConfig } from "axios";
import { onResponse } from "./onResponse";
import { onRequest, onRequestError } from "./onRequest";
import { getLocalStore, setLocalStore } from "utils/encryption";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_WEBSITE_BASEURL
    : "http://localhost:3000";
export const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use(
  (config: AxiosRequestConfig) => onRequest(config),
  onRequestError
);
API.interceptors.response.use(onResponse, (error) => {
  // Reject promise if usual error
  if (error.request.status !== 401) {
    return Promise.reject(error);
  }
  return axios
    .post("/api/user/refresh_token/", {
      refresh_token: getLocalStore(`${window.location.host}AUTH_DETAILS`),
      id: localStorage.getItem(`${window.location.host}userId`),
    })
    .then((response) => {
      setLocalStore({
        cname: `${window.location.host}AUTH_DETAILS`,
        cvalue: response.data.data.token,
      });
      error.response.config.headers["Authorization"] =
        "Token " + response.data.data.token;
      return axios(error.response.config);
    })
    .catch((error) => {
      localStorage.removeItem(`${window.location.host}AUTH_DETAILS`);
      localStorage.removeItem(`${window.location.host}TEMPAUTH_DETAILS`);
      localStorage.removeItem(`${window.location.host}userId`);
      localStorage.removeItem(`${window.location.host}email_verified`);
      localStorage.removeItem(`${window.location.host}roles`);
      window.location.reload();
    });
});
