import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";
import { HTTPMSG } from "config";
import { GuestCheckoutService } from "./guest-checkout.service";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
  data: {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone_number: "",
    expiry: ""
  }
};

export const resendOtp: any = createAsyncThunk(
  "resendOtp",
  async (payload: string, { rejectWithValue }) => {
    try {
      const response: any = await GuestCheckoutService.resendOtpService(payload);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const resendOtpSlice = createSlice({
  name: "resendOtpSlice",
  initialState,
  reducers: {
    clearState: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      if (payload) {
        state.data = {
          first_name: "",
          last_name: "",
          username: "",
          email: "",
          phone_number: "",
          expiry: ""
        };
      }
      return state;
    },
  },
  extraReducers: {
    [resendOtp.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = HTTPMSG[payload.message || "ERROR"];
    },
    [resendOtp.pending]: (state) => {
      state.isFetching = true;
    },
    [resendOtp.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = HTTPMSG[payload.message || "ERROR"];
      state.data = payload.data;
    },
  },
});

export const { clearState } = resendOtpSlice.actions;
export const resendOtpSelector = (state: RootState) => state.resendOtp;
const resendOtpReducer = resendOtpSlice.reducer;
export default resendOtpReducer;
