import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "store/store";
import Jumbotron from "components/Jumbotron/Jumbotron";
import { locationsSelector, fetchLocations, clearState } from "features/locations/locations.slice";

import './LocationsComponent.scss';
import LocationsCard from "../LocationsCard/LocationsCard";
import { setToastState } from "components/Toast/Toast.slice";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { Context } from "components/app/Wrapper";
import { DirectionCheck } from "utils/classnameSwitch";

const LocationsComponent: React.FC = () => {
    const { t } = useTranslation();
    const context: any = useContext(Context); 
    const dispatch = useAppDispatch();
    const { isSuccess, isError, errorMessage, locationList } = useAppSelector(locationsSelector);
    
    useEffect(() => 
      dispatch(fetchLocations())
    , [dispatch, context.locale]);

    useEffect(() => {
      if (isError) {
        dispatch(setToastState({type: 'danger', message: errorMessage, dismiss: 3500}));
        dispatch(clearState());
      }
    }, [isError, dispatch, errorMessage]);
    
    return (
      <div className="locations" dir={DirectionCheck()}>
        <Jumbotron
          title={t('location.title')}
          subtitle1={t('location.subtitle1')}
          subtitle2={t('location.subtitle2')}
          classes="service-jt"
        ></Jumbotron>
        <div className="grid">
          <p className="locations-heading">{t('location.heading')}</p>
          <div className="locations-card-wrapper">
            {isSuccess ? locationList.map(location => <LocationsCard {...location} />) : null}
          </div>
        </div>
        <ScrollToTop refID="" />
      </div>
    );
}

export default LocationsComponent;
