import { createSlice } from "@reduxjs/toolkit";
import { IOwnerPropertyList } from "features/owner/owner.interface";
import { RootState } from "store/rootReducer";

export interface SingleSelectState {
    options: IOwnerPropertyList;
}

export const initialState: SingleSelectState = {
    options: {
        id: "",
        name: "",
        is_active: false
    }
};

const unitsSingleSlice = createSlice({
    name: "units/singleSelect",
    initialState,
    reducers: {
        updateUnitSelection: (state, { payload }) => {
            state.options = payload;
        }
    }
});

export const { updateUnitSelection } = unitsSingleSlice.actions;
export const unitsSingleSelector = (state: RootState) => state.singleSelect;
const singleSelectReducer = unitsSingleSlice.reducer;
export default singleSelectReducer;
