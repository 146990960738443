import React from "react";

import { PageProps } from "interfaces/page.interface";
import ForgotPasswordComponent from "./components/ForgotPasswordComponent";

const Login: React.FC<PageProps> = (props) => {
  return <ForgotPasswordComponent />;
};

export default Login;
