import { API } from "api/api";
import { endPoints } from "api/endPoints";

export interface ContactUsProps {
    name: string;
    email: string;
    phone_number: string;
    message: string;
}

const contactUs = (data: ContactUsProps) => {
    const body = data;
    return new Promise((resolve, reject) => {
      API.post(endPoints.CONTACT_US, body)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(error);
        });
    });
  };
  
export const ContactUsService = {
    contactUs: contactUs
};