import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTPMSG } from 'config/index';
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { post } from "services/api.service";

interface Response {
    loading: boolean;
    isError: boolean;
    isSucess: boolean;
    errorMessage: string;
    status: string;
    successMessage: string
}

interface Request {
    email: string;
    old_password: string;
    new_password: string;
    reenter_password: string;
}

const intialState: Response = {
    loading: false,
    isError: false,
    errorMessage: '',
    status: 'pending',
    successMessage: '',
    isSucess: false
}

export const verifyNow: any = createAsyncThunk(
    "verifyNow", async (payload: Request, { rejectWithValue }) => {
        try {
            const response: any = await post(payload, endPoints.USERS.VERIFYNOW);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    })

const verifyNowSlice = createSlice({
    name: 'verifyNow',
    initialState: intialState,
    reducers: {
        clearState: (state) => {
            return intialState;
        },
    },
    extraReducers: {
        [verifyNow.rejected]: (state, { payload }) => {
            state.loading = false;
            state.isError = true;
            state.errorMessage = HTTPMSG[payload?.message || 'ERROR'];;
        },
        [verifyNow.pending]: (state) => {
            state.loading = true;
        },
        [verifyNow.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.status = "fulfilled";
            state.isSucess = true;
            state.successMessage = HTTPMSG[payload.message];
        },
    }
});

export const { clearState } = verifyNowSlice.actions;
export const verifyNowStatus = (state: RootState) => state.verificationNow;
const verifyNowReducer = verifyNowSlice.reducer;
export default verifyNowReducer;
