import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "api/api";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";

interface PaymentState {
  paymentInProgress: boolean;
  bookingStatus: string;
  paymentError: string;
}

export const initialState: PaymentState = {
  paymentInProgress: false,
  bookingStatus: '',
  paymentError: ''
};

export const updatePaymentStatus: any = createAsyncThunk(
  "updatepaystatus",
  async (roomInfo, { rejectWithValue }) => {
    try {
      const response: any = await API.post(endPoints.CHECKOUT.UPDATEPAY, roomInfo);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);
export const cancelPayment: any = createAsyncThunk(
  "cancelpaystatus",
  async (roomInfo, { rejectWithValue }) => {
    try {
      const response: any = await API.post(endPoints.CHECKOUT.UPDATEPAY, roomInfo);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);
const paymentSlice = createSlice({
  name: "managepayment",
  initialState,
  reducers: {
    updatePaymentProgress: (state) => {
      state.paymentInProgress = true;
      return state
    },
    clearPaymentInfo: (state) => {
      state.bookingStatus = "";
      state.paymentInProgress = false;
      state.paymentError = "";
      return state
    }
  },
  extraReducers: {
    [updatePaymentStatus.pending]: (state) => {
      state.paymentInProgress = true;
    },
    [updatePaymentStatus.fulfilled]: (state, { payload }) => {
      state.paymentInProgress = false;
      state.bookingStatus = payload.data.status;
    },
    [updatePaymentStatus.rejected]: (state, {payload}) => {
      state.paymentError = payload.message;
    },
    [cancelPayment.fulfilled]: (state, { payload }) => {
      state.bookingStatus = payload.data.status;
    },
  },
});

export const { updatePaymentProgress, clearPaymentInfo } = paymentSlice.actions;
export const paymentSelector = (state: RootState) => state.payment;
const paymentReducer = paymentSlice.reducer;
export default paymentReducer;
