import { IOwnerPropertyList } from "features/owner/owner.interface";
import { ownerProperties, ownerSelector } from "features/owner/owner.slice";
import useClickOutside from "hooks/useClickOutside";
import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  multiSelectSelector,
  resetAll,
  updateSelection,
} from "./multi-select.slice";
import { Context } from "components/app/Wrapper";

import "./MultiSelect.scss";

interface IMultiSelect {
  placeholder: string;
}

const getTagLimit = (page: string): number => {
  if (window.innerWidth > 1800) {
    return page === "dashboard" ? 3 : 2;
  } else if (window.innerWidth > 1440) {
    return page === "dashboard" ? 2 : 1;
  }
  return 1;
};

const getPathName = (pathname: string): string => {
  if (pathname === "/owner") {
    return "dashboard";
  } else if (pathname.includes("/reservation")) {
    return "reservation";
  }
  return "";
};

const MultiSelect: React.FC<IMultiSelect> = ({ placeholder }) => {
  const context: any = useContext(Context);
  const [toggle, setToggle] = useState(false);

  const pathname = useLocation().pathname;
  const [limit, setLimit] = useState(getTagLimit(getPathName(pathname))); // Number of units to be displayed in units selection by default

  const [searchText, setSearchText] = useState("");
  const node = React.useRef<HTMLDivElement>(null);
  useClickOutside(node, () => {
    setToggle(false);
  });
  const dispatch = useAppDispatch();
  const location = useLocation().pathname;
  const [selectAll, setSelect] = useState(true); // by default all units are selected

  const { options: units } = useAppSelector(multiSelectSelector);
  const { data, isFetching, isSuccess } = useAppSelector(ownerSelector);
  const [unitOptions, setUnits] = useState<IOwnerPropertyList[]>([]);
  const [isTyping, setTypingStatus] = useState(true);

  useEffect(() => {
    dispatch(ownerProperties());
  }, [dispatch, context.locale]);

  useEffect(() => {
    window.addEventListener("resize", (_) => setTagLimit());
    window.addEventListener("load", (_) => setTagLimit());
    return () => {
      window.removeEventListener("resize", (_) => setTagLimit());
      window.removeEventListener("load", (_) => setTagLimit());
    };
  });

  const setTagLimit = () => setLimit(getTagLimit(getPathName(pathname)));

  const addOrRemoveUnits = (
    unit: IOwnerPropertyList,
    action: "add" | "remove"
  ) => {
    if (action === "add") {
      dispatch(updateSelection([unit, ...units]));
    } else {
      dispatch(
        updateSelection(
          units.filter((eachUnit) => eachUnit.name !== unit?.name)
        )
      );
    }
  };

  const optionSelection = (unit: IOwnerPropertyList) =>
    !units.some((eachUnit) => eachUnit.name === unit?.name)
      ? addOrRemoveUnits(unit, "add")
      : addOrRemoveUnits(unit, "remove");

  const tagSelection = (unit: IOwnerPropertyList, e: any) => {
    e.stopPropagation();
    showOrCollapseMenu("tag");
    addOrRemoveUnits(unit, "remove");
  };

  const showOrCollapseMenu = (action: "dropdown" | "tag") => {
    if (action === "dropdown") {
      setToggle(!toggle);
    }
  };

  const onSearch = (text: string) => setSearchText(text.toLowerCase());

  useEffect(() => {
    if (selectAll) {
      dispatch(
        updateSelection(
          [...unitOptions, ...units].filter(
            (v, i, a) => a.findIndex((t) => t.name === v.name) === i
          )
        )
      );
    } else {
      if (!isTyping) {
        if (unitOptions.length === data.length) {
          dispatch(resetAll());
        } else {
          dispatch(
            updateSelection(
              units.filter(
                (selectedUnit: any) =>
                  !unitOptions
                    .map((unit: any) => unit.id)
                    .includes(selectedUnit.id)
              )
            )
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [selectAll]);

  useEffect(() => {
    if (isSuccess && data.length > 0) {
      location.includes("report")
        ? dispatch(updateSelection(data.slice(0, 1)))
        : dispatch(updateSelection(data.slice().reverse()));
    }
    if (isSuccess) {
      setUnits(data);
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    setUnits(
      data.filter((unit: any) => unit?.name.toLowerCase().includes(searchText))
    );
    setTypingStatus(true);
    if (searchText) {
      setSelect(false);
      units.filter((sUnit: any) =>
        unitOptions.map((unit: any) => unit.id).includes(sUnit.id)
      ).length === unitOptions.length
        ? setSelect(true)
        : setSelect(false);
    }
    // eslint-disable-next-line
  }, [searchText]);

  if (isSuccess && data.length === 0) {
    return (
      <div
        className={`multiSelectOptions dropdown`}
        onClick={(_) => showOrCollapseMenu("dropdown")}
        ref={node}
      >
        <div className="dropdown-trigger">
          <div
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <span>No units available</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`multiSelectOptions dropdown ${toggle ? "is-active" : ""}`}
      onClick={(_) => showOrCollapseMenu("dropdown")}
      ref={node}
    >
      <div className="dropdown-trigger">
        <div
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          {isFetching && !isSuccess ? (
            <span className="mb-0">{placeholder}</span>
          ) : units.length > limit ? (
            <>
              {units.slice(0, limit).map((unit, index) => (
                <span
                  className={`tag ${index === 0 ? "ml-0" : ""}`}
                  onClick={(e) => tagSelection(unit, e)}
                >
                  {unit?.name}{" "}
                  <i className="fas fa-close ml-2" aria-hidden="true"></i>
                </span>
              ))}{" "}
              &nbsp;+ {units.length - limit} more
            </>
          ) : units.length > 0 ? (
            units.map((unit, index) => (
              <span
                className={`tag ${index === 0 ? "ml-0" : ""}`}
                onClick={(e) => tagSelection(unit, e)}
              >
                {unit?.name}{" "}
                <i className="fas fa-close ml-2" aria-hidden="true"></i>
              </span>
            ))
          ) : (
            <span className="mb-0">{placeholder}</span>
          )}
          <span className="icon is-small">
            {toggle ? (
              <i className="fas fa-angle-up" aria-hidden="true"></i>
            ) : (
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            )}
          </span>
        </div>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="field mb-0 mt-2" onClick={(e) => e.stopPropagation()}>
          <p className="control has-icons-right">
            <input
              className="input"
              type="text"
              placeholder="Search Unit"
              onInput={(e: any) => onSearch(e.target.value)}
              value={searchText ? searchText : ""}
            />
            <span className="icon is-small is-right">
              <i className="fas fa-search"></i>
            </span>
          </p>
        </div>
        <div
          className="field mb-0 mt-2 select-all"
          onClick={(e) => {
            e.stopPropagation();
            setSelect(!selectAll);
            setTypingStatus(false);
          }}
          style={{ padding: "0 26px", cursor: "pointer" }}
        >
          <input
            type="checkbox"
            name="selectDeselect"
            className="mr-2"
            checked={selectAll ? true : false}
          />
          Select All
          <hr className="mt-2 mb-0" />
        </div>
        <div className="dropdown-content mt-2">
          {unitOptions.map((unit: any) => (
            <span
              style={{ fontSize: "14px" }}
              className={`dropdown-item pt-2 pb-2 unit-list-item ${
                units.some((eachUnit) => eachUnit.name === unit?.name)
                  ? "selected-unit"
                  : ""
              }`}
              onClick={(_) => optionSelection(unit)}
            >
              <input
                type="checkbox"
                name={unit?.name}
                id={unit?.name}
                className="mr-2"
                checked={
                  units.some((eachUnit) => eachUnit.name === unit?.name)
                    ? true
                    : false
                }
                onChange={(_) => optionSelection(unit)}
              />
              {unit?.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
