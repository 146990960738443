import { multiSelectSelector, updateSelection } from "components/multi-select/multi-select.slice";
import { setToastState } from "components/Toast/Toast.slice";
import { ownerSelector } from "features/owner/owner.slice";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getReservationList,
  getReservations,
  updateSearchText,
  clearFilters,
} from "../../reservation.slice";
import "./TableSearch.scss";

const TableSearch: React.FC<any> = (props) => {
  const [query, setQuery] = useState("");
  const dispatch = useAppDispatch();
  const { selecteddate } =
    useAppSelector(getReservations);
  const { options } = useAppSelector(multiSelectSelector);
  const { data } = useAppSelector(ownerSelector);
  
  const handleChange = (evt: any) => {
    setQuery(evt.target.value);
    dispatch(updateSearchText(evt.target.value));
  };

  return (
    <>
      <div className="owner-scheduler-table-header">
        <input
          className="owner-search"
          placeholder="Search by Reservation Id"
          onChange={(e: any) => {
            handleChange(e);
          }}
          value={query}
        />
        <div className="btn-wrap">
          <button
            className="search-btn"
            onClick={() => {
              if (options.length) {
                props.onSearch(true); // Trigger reservation api call only on search
              }
              else {
                props.onSearch(false);
                dispatch(setToastState({type: "danger", message: "Units can't be empty!", dismiss: 3000}));
              }
            }}
          >
            Search
          </button>
          <a
            className="clear-all-table"
            href="#/"
            onClick={(e) => {
              e.preventDefault();
              dispatch(clearFilters());
              dispatch(
                getReservationList({
                  ...selecteddate,
                  units: data.map((unit) => unit.id).join(), // Reset to default number of units on clear
                  status: "",
                  reservation_id: "",
                  limit: 1000,
                  page: 2,
                })
              );
              setQuery("");
              dispatch(updateSelection(data));
            }}
          >
            Clear All
          </a>
        </div>
      </div>
    </>
  );
};

export default TableSearch;
