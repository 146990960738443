import React, { useEffect } from "react";
import { useHistory } from "react-router";

import PropertyForm from "features/list-property/components/PropertyForm/PropertyForm";
import OwnerContact from "./components/OwnerContact";
import { useAppSelector } from "store/store";
import { profile } from "features/profile/slices/profile.slice";

import './AddProperty.scss';

const AddProperty: React.FC = () => {
    const { isOwner, loading } = useAppSelector(profile);
    const history = useHistory();
    

    useEffect(() => {
        if (!loading && !isOwner) {
            history.push("/");
        }
    }, [loading, isOwner, history]);

    if (loading) {
        return <div className="dashboard-main add-property">
            <div className="list-property-wrapper">
                <div className="list-property-grid">
                    <div className="columns mt-4 map-loader" style={{minHeight: "500px"}}></div>
                </div>
            </div>
        </div>
    }

    return (
        <div className="dashboard-main add-property">
            <div className="list-property-wrapper">
                <div className="list-property-grid">
                    <div className="columns mt-4">
                        <div className="column order-1">
                            <OwnerContact />
                        </div>
                        <div className="column">
                            <PropertyForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProperty;
