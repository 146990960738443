import React from "react";

import "./WishListLoader.scss";

const WishListLoader: React.FC = () => {
  return (
    <>
      <div className={`columns wishlist-columns wishlist-loader`}>
        <div className={`wishlist-image`}></div>
        <div className={`wishlist-contents`}>
          <div className="left-pane">
            <div className="wishlist-name"></div>
            <div className="amenities-wrap">
              <div className="title mb-0"></div>
              <div className="content"></div>
            </div>
            <div className="amenities-wrap">
              <div className="title mb-0"></div>
              <div className="content"></div>
            </div>
          </div>
          <div className="wishlist-booking-container-loader">
            <div className="wishlist-price"></div>
            <div className="rating-wrap mt-3"></div>
            <button className={"staycae-btn button"}></button>
          </div>
        </div>
      </div>
      <div className={`columns wishlist-columns wishlist-loader`}>
        <div className={`wishlist-image`}></div>
        <div className={`wishlist-contents`}>
          <div className="left-pane">
            <div className="wishlist-name"></div>
            <div className="amenities-wrap">
              <div className="title mb-0"></div>
              <div className="content"></div>
            </div>
            <div className="amenities-wrap">
              <div className="title mb-0"></div>
              <div className="content"></div>
            </div>
          </div>
          <div className="wishlist-booking-container-loader">
            <div className="wishlist-price"></div>
            <div className="rating-wrap mt-3"></div>
            <button className={"staycae-btn button"}></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WishListLoader;
