import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { post } from "services/api.service";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { HTTPMSG } from "config/index";
import i18n from "locale/translation"

interface ISubs {
  email: string;
}
interface HomeState {
  isPending: boolean;
  isSuccess: boolean;
  isError: boolean;
  erroMessage: string;
  successMessage: string;
}
export const initialState: HomeState = {
  isPending: false,
  isSuccess: false,
  isError: false,
  erroMessage: "",
  successMessage: "",
};
export const addSubscriber: any = createAsyncThunk(
  "subscriptions",
  async (payload: PayloadAction<ISubs>, { rejectWithValue }) => {
    try {
      const rsp: any = await post(payload, endPoints.USERS.SUBSCRIPTION);
      return rsp.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,

  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isPending = false;
      state.erroMessage = "";
      state.successMessage = "";

      return state;
    },
  },
  extraReducers: {
    [addSubscriber.fulfilled]: (state, action) => {
      state.isPending = false;
      state.isSuccess = true;
      state.successMessage = HTTPMSG["SUBSCRIBED"];
    },
    [addSubscriber.pending]: (state, action) => {
      state.isPending = true;
    },
    [addSubscriber.rejected]: (state, { payload }) => {
      state.isPending = false;
      state.isError = true;
      state.erroMessage = i18n.t(HTTPMSG[payload.message]);
    },
  },
});

export const { clearState } = homeSlice.actions;
export const homeSelector = (state: RootState) => state.home;
const homeReducer = homeSlice.reducer;
export default homeReducer;
