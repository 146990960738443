import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import moment from "moment";

import MultiDatePicker from "features/owner/dashboard/components/multiDatePicker/MultiDatePicker";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  generateOwnerReport,
  ownerSelector,
  setDates,
} from "features/owner/owner.slice";
import UnitsDropdown from "components/UnitsDropdown/UnitsDropdown";
import { unitsSingleSelector } from "components/UnitsDropdown/units-dropdown.slice";

import "./ReportOptions.scss";

const ReportOptions: React.FC = () => {
  const dispatch = useAppDispatch();
  const [dates, setDateRange] = useState({
    from: "",
    to: "",
  });
  const getSelectedDates = (date: any) => setDateRange(date);
  const { options: selectedUnit } = useAppSelector(unitsSingleSelector);
  const [error, setError] = useState(false);
  const [generate, setGenerate] = useState(true);
  const { isReportFetching } = useAppSelector(ownerSelector);

  const setSelectedDates = () => {
    if (selectedUnit.id.trim()) {
      setError(false);
      const updateSetDates = () =>
        new Promise<void>((resolve, reject) => {
          dispatch(setDates(dates));
          resolve();
        });
      updateSetDates();
      dispatch(
        generateOwnerReport({
          units: selectedUnit.id,
          ...dates,
          generated_time: format(new Date(), "MMM. d, yyyy, hh:mm a"),
        })
      );
    } else setError(true);
  };

  useEffect(() => {
    if (selectedUnit.id.length > 0 && generate && dates.from && dates.to) {
      setGenerate(false);
      setSelectedDates();
    }
    //eslint-disable-next-line
  }, [selectedUnit.id, dates.from, dates.to]);

  return (
    <div className="report-options">
      <h3 className="section-title">Report Details</h3>
      <hr className="mt-2 mb-2 divider" />

      <label htmlFor="" className="mt-4 label">
        Units:
      </label>
      <UnitsDropdown placeholder="Select Units" />
      {error ? (
        <p className="has-text-danger mt-2" style={{ fontSize: "smaller" }}>
          Please select atleast one unit
        </p>
      ) : null}

      <MultiDatePicker selectedDate={getSelectedDates} />

      <button
        className={`mr-3 download-btn mt-4 ${
          !isReportFetching ? "" : "is-disabled"
        } ${moment(dates.from) > moment(dates.to) ? "is-disabled" : ""}`} // disable if date range is invalid
        onClick={(_) => setSelectedDates()}
      >
        Generate Report
      </button>
    </div>
  );
};

export default ReportOptions;
