import React from "react";
import { useTranslation } from "react-i18next";
import useClickOutside from "hooks/useClickOutside";

import "./CancelConfirmPopup.scss";

interface ICancelConfirmPopup {
  onClose: (showPopup: boolean) => any,
  handleCancelBoooking: (val: any) => any,
  data: any,
  cancelID: any,
  isCancelled: any
}
const CancelConfirmPopup: React.FC<ICancelConfirmPopup> = ({
  onClose,
  handleCancelBoooking, data,
  cancelID, isCancelled
}) => {
  const node = React.useRef<HTMLDivElement>(null);
  useClickOutside(node, () => {
    onClose(false);
  });
  const { t } = useTranslation();
  return (
    <div className={`modal is-active non-member-checkout`}>
      <div className="modal-background background-banner"></div>
      <div className="modal-card modal-window" ref={node as any}>
        <section className="modal-card-body mod-content">
          <h3 className="mt-3 highlight-text">
            <h1 style={{ fontSize: "24px" }}>{t('checkout.wait')} </h1><br />
            {t('checkout.waitDesc')}
          </h3>

          {/* <p className="mb-3 mt-4 grab-offer">Grab this offer right away?</p> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-evenly"
            }}
          >
            <button className={`is-primary staycae-btn button ml-4 book-btn confirm-cancel  ${(isCancelled && cancelID === data.id) ? 'is-loading' : ''}`} onClick={(e: any) => {
              e.preventDefault();
              handleCancelBoooking(data);
              // Redirect to checkout page
            }}>{t('checkout.yes')}</button>
            <button className={`guest-btn mt-4 confirm-cancel  ${(isCancelled && cancelID === data.id) ? 'is-disabled' : ''}`}
              onClick={(e: any) => onClose(e)}
            >{t('checkout.no')}</button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CancelConfirmPopup;
