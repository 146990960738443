import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { API } from "api/api";
import { RootState } from "store/rootReducer";
interface ResultState {
  isFetchingMapSearchResult: boolean;
  mapSearchResultList: any;
  total_property_count: number;
}

export const initialState: ResultState = {
  isFetchingMapSearchResult: false,
  mapSearchResultList: [],
  total_property_count: 0
};

export const fetchAllProperties: any = createAsyncThunk(
  "fetchProperties",
  async (params) => {
    const rsp: any = await API.get(endPoints.SEARCH, {
      params
    });
    return {
      data: rsp.data.data
    };
  }
);

const fetchPropertiesSlice = createSlice({
  name: 'fetchProperties',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllProperties.rejected]: (state, { payload }) => {
      state.isFetchingMapSearchResult = true;
      state.mapSearchResultList = [];
      state.total_property_count = 0
    },
    [fetchAllProperties.pending]: (state) => {
      state.isFetchingMapSearchResult = false;
    },
    [fetchAllProperties.fulfilled]: (state, { payload }) => {
      state.isFetchingMapSearchResult = true;
      const { data } = payload;
      state.mapSearchResultList = data.room_categories;
      state.total_property_count = data.total_count;
    }
  }
});

export const fetchPropertiesData = (state: RootState) => state.fetchAllProperties;
const fetchPropertiesReducer = fetchPropertiesSlice.reducer;
export default fetchPropertiesReducer;