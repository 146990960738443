import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "api/api";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";

interface WishlistState {
  isWishlistAdded: boolean;
}

export const initialState: WishlistState = {
  isWishlistAdded: false,
};

export const addToWishlist: any = createAsyncThunk(
  "addWishlist",
  async (id: string) => {
    const rsp: any = await API.post(endPoints.WISHLIST, {
      room_category: id,
    });
    return {
      data: rsp.data.data,
    };
  }
);
export const removeFromWishlist: any = createAsyncThunk(
  "removeWishlist",
  async (id: string) => {
    const rsp: any = await API.delete(endPoints.WISHLIST, {
      data: {
        room_category: id,
      },
    });
    return {
      data: rsp.data.data,
    };
  }
);
const wishListSlice = createSlice({
  name: "manageWishList",
  initialState,
  reducers: {},
  extraReducers: {
    [addToWishlist.rejected]: (state, { payload }) => {
      state.isWishlistAdded = true;
    },
    [addToWishlist.fulfilled]: (state, { payload }) => {
      state.isWishlistAdded = true;
    },
  },
});

export const wishListSelector = (state: RootState) => state.wishlist;
const wishListReducer = wishListSlice.reducer;
export default wishListReducer;
