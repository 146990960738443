export const checkPassword = (string: string) => {
  const symbolRegex = /(?=.*?[#?!@$%^&*-])/;
  const numberRegex = /[0-9]/;
  const upperCaseRegex = /[A-Z]/;
  const lowerCaseRegex = /[a-z]/;
  if (string === "") {
    return {
      errorMsg: "common.pwdrequired",
      isValid: false,
    };
  } else if (string.length < 6 || string.length > 30) {
    return {
      errorMsg: "common.atleastChar",
      isValid: false,
    };
  } else if (
    !lowerCaseRegex.test(string) ||
    !numberRegex.test(string) ||
    !upperCaseRegex.test(string) ||
    !symbolRegex.test(string)
  ) {
    return {
      errorMsg: "common.pwdChecks",
      isValid: false,
    };
  } else {
    return {
      errorMsg: "",
      isValid: true,
    };
  }
};

export const createMarkup = (content: any) => {
  return {
    __html: content,
  };
};

export const clearBookingStates = () => {
  localStorage.removeItem("checkin");
  localStorage.removeItem("checkout");
  localStorage.removeItem("currentRoom");
  localStorage.removeItem("priceSelected");
  localStorage.removeItem("price");
  localStorage.removeItem("guests");
  localStorage.removeItem("stayDetail");
  localStorage.removeItem("resetPasswordFlow");
  localStorage.removeItem("registerFlow");
};

export const findDaysDifference = (fromDate: string, toDate: string): number => {
  const differenceInMilliseconds = new Date(toDate).getTime() - new Date(fromDate).getTime();

  // Convert the difference between start and end date
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  return differenceInDays;
};
