import { AxiosResponse, AxiosError } from "axios";

const getUnknownError = (message: string) => ({
  response: {
    error: [
      message && typeof message === typeof ""
        ? message
        : "Service unavailable. Please try after some time.",
    ],
  },
});

export function onResponse(response: AxiosResponse) {
  const customResponse = response;
  const { status } = response.data;
  if (status === 500) {
    return Promise.reject(getUnknownError(""));
  }
  if (status === 400) {console.log('bad request');
    const { errorMessage } = response.data.error[0];
    return Promise.reject(getUnknownError(errorMessage));
  }
  customResponse.data = response.data;
  return customResponse;
}

export function onResponseError(error: AxiosError) {
  const status = error.response
    ? error.response.headers.status || error.response.status
    : -1;
  if (status === 401) {
  }
  return Promise.reject(error);
}
