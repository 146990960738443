const ar = {
  landing: {
    offer: "عروض",
    services: "خدمات",
    locations: "المواقع",
    host: "مضيف",
    book: "احجز غرفة",
    register: "تسجيل الدخول",
    switch: "التبديل إلى",
    owner: "صاحب",
    guest: "ضيف",
  },
  search: {
    locations: "المواقع",
    forStay: "أنا أبحث عن إقامة في",
    from: "من عند",
    checkIn: "تحقق في",
    to: "إلى",
    checkOut: "الدفع",
    for: "ل",
    guests: "ضيوف",
    title: "بحث",
    find: "تجد",
  },
  home: {
    title: {
      findYour: "تجد الخاص بك",
      perfPlace: "مكان مثالي",
      stayLong: "ابق طويلا",
      youLike: "تحب",
    },
    banner: {
      exp: "خبرة",
      dimension: "البعد الجديد لـ",
      comfort: "الراحة والاسترخاء",
      withus: "معنا.",
      sub1: "اختر ما يناسبك من بين آلاف الأماكن الرائعة ، وحدد التاريخ ،",
      sub2: "وسنتأكد من أن فوطتك الجديدة جاهزة للبقاء ،",
      sub3: "قبل وصولك.",
      button: "احجز الآن",
    },
    homeOffer: {
      title: "احتضان العظمة في ميزانيتك",
      deal: "صفقات مثيرة مع عروض خاصة",
      viewAll: "مشاهدة الكل",
      details: "عرض التفاصيل",
    },
    attractions: {
      title: "اعثر على إقامتك المثالية لاكتشاف والاستمتاع ...",
      subtitle: "مناطق جذب مدهشة من حولك",
      adventure: "متعة المغامرة مع أحبائك",
    },
    testimonials: {
      title: "هذا ما يقوله عملاؤنا",
      stayedAt: "أقمت في",
    },
  },
  footer: {
    contact: {
      phone: "رقم الهاتف",
      mail: "بريد الالكتروني",
      whatsapp: "ال WhatsApp",
    },
    about: {
      title: "حول",
      whoarewe: "من نحن؟",
      service: "خدمات",
      offers: "عروض",
    },
    help: {
      title: "يساعد",
      contact: "اتصل بنا",
    },
    policies: {
      title: "سياسات",
      cancellation: "إلغاء",
      payandRef: "الدفع والاسترداد",
    },
    stayConnected: "ابق على اتصال",
    stayUpdated: {
      title: "ابق على اطلاع",
      enterMail: "أدخل عنوان بريدك الالكتروني",
      offer: "ستكون قادرًا على تلقي إشعارات بالعروض الأخيرة.",
      emailPlaceholder: "عنوان البريد الإلكتروني",
      submit: "يقدم",
    },
    copyRight: "STAYCAE UAE",
    copyRightText: "حقوق النشر ©",
  },
  bookAStay: {
    booknow: "احجز الآن",
    viewdetails: "عرض التفاصيل",
    subtitle: "معظم المنازل الموصى بها",
    rated: "مصنفة",
    title: "إقامات مميزة",
    popularLocation: "موقع شعبي",
  },
  searchResults: {
    filter: "التصفية والفرز",
    reset: "إعادة ضبط",
    price: "سعر",
    discnt: "الخصومات",
    rooms: "غرف",
    amnty: "وسائل الراحة",
    map: "خريطة",
    list: "قائمة",
    show: "عرض",
    prop: "الخصائص",
    near: "قرب",
    sort: "نوع",
    featured: "متميز",
    new: "الأحدث",
    dscnthl: "الخصومات (عالية - منخفضة)",
    pricehl: "السعر (مرتفع - منخفض)",
    pricelh: "السعر (منخفض - مرتفع)",
    more: "عرض المزيد",
    less: "أقل",
    close: "قريب",
    applyFilter: "تطبيق تصفية",
  },
  stayDetails: {
    desc: "وصف",
    loc: "موقع",
    services: "خدمات",
    deals: "العروض",
    locs: "المواقع",
    book: "الكتاب",
    pricing: "التسعير",
    timing: "توقيت",
    rules: "قواعد المنزل",
    attr: "أقرب مناطق الجذب",
    review: "التقييمات والمراجعات",
    avgrate: "متوسط ​​التقييمات",
    pcy: "سياسات",
    stayHeader: "منازل مريحة أخرى قد تعجبك",
    reg: "يسجل",
    or: "أو",
    login: "تسجيل الدخول",
    priceInfo: "انقر فوق السعر الذي تفضله.",
    rvw: "المراجعات",
    offerText: "الحصول على هذه الإقامة ل",
    save: "أنت أحفظ",
    in: "تحقق في",
    out: "اجراءات المغادرة",
    member: "عضو Staycae",
    nonmember: "غير الأعضاء",
    refund: "قابل للإسترداد",
    nonrefund: "غير قابل للاسترجاع",
    ruleText: {
      groupAllowed: "يسمح بالحفلات",
      groupNotAllowed: "الأطراف غير مسموح بها",
      petsAllowed: "مسموح بدخول الحيوانات الأليفة",
      petsNotAllowed: "غير مسموح بالحيوانات الاليفة",
      smokingAllowed: "مسموح التدخين",
      smokingNotAllowed: "ممنوع التدخين",
      min_age_to_book: "الحد الأدنى لسن عملية تسجيل الوصول هو",
    },
    propertyDist: "كم (ث) من العقار",
  },
  common: {
    bedrooms: "غرف نوم",
    bathrooms: "الحمامات",
    maxpersons: "ماكس الأشخاص",
    bedroom: "غرفة نوم",
    attr: "مناطق الجذب القريبة",
    services: "خدمات",
    amnty: "وسائل الراحة",
    total: "المجموع",
    rated: "مصنفة",
    login: "تسجيل الدخول",
    email: "بريد الالكتروني",
    pwd: "كلمه السر",
    fgtpwd: "هل نسيت كلمة السر؟",
    submit: "يقدم",
    profile: "الملف الشخصي",
    logout: "تسجيل خروج",
    pwdrequired: "كلمة المرور مطلوبة",
    studio: "الاستوديو",
    guests: "ضيوف",
    hosts: "المضيفون",
    numberBath: "عدد الحمامات",
    numberBed: "ارقام الغرف",
    sqrfeet: "قدم مكعب",
    invoice: "فاتورة",
    transaction_id: "عملية تجارية",
    date: "تاريخ",
    atleastChar: "يجب أن تحتوي كلمة المرور على 6-30 حرفًا!",
    pwdChecks:
      "يجب أن تحتوي كلمة المرور على الأقل على رقم وحرف صغير وحرف كبير وحرف خاص!",
    pwdMiss: "كلمات المرور هذه غير متطابقة. حاول مجددا.",
  },
  notFound: {
    title: "هناك شئ غير صحيح",
    paraText:
      "الصفحة التي تبحث عنها تم نقلها أو إزالتها أو إعادة تسميتها أو ربما لم تكن موجودة على الإطلاق.",
    btn: "اذهب للمنزل",
  },
  login: {
    signup: "أو اشترك مع",
    continue: "تواصل كضيف",
    noAccnt: "ليس لديك حساب؟",
    reg: "سجل الان",
  },
  register: {
    alreadyAccnt: " هل لديك حساب ؟ ",
    gotologin: "اذهب إلى تسجيل الدخول",
    firstname: "الاسم الأول",
    lastname: "الكنية",
    emailaddr: "عنوان البريد الإلكتروني",
    phone: "هاتف",
    toastMsgs: {
      successfulReferral:
        "تم التسجيل بنجاح باستخدام رمز الإحالة. قبل المتابعة ، يرجى التحقق من بريدك الإلكتروني للحصول على رابط التحقق",
      expiredReferral:
        "تم التسجيل بنجاح ولكن انتهت صلاحية رمز الإحالة. قبل المتابعة ، يرجى التحقق من بريدك الإلكتروني للحصول على رابط التحقق",
      defaultSignup:
        "قبل المتابعة ، يرجى التحقق من بريدك الإلكتروني للحصول على رابط التحقق",
    },
  },
  fgtpwd: {
    title: "هل نسيت كلمة السر",
    text: "أدخل عنوان بريدك الإلكتروني أدناه وسنرسل لك تعليمات حول كيفية تغيير كلمة المرور الخاصة بك.",
  },
  header: {
    switch: "التبديل إلى",
    guest: "ضيف",
    owner: "صاحب",
  },
  resetpwd: {
    title: "إعادة تعيين كلمة المرور",
    new: "كلمة مرور جديدة",
    retype: "أعد كتابة كلمة السر الجديدة",
  },
  errmsg: {
    require: "مطلوب",
    maximum: "يجب ألا يتجاوز الحد الأقصى",
    char: "الشخصيات",
    minimum: "يجب أن تحتوي على الأقل",
    limit: "لا ينبغي أن يتجاوز",
    atleast: " يجب أن يكون على الأقل",
    invalid: "غير صالح",
    phonerequire: "رقم الهاتف مطلوب",
    phoneinvalid: "رقم الهاتف غير صحيح",
    human: "يرجى التحقق من أنك إنسان.",
  },
  aboutus: {
    wehave: "واليوم لدينا ...",
    ourpartner: "شركاؤنا",
    partner: "شركاء",
    happyguest: "ضيوف سعداء",
    host: "مستضيفات",
  },
  contactus: {
    title: "اتصل بنا",
    reach: "تواصل معنا",
    locate: "موقعنا",
    question: "هل لديك سؤال؟ اترك لنا رسالة",
    msg: "رسالة",
    verify: "يرجى التحقق من أنك إنسان.",
    send: "يرسل",
    address1: "صندوق بريد 119169",
    address2: "مراسي درايف, دبي",
    address3: "الأمارات العربية المتحدة",
    contactno: "0450 210 4 971+",
  },
  Bathroom: "حمام",
  Internet: "إنترنت",
  Kitchen: "مطبخ",
  "Bed & Laundry": "السرير والغسيل",
  Entertainment: "تسلية",
  "Other Facilities": "المرافق الاخرى",
  "Service Charge": "تكلفة الخدمة",
  VAT: "ضريبة القيمة المضافة",
  "Tourism Fee": "رسوم السياحة",
  "Applied Coupon": "القسيمة المطبقة",
  "Offer Discount": "عرض الخصم",
  infoTxt: {
    text1: "معظم أماكن الإقامة غير متاحة في التواريخ التي تفضلها على موقعنا.",
    text2:
      "إذا كنت مرنًا ، فحاول تغيير التواريخ الخاصة بك لزيادة التوافر إلى أقصى حد أو تحقق من الإقامات المتاحة أدناه.",
    text3: "معظم أماكن الإقامة غير متاحة للتواريخ / معايير البحث الخاصة بك.",
    text4:
      "إذا كنت مرنًا ، فيرجى مراجعة البحث أو التحقق من الإقامات المتاحة أدناه.",
    text5: "نأسف ، كل النتائج في",
    text6: "تم بيعها على Staycae.",
  },
  referral: {
    text1: "قم بدعوة أصدقائك إلى Staycae وستربح قسيمة باستخدام",
    text12: "٪ خصم عندما يحجزون معنا لأول مرة. يمكنك كسب 5 كوبونات بحد أقصى.",
    text2:
      "شارك هذا الرابط مع أصدقائك وعائلتك واكسب قسائم أثناء قيامهم بالحجز معنا.",
    text3: "(أو)",
    text4: "أرسل دعوة عبر البريد الإلكتروني",
    text5: "يرجى الضغط",
    text6: "أدخل",
    text7: "أو",
    text8: "مساحة",
    text9: "بعد إدخال عنوان البريد الإلكتروني لإضافتهم إلى القائمة البريدية",
    text10: "الدعوات المتبقية!",
    text11: "ارسل دعوات",
    emailError: "البريد الإلكتروني الذي تم إدخاله غير صالح",
    toastMsg: {
      success: "تم إرسال الدعوة!",
      error: "حدث خطأ أثناء إرسال الدعوة. الرجاء معاودة المحاولة في وقت لاحق!",
      copy: "تم نسخ رمز القسيمة!",
    },
  },
  location: {
    heading: "اكتشف أفضل مكان للإقامة!",
    title: "المواقع",
    subtitle1:
      "نقوم بالبحث عن المنازل وإدراجها في أفضل المواقع في المدينة ، بحيث لا يكون موقع STAYCAE الخاص بك ممتعًا فحسب ، بل مريحًا أيضًا.",
    subtitle2:
      "ستكون قريبًا من مراكز التسوق والمواصلات العامة والمطاعم وجميع المشاركات عندما تكون STAYCAE",
    button: "عرض الإقامات",
  },
  beHost: {
    title: "كن مضيفنا",
    desc: "أرسل لنا تفاصيل الممتلكات الخاصة بك وسوف نتصل بك في غضون أسبوع إذا كانت تلبي معاييرنا. كوننا حلاً مبتكراً لإدارة الممتلكات ، نحول شقتك إلى غرفة فندقية ، وبالتالي نحقق عائد استثمار يصل إلى 30٪ أعلى من الإيجارات طويلة الأجل.",
    subtitle: "انضم إلينا عن طريق استئجار منزلك الإضافي",
    guarantee: "مضمون الخصوصية",
    knowBenefits: "تعرف على الفوائد الخاصة بك مع Staycae",
    propHeading: "إنتاجية إيجارية عالية",
    propDesc: "احصل على إيجار أكثر وتدفق نقدي ثابت",
    secureHeading: "الضيوف المعتمدون",
    secureDesc: "يتم فحص السياح والمسافرين من رجال الأعمال أثناء الحجز",
    freeHeading: "صيانة خالية من القلق",
    freeDesc: "نتولى جميع أعمال الصيانة حتى تحتفظ ممتلكاتك بقيمة",
    timerHeading: "إيجار مرن",
    timerDesc: "يمكنك استعادة الممتلكات الخاصة بك في وقت أقصر",
    personal: "تفاصيل شخصية",
    property: "تفاصيل اوضح",
    submit: "تقديم للمراجعة",
    propName: "اسم الخاصية",
    proType: "نوع الملكية",
    villa: "فيلا",
    flat: "مسطحة",
    addr: "عنوان",
    sqrfeet: "قدم مكعب",
  },
  villa: "فيلا",
  flat: "مسطحة",
  Villa: "فيلا",
  offers: {
    title: "عروض",
    subtitle:
      "اختر ما يناسبك من بين آلاف الأماكن الرائعة مع الاستفادة من أحدث عروضنا وصفقاتنا. احصل على خصومات على عروض الفلاش والعروض الموسمية وغير ذلك الكثير. نتطلع إلى الترحيب بك في منازلنا",
    stayMore: "ابق أكثر ووفر أكثر",
    enjoy: "للاستمتاع بالمزايا",
    coupon: "(أسعار أرخص ، عروض خاصة ، و (كوبونات)",
    member: "لكونك عضوًا في Staycae",
    reg: "يسجل",
    login: "تسجيل الدخول",
    monthlyDeal: "العروض المتوفرة لهذا الشهر",
    flashDeal: "صفقات فلاش",
    seasonalOffer: "عروض موسمية",
    stayAndGet: "ابق واحصل على العروض",
    bankOffer: "عروض البنك",
    hurry: "عجلوا!",
    valid: "صالح حتى",
    expire: "ينتهي في",
    view: "عرض الإقامات",
    new: "صفقات جديدة",
    fromTxt: "ابق بين",
    toText: "و",
    losTxt1: "صالحة ل",
    losTxt2: "أو ليال أكثر من البقاء",
    losTxt3: "أو ليالي أكثر",
  },
  payment: {
    title: "قسط",
    booking: "ملخص الكتاب",
    cardNum: "رقم البطاقة",
    exp: "تاريخ الإنتهاء",
    cvc: "رمز التحقق من البطاقة (CVC)",
    pay: "ادفع الآن",
    timeRem: "الوقت المتبقي لإكمال الحجز الخاص بك",
    sorry: "عذرا ، لقد انتهت صلاحية نافذة الدفع الخاصة بك",
    bookDetails: "تفاصيل الحجز",
    adults: "الكبار",
    summary: "ملخص الدفع",
    nights: "ليالي",
    rooms: "غرف",
    service: "تكلفة الخدمة",
    disc: "عرض الخصم",
    coupon: "القسيمة المطبقة",
    vat: "ضريبة القيمة المضافة",
    tourismFee: "رسوم السياحة",
    total: "الإجمالي (شاملاً ضريبة القيمة المضافة)",
    night: "ليلة",
    totalAmt: ":الإجمالي",
    youSave: "أنت أحفظ",
    applyCoupon: "تطبيق القسيمة",
    success: "تم إنشاء حجزك بنجاح.",
    bookingRef: "مرجع الحجز: SYC-",
    print: "لطباعة فاتورة الحجز الخاصة بك",
    click: "انقر هنا",
    errorMsg: "لا يمكن إنشاء الفاتورة للحجوزات القابلة للاسترداد",
    dmgDeposit:
      "سيتم تحصيل وديعة الأضرار من 1000 إلى 5000 درهم إماراتي عند الوصول نقدًا / بالبطاقة",
  },
  toast: {
    pwdinvalid: "خطأ في اسم المستخدم أو كلمة مرور",
    resetsuccess: "تمت إعادة تعيين كلمة المرور بنجاح!",
    internalerror: "حدث خطأ داخلي. الرجاء معاودة المحاولة في وقت لاحق!",
    nomatchpwd: "كلمات المرور التي تم إدخالها غير متطابقة",
    invalidemail: "عنوان البريد الإلكتروني هذا غير متوفر",
    forgetpwdmailsent:
      "لقد أرسلنا رابط إعادة تعيين كلمة المرور لحساب بريدك الإلكتروني",
    usernotexist:
      "تعذر العثور على حساب لهذا البريد الإلكتروني. أدخل حسابًا مختلفًا",
    userexist: "المستخدم بنفس معرف البريد الإلكتروني موجود بالفعل",
    signupproceed:
      "قبل المتابعة ، يرجى التحقق من بريدك الإلكتروني للحصول على رابط التحقق",
    contactsuccess:
      "تم ارسال رسالتك بنجاح. يمكنك أيضًا الاتصال بنا من خلال وسائل التواصل الاجتماعي ، ويمكن العثور على الروابط أدناه!",
    resetlinkexpire:
      "عذرا ، لقد انتهت صلاحية رابط إعادة تعيين كلمة المرور الخاصة بك",
    wrongoldpwd: "كلمة المرور القديمة خاطئة",
    alreadyuser: "أنت بالفعل مشترك في Staycae.",
    general: "هناك خطأ ما",
    profileupdate: "تم تحديث الملف الشخصي بنجاح",
    verificationsent: "تم إرسال رسالة التحقق إلى حساب بريدك الإلكتروني",
    invalidpassword: "رمز مرور خاطئ",
    verificationsuccess: "التحقق من البريد الإلكتروني بنجاح",
    oldnewpwdsame:
      "لا يمكن أن تكون كلمة مرورك الجديدة هي نفسها كلمة مرورك الحالية",
    nodetails: "تفاصيل هذه الغرفة غير متوفرة حاليا",
    nocoupon: "القسيمة المختارة لم تعد متوفرة",
    unavailable: "الغرفة غير متاحة للتواريخ المحددة. يرجى ضبط التواريخ",
    verifyemail: "تم التحقق من معرف البريد الإلكتروني بالفعل",
    verifyphone: "تم التحقق من رقم الهاتف بنجاح",
    sendotp: "لقد أرسلنا التحقق إلى رقم هاتفك المحمول",
    failotp: "فشل في إرسال رمز التحقق. الرجاء معاودة المحاولة في وقت لاحق",
    expireotp: "فشل المصادقة. منتهي الصلاحية OTP",
    wrongotp: "تم إدخال OTP خاطئ. حاول مرة اخرى",
    nootp: "لم يتم بدء OTP لهذا البريد الإلكتروني",
    limitexceed: "تم تجاوز حد الاستياء. يرجى المحاولة بعد مرور بعض الوقت",
    dateinvalid: "التواريخ المحددة غير صالحة",
    bookingfail:
      "معذرة ، الوحدة المختارة لم تعد متوفرة. يرجى محاولة تاريخ أو وحدة أخرى",
    propsubmit: "تم تقديم تفاصيل الوحدة الخاص بك. سنتواصل معك قريبًا",
    propexist: "إدراج الوحدة مع هذا البريد الإلكتروني موجود بالفعل",
    success: "اكتمل العمل بنجاح",
    switchErr: "يرجى التحقق من بريدك الإلكتروني للتحويل إلى بوابة المالك",
    payFail: "عذرا ، فشل الدفع! الرجاء اعادة المحاولة.",
    invalidCard: "تفاصيل البطاقة غير صحيحة.",
    verifyMail:
      "يرجى التحقق من بريدك الإلكتروني لتسجيل الدخول إلى بوابة المالك",
    fileUpload: "يرجى تحميل ملف أقل من 2 ميغا بايت",
    slug: "سبيكة غير صالحة",
    couponAdded: "تم تطبيق القسيمة!",
    verifyEmail: "تحقق من بريدك الإلكتروني قبل المتابعة!",
    subscribed: "تم الاشتراك بنجاح!",
    driveFileError: "يرجى محاولة تحميل ملف من جهازك",
    driveImgError: "يرجى محاولة تحميل صورة من جهازك",
    bookingUpdateError: "لم يمر الحجز. سيتم إعادة أي أموال يتم خصمها.",
    userExistCheckout:
      "عنوان البريد الإلكتروني الذي أدخلته مستخدم. يرجى تسجيل الدخول باستخدام نفس عنوان البريد الإلكتروني لإكمال الحجز أو ادخال عنوان بريد إلكتروني مختلف.",
  },
  webCheckIn: {
    bannerHeading: "تسجيل وصول الضيف",
    toastMsg: {
      INVALID_BOOKING: "حجز غير صحيح",
      PRE_CHECK_IN_SUCCESSFUL:
        "شكرًا لك على إكمال تفاصيل تسجيل الوصول المسبق. تم إخطار فريقنا بوصولك. يرجى زيارة مكتب الاستقبال لدينا عند وصولك لإكمال عملية تسجيل الوصول والحصول على حق الوصول إلى الوحدة.",
      USER_NON_ASSOCIATED_BOOKING:
        "هذا الحجز غير مرتبط بهذا الحساب. يرجى التحقق من بيانات الاعتماد الخاصة بك وحاول مرة أخرى",
      PRE_CHECK_IN_ALREADY_COMPLETED:
        "لقد تمت معالجة تسجيل الوصول الخاص بك بالفعل!",
      GENERAL_ERROR: "هناك خطأ ما. يرجى التسجيل في وقت لاحق",
      FILE_NAME_TOO_LONG: "لا يمكن أن يتجاوز اسم الملف 100 حرف",
      EXPIRED_BOOKING: "انتهت صلاحية هذا الحجز",
      PRE_CHECK_IN_SUCCESSFUL_AND_VERIFICATION_MAIL_SENT:
        "قبل المتابعة ، يرجى التحقق من بريدك الإلكتروني للحصول على رابط التحقق",
    },
    additionalInfo: "معلومات إضافية عن وصولك",
    additionalInfoPlaceholder:
      "يرجى تحديد الوقت المتوقع للوصول وأي متطلبات أخرى",
    field: "هذه الخانة",
    btnText: "تحقق في",
    terms: "أفهم أنه سيُطلب مني ملء نموذج إقرار صحي وتقديمه عند وصولي",
    uaeUserText: "يرجى إرفاق هوية الإمارات الخاصة بك",
    nonUaeUserText: "يرجى إرفاق نسخة من جواز السفر أو التأشيرة",
    errorMsg: "يرجى تحديد الاختيارللمتابعة",
    noFile: "لم يتم تحميل أي ملف",
    chooseFile: "يرجى اختيار الملف",
    yes: "نعم",
    no: "لا",
    residentLabel: "هل انت مقيم في الامارات؟",
    imgRequired: "يرجى تحميل إثبات الهوية",
    imgSizeError: "يرجى تحميل ملف أقل من 2 ميغا بايت",
    cancellation: "يمكنك التحقق من سياسة الإلغاء لدينا هنا أو التواصل معنا على",
    highlight: "هنا",
  },
  profile: {
    personal: "معلومات شخصية",
    coupon: "كوبونات",
    bookings: "الحجوزات",
    wishlist: "قائمة الرغبات",
    booking: "الحجز",
  },
  bookings: {
    nobooking: "لا حجوزات متاحة!",
    past: "ماضي",
    coming: "القادمة",
    active: "نشيط",
    cancelled: "ألغيت",
    departed: "الراحل",
    arrived: "وصل",
    extend: "تمديد فترة الإقامة",
    bookagain: "احجز مرة أخرى",
    download: "عرض وتحميل الفاتورة",
    checkout: "موعد انتهاء الأقامة",
    checkin: "تاريخ الوصول",
    guests: "ضيوف",
    cancel: "الغاء",
    bookedon: "تم الحجز",
  },
  personalInfo: {
    title: "معلومات شخصية",
    verify: "تحقق الآن",
    verified: "تم التحقق",
    edit: "تحرير",
    cancel: "الغاء",
    save: "أحفظ",
    removePic: "إزالة الصورة",
    country: "دولة",
    city: "مدينة",
    changepwd: "تغيير كلمة المرور",
    currentpwd: "كلمة المرور الحالي",
    confirmPwd: "تأكيد كلمة المرور",
  },
  wishlist: {
    nolist: "لا توجد قائمة الرغبات المتاحة!",
    booknow: "احجز الآن",
  },
  coupons: {
    noCoupon: "لا كوبونات متاحة!",
    noCouponDesc:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diamnonumy eirmod",
    refer: "ارجع واربح",
    or: "( أو )",
    goHome: "اذهب للمنزل",
    disc: "خصم",
    get: "احصل على",
    offBook: "عند حجز منزل إقامتك المفضل.",
    valid: "صالح حتى",
    terms: "البنود و الظروف",
    title: "كوبونات",
    flat: "خصم ثابت",
    couponNote: "ملاحظة: لا يمكن تطبيق القسائم التي تزيد عن قيمة الحجز.",
  },
  checkout: {
    highlight: "مرحبًا ، بكونك عضوًا في Staycae ، احجز هذه الإقامة ب",
    grab: "احصل على هذا العرض على الفور؟",
    reg: "التسجيل أو تسجيل الدخول",
    cancel: "لا ، تابع كضيف",
    guestChk: "ضيف المحاسبة",
    checkTypo:
      "يرجى التحقق من الأخطاء المطبعية. سنرسل التأكيد والفاتورة إلى عنوان البريد الإلكتروني الذي تقدمه.",
    proceed: "تقدم",
    verify: "تحقق من رقم الهاتف",
    almostDone: "لقد اوشكت على الانتهاء!",
    otp: "OTP",
    otpExp: "انتهت صلاحية كلمة المرور لمرة واحدة",
    verifyText: "التحقق",
    noOtp: "لم تستلم OTP؟",
    resend: "إعادة إرسال",
    wait: "مهلا انتظر !!",
    waitDesc: "هل أنت متأكد أنك تريد إلغاء هذا الحجز؟",
    yes: "نعم",
    no: "لا",
    minNightStayText: "للحجز، يجب الإقامة لمدة {{101}} ليالٍ على الأقل." 
  },
  loyalty: {
    points: "نقطة",
    earned: "النقاط التي أحرزتها:",
    error: "أنت غير مسجل في برنامج الولاء الخاص بنا.",
  },
  resultCard: {
    others: "آخرون",
    showLess: "عرض أقل",
    forMem: "لأعضاء Staycae",
    nonMem: "غير الأعضاء",
  },
  guestExceed: {
    title: "تم الوصول إلى الحد الأقصى لعدد الأشخاص!",
    subtitle: "يجب ألا يزيد عدد الضيوف عن الحد الأقصى للأشخاص",
    continue: "تواصل على أي حال",
    skip: "تخطي في الوقت الراهن",
  },
  addPhone: {
    title: "أضف رقم الهاتف المتحرك",
    description: "أضف رقم هاتفك المحمول لإكمال عملية الحجز",
    save: "حفظ",
    cancel: "تخطي الآن",
  },
  privacyPolicy: {
    bannerHeading: "سياسة الخصوصية",
  },
};

export default ar;
