import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import './SocialMedia.scss';

const fbKey: string = process.env.REACT_APP_FACEBOOK_AUTH_KEY as string;
const gmailKey: string = process.env.REACT_APP_GMAIL_AUTH_KEY as string;

const SocialMediaWrapper: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const responseFacebook = (response: any) => {
    if (response.status === 'unknown') {
    } else {
      props.onSocialMediaBtnClick({ type: 'facebook', token: response.accessToken })
    }
  }

  const responseGoogle = (response: any) => {
    if (response.error) {
    } else {
      props.onSocialMediaBtnClick({ type: 'google', token: response.accessToken })
    }
  }

  return (
    <Fragment>
      <h2 className="login-info">{t("login.signup")} ? </h2>
      <div className="social-media-btn-wrapper p-0">
        <div className="column column-left">
          <FacebookLogin
            appId={fbKey}
            callback={responseFacebook}
            textButton="Facebook"
            icon="fab fa-facebook"
            cssClass="fb-btn"
            isMobile={false}
          />
        </div>
        <div className="column">
          <GoogleLogin
            clientId={gmailKey}
            render={renderProps => (
              <button className="gmail-btn" onClick={renderProps.onClick} disabled={renderProps.disabled}><i className="fa gmail-icon fa-google" aria-hidden="true"></i><span>Google</span></button>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default SocialMediaWrapper;
