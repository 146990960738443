import { Context } from "components/app/Wrapper";
import React, { useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const OwnerHeaderOption: React.FC<{ onClick: (status: boolean) => any }> = ({
  onClick,
}) => {
  const pathname = useLocation().pathname;
  const context: any = useContext(Context);

  useEffect(() => {
    context.selectLanguage('en');
  }, [context]);

  return (
    <>
      <NavLink
        className="navbar-item"
        activeClassName={pathname === "/owner" ? "is-active" : ""}
        to="/owner"
        onClick={(_) => onClick(false)}
      >
        Dashboard
      </NavLink>

      <NavLink
        className="navbar-item"
        activeClassName={pathname === "/owner/reservation" ? "is-active" : ""}
        to="/owner/reservation"
        onClick={(_) => onClick(false)}
      >
        Reservations
      </NavLink>

      <NavLink
        className="navbar-item"
        activeClassName={pathname === "/owner/report" ? "is-active" : ""}
        to="/owner/report"
        onClick={(_) => onClick(false)}
      >
        Report
      </NavLink>
      
      <NavLink
        className="navbar-item"
        activeClassName="is-active"
        to="/owner/add-property"
        onClick={(_) => onClick(false)}
      >
        Add Property
      </NavLink>
    </>
  );
};

export default OwnerHeaderOption;
