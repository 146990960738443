import React from "react";
import "./Rating.scss";
export interface RatingProps {
  ratings: number;
  type?: string
}
const Rating: React.FC<RatingProps> = ({ ratings ,type}) => {
  const rating = parseInt(ratings?.toLocaleString()) || 0;
  const halfStar = ratings - parseInt(ratings?.toLocaleString()) !== 0 && ratings - parseInt(ratings?.toLocaleString()) >= 0.5;
  let stars = [...Array(5)];
  
  return (
    <div>
      {stars.map((item, i) => {
        const  starBackGroundColor = i <= rating - 1
        ? "#f4b914"
        : halfStar && i === rating
        ? "url(#half_grad)"
        : `${type === 'stay-details'? '#d9d9d9' : 'white'}`;
        return (
          <svg height="24" viewBox="0 0 24 24" width="24" key={i}>
            <path d="M0 0h24v24H0z" fill="none" />
            <linearGradient id="half_grad">
              <stop offset="50%" stopColor="#f4b914" />
              <stop offset="50%" stopColor="white" stopOpacity="1" />
            </linearGradient>
            <path
              fill={starBackGroundColor}
              stroke={type === 'stay-details' && starBackGroundColor === '#d9d9d9' ? '#d9d9d9' : '#f4b914'}
              strokeWidth="1.5"
              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        );
      })}
    </div>
  );
};

export default Rating;
