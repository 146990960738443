export const OFFER_TYPE: { 
    [index: string]: string;
    flashDeal: string;
    seasonal: string;
    getFree: string;
    bankOffer: string; 
} = {
    flashDeal: "flash_deal",
    seasonal: "seasonal",
    getFree: "stay_and_get_free",
    bankOffer: "bank_offer"
};

export const OFFER_MAPPING: { 
    [index: string]: string;
} = {
    flash_deal: "offers.flashDeal",
    seasonal: "offers.seasonalOffer",
    stay_and_get_free: "offers.stayAndGet",
    bank_offer: "offers.bankOffer"
};
