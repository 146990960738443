import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";
import { HTTPMSG } from "config";
import { ListPropertyService } from "./list-property.service";
import { PropertyFormInputs } from "./components/PropertyForm/PropertyForm";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: ""
};

export const listPropertyDetails: any = createAsyncThunk(
  "listPropertyThunk",
  async (payload: PropertyFormInputs, { rejectWithValue }) => {
    try {
      const response: any = await ListPropertyService.listPropertyService(payload);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

const listPropertySlice = createSlice({
  name: "listProperty",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [listPropertyDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [listPropertyDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [listPropertyDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = HTTPMSG[payload.message || "GENERAL_SUCCESS"];
    },
  },
});

export const { clearState } = listPropertySlice.actions;
export const listPropertySelector = (state: RootState) => state.listProperty;
const listPropertyReducer = listPropertySlice.reducer;
export default listPropertyReducer;
