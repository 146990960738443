import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { ICheckInBookingDetails } from "features/check-in/components/check-in.interface";
import { get, post} from "services/api.service";
import { RootState } from "store/rootReducer";

const dataInit: ICheckInBookingDetails = {
  id: "",
  reservation_id: "",
  invoice_number: "",
  rate: "",
  daily_rate: "",
  vat: "",
  tourism_fee: "",
  service_charge: "",
  status: "",
  room_category: {
    room_category_id: "",
    name: "",
    sub_title: "",
    nice_name: "",
    description: "",
    max_occupants: 0,
    bedrooms: 0,
    bathrooms: 0,
    slug: "",
    image: null,
  },
  arrival_date: "",
  departure_date: "",
  booking_date: "",
  no_of_nights: 0,
  guests: 0,
  user: {
    user_id: "",
    name: "",
    email: "",
    phone_number: "",
  },
  payment_status: 0,
  transaction_id: "",
  property: "",
  coupon: null,
  coupon_discount: "",
  offer: {
    id: "",
    code: "",
  },
  offer_discount: "",
  stripe_refund_key: null,
  refund_status: null,
  is_refundable: false,
  is_cancellable: false,
  room: {
    id: "",
    name: "",
  },
};

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  isCheckinFetching: false,
  isCheckinSuccess: false,
  isCheckinError: false,
  successMessage: "",
  errorMessage: "",
  bookingDetails: dataInit,
  bookingId: ""
};

export const getBookingDetails: any = createAsyncThunk(
  "guest/bookingDetails",
  async (id: string, { rejectWithValue }) => {
    try {
      const response: any = await get(
        `${endPoints.WEB_CHECK_IN.BOOKING_DETAILS}?booking=${id}`
      );
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const webCheckIn: any = createAsyncThunk(
  "guest/webCheckIn",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await post(
        data,
        `${endPoints.WEB_CHECK_IN.CHECK_IN}`
      );
      return response.data;
    } catch (e: any) {
      let error = {};
      if(e?.response?.data) {
        error = e.response.data;
      } else {
        error = {
          message: 'DRIVE_FILE_ERROR'
        }
      }
      return rejectWithValue(error);
    }
  }
);

const guestWebCheckInSlice = createSlice({
  name: "guestWebCheckIn",
  initialState,
  reducers: {
    clearCheckInState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isCheckinError = false;
      state.isCheckinSuccess = false;
      state.isCheckinFetching = false;
      return state;
    },
    setBookingId: (state, { payload }) => {
      state.bookingId = payload;
    }
  },
  extraReducers: {
    [getBookingDetails.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
      state.isFetching = false;
      state.isError = true;
    },
    [getBookingDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [getBookingDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.bookingDetails = payload.data;
      state.isSuccess = true;
    },
    [webCheckIn.rejected]: (state, { payload }) => {
      state.errorMessage = payload?.message?.includes("_") ? payload?.message : "GENERAL_ERROR";
      state.isCheckinFetching = false;
      state.isCheckinError = true;
    },
    [webCheckIn.pending]: (state) => {
      state.isCheckinFetching = true;
      state.isCheckinError = false;
    },
    [webCheckIn.fulfilled]: (state, { payload }) => {
      state.isCheckinFetching = false;
      state.isCheckinSuccess = true;
      state.successMessage = payload?.message;
    },
  },
});

export const { clearCheckInState, setBookingId } = guestWebCheckInSlice.actions;
export const guestWebCheckInSelector = (state: RootState) =>
  state.guestWebCheckIn;
const guestWebCheckInReducer = guestWebCheckInSlice.reducer;
export default guestWebCheckInReducer;
