import React, { useEffect } from "react";
import UpArrow from "assets/icons/up-arrow.svg";

import "./ScrollToTop.scss";

const ScrollToTop: React.FC<{ refID: string }> = (props) => {

  useEffect(() => {
    function displayScrollIcon() {
      const scrollTop = document.getElementById("scroll-to-top"),
        currentPos = props.refID.trim() ? window.pageYOffset : window.scrollY;
      if (props.refID.trim()) {
        const scrollWRT = document.getElementById(props.refID),
          parentOffset: any = scrollWRT?.offsetHeight;
        if (currentPos >= parentOffset + 100) {
          scrollTop?.classList.add("show");
          scrollTop?.classList.remove("hide");
        } else {
          scrollTop?.classList.add("hide");
          scrollTop?.classList.remove("show");
        }
      } else {
        if (currentPos >= 500) {
          scrollTop?.classList.add("show");
          scrollTop?.classList.remove("hide");
        } else {
          scrollTop?.classList.add("hide");
          scrollTop?.classList.remove("show");
        }
      }
    };
    window.addEventListener("scroll", displayScrollIcon);
    return () => {
      window.removeEventListener("scroll", displayScrollIcon);
    };
  });

  return (
    <span
      className="scroll-to-top"
      id="scroll-to-top"
      title="Scroll to top"
      onClick={(_) => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <img src={UpArrow} alt="" />
    </span>
  );
};

export default ScrollToTop;
