import { API } from "api/api";
import { endPoints } from "api/endPoints";

const fetchCoupons = (req: any) => {
    return new Promise((resolve, reject) => {
        let queryParam = '';
        if(req.total) {
            queryParam = `?total_rate=${req.total}`
        }
        API.get(`${endPoints.PAYMENT.ACTIVE_COUPONS}${queryParam}`)
        .then((response) => resolve(response))
        .catch((error) => {
            console.log(error);
            reject(error);
        });
    });
}

export const CouponsService = {
    fetchCoupons: fetchCoupons
}
