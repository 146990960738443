import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import Modal from 'components/Modal/Modal';
import './cropper.scss';

interface CropperProps {
    active: boolean;
    src: string;
    style: {
        height: any;
        width: any;
        x: any;
        y: any
    }
    croppedImage: (data: any) => any;
    options: any;
}

const ImageCropper: React.FC<CropperProps> = ({ active, src, style, croppedImage,options }) => {
    const { t } = useTranslation();
    const [cropper, setCropper] = useState<any>();

    const onSave = () => {
        croppedImage({
            ...options,
            fileContent: cropper.getCroppedCanvas()?.toDataURL()
          })
    }

    const CropperComp = <Fragment>
        <Cropper
            src={src}
            style={{ height: style.height, width: style.width }}
            aspectRatio={style.x / style.y}
            guides={false}
            onInitialized={(instance: any) => {
                setCropper(instance)
            }}
        />
        <button className="save-btn is-link" onClick={onSave}>{t('personalInfo.save')}</button>
    </Fragment>

    const toggle = () => {
        croppedImage({ ...options, fileContent: ''})
    }

    return (
        <div className="image-crop">
            <Modal
                isShown={active}
                hide={toggle}
                modalContent={CropperComp}
                buttonName="Crop"
            />
        </div>
    )
}

export default ImageCropper;