import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { profile } from "features/profile/slices/profile.slice";
import { clearState, contactUs, contactUsSelector } from "features/contact-us/Contact.slice";

import './OwnerContact.scss';
import { setToastState } from "components/Toast/Toast.slice";
import { useForm } from "react-hook-form";
import TextArea from "components/TextArea/TextArea";

const OwnerContact: React.FC = () => {
    const { register, handleSubmit, formState: { errors }} = useForm();
    const dispatch = useAppDispatch();
    const { loading, data } = useAppSelector(profile);

    const sendData = (formData:any) => {
        dispatch(contactUs({
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
            phone_number: data.phone_number,
            message: formData.message
        }));
    }

    const { isFetching, isSuccess, isError, errorMessage, successMessage } =
        useAppSelector(contactUsSelector);

    useEffect(() => {
        if (isError) {
            dispatch(setToastState({ type: 'danger', message: errorMessage, dismiss: 3500 }));
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(setToastState({ type: 'success', message: "Your message has been sent successfully!", dismiss: 3500 }));
            dispatch(clearState());
        }
    }, [isError, isSuccess, dispatch, errorMessage, successMessage]);

    return <div className="contact-banner">
        <h3 className="title">Contact for help</h3>
        <div className="contacts">
            <a className="row" title={"Email"} href="mailto:reservations@staycae.ae">
                <i className="fa fa-envelope" aria-hidden="true"></i>
                <span>reservations@staycae.ae</span>
            </a>
            <a className="row" title={"Dial"} href="tel:+97142100450">
                <i className="fa fa-phone" aria-hidden="true"></i>
                <span>+971-42100450</span>
            </a>
        </div>

        <div className="form-wrapper">
            <h3 className="title mb-1">Message us your query</h3>
            <hr />
            <form>
                <TextArea
                    placeholder=""
                    label=""
                    register={register}
                    name="message"
                    errors={errors}
                    rules={{
                        required: true,
                        maxLength: 200
                    }}
                />
                <button className={`button btn ${loading || isFetching ? "is-disabled is-loading" : ""}`} onClick={handleSubmit(sendData)}>Send</button>
            </form>
        </div>
    </div>
}

export default OwnerContact;
