import { useState } from "react";
import SubSections from "../SubSections";

interface IAmenities {
  amenities: [];
  name?: string;
}
export const Amenities: React.FC<IAmenities> = (props) => {
  const [isAmenities, showAmenities] = useState(false);
  const [countAmenities, updateCount] = useState(3);
  const count = props.amenities.length;
  const amenitiesOther = () => {
    return props.amenities.length - 3;
  };
  const showAllAmentities = (show: boolean, count: number) => {
    showAmenities(show);
    updateCount(count);
  };
  return (
    <SubSections
      title={props.name}
      subtitle="Free Wi-Fi  |  Pool  |  AC  |  Kitchen  |  TV"
      others="+10 0thers"
    >
      {props.amenities?.map(
        (item, index) =>
          index < countAmenities && (
            <span key={index}>
              <span>{item}</span>
              {count > 1 && count - 1 !== index && (
                <span className="other-bar"> | </span>
              )}
            </span>
          )
      )}
      {!isAmenities && props.amenities.length > 3 ? (
        <span className="others" onClick={() => showAllAmentities(true, 20)}>
          +{amenitiesOther()} Others
        </span>
      ) : !isAmenities && props.amenities.length <= 3 ? (
        <></>
      ) : (
        <span
          className="others"
          style={{ marginLeft: "5px" }}
          onClick={() => showAllAmentities(false, 3)}
        >
          Show Less
        </span>
      )}
    </SubSections>
  );
};
