import { API } from "api/api";

const config = {
  headers: { 'Content-Type': 'application/pdf' },
  responseType: 'arraybuffer'
};


export const post = (reqdata: any, endPoint: string) => {
  const body = reqdata;
  return new Promise((resolve, reject) => {
    API.post(endPoint, body)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const get = (endPoint: string) => {
  return new Promise((resolve, reject) => {
    API.get(endPoint)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error);
        reject(error);
      })
  });
}

export const put = (reqdata: any, endPoint: string) => {
  const body = reqdata;
  return new Promise((resolve, reject) => {
    API.put(endPoint, body)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const download = (endPoint: string) => {
  return new Promise((resolve, reject) => {
    API.get(endPoint, config as any)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      })
  });
}

export const formattedPostResponse = (endPoint: string, data:any , responseType: "blob" | "arraybuffer" | "document" | "json" | "stream" | "text") => {
  return new Promise((resolve, reject) => {
    API.post(endPoint, data, { responseType: responseType })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error);
        reject(error);
      })
  });
}
