import React, { FunctionComponent, useEffect, useState } from "react";
import moment from "moment";

import BarChartGraph from "./components/BarChart/BarChartGraph";
import RadialChart from "./components/radialChart/RadialChart";
import PieChartCard from "./components/pieChart/PieChart";
import OccupancyCard from "./components/occupancyCard/OccupancyCard";
import TableCard from "./components/tableCard/TableCard";
import MultiDatePicker, {
  IDateRange,
} from "./components/multiDatePicker/MultiDatePicker";
import { useAppDispatch, useAppSelector } from "store/store";
import { getData, getDataValue } from "./slices/getData.slice";
import {
  getDateDataValue,
  getDateDependData,
} from "./slices/getDateData.slice";
import { initialOccupancyChartData, initialViewData } from "./data";
import MultiSelect from "components/multi-select/MultiSelect";
import { multiSelectSelector } from "components/multi-select/multi-select.slice";
import {
  getAnalyticsData,
  getAnalyticsValue,
} from "./slices/getAnalytics.slice";
import {
  getMaintenanceData,
  getMaintenanceDataValue,
} from "./slices/getMaintenaceData.slice";
// import { setToastState } from "components/Toast/Toast.slice";

import DashboardCard from "./components/Card/DashboardCard";
import earnedIcon from "assets/icons/earned.svg";
import reservationIcon from "assets/icons/reservation.svg";
import unitIcon from "assets/icons/units.svg";
import revenueIcon from "assets/icons/revenue.svg";
import "./Dashboard.scss";

const Dashboard: FunctionComponent = () => {
  const [initialLoaded, setInitialLoading] = useState(false);
  const [selectedDateVal, setSelectedDate] = useState({});
  const [dateError, setDateError] = useState(false);
  const [pieData, setPieData] = useState([]);
  const dispatch = useAppDispatch();
  const { data: unitData, loading: unitLoading } = useAppSelector(getDataValue);
  const { data: analyticsData } = useAppSelector(getAnalyticsValue);
  const { options } = useAppSelector(multiSelectSelector);
  const { data, loading } = useAppSelector(getDateDataValue);
  const [viewsData, setViewData] = useState<any>(initialViewData);
  const [occupancyData, setOccupancyData] = useState<any>(
    initialOccupancyChartData
  );
  const { data: maintenanceData, loading: maintenanceLoading } = useAppSelector(
    getMaintenanceDataValue
  );

  useEffect(() => {
    if (data) {
      data?.occupancy_status && generateOccupancyChartData();
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (maintenanceData) {
      formatData();
    }
    // eslint-disable-next-line
  }, [maintenanceData]);

  const formatData = () => {
    const formattedData = maintenanceData.map((item: any) => {
      return { ...item, pieData: Number(item.value) * -1 };
    });
    setPieData(formattedData);
  };

  useEffect(() => {
    return () => {
      setInitialLoading(false);
    };
  }, []);

  useEffect(() => {
    generateAverageViewsData();
    // eslint-disable-next-line
  }, [analyticsData, data]);

  const getSelectedDates = (date: IDateRange) => {
    setSelectedDate(date);
    if (moment(date.from) > moment(date.to)) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const generateAverageViewsData = () => {
    setViewData([
      {
        name: `${data?.view_stats?.views_count || 0} Room Category Views`,
        uv: data?.view_stats?.views_count || 20,
        fill: "#3B62AC",
      },
      {
        name: `${analyticsData?.site_views || 0} Website Visitors`,
        uv: analyticsData?.site_views || 20,
        fill: "#71CEF7",
      },
    ]);
  };

  const generateOccupancyChartData = () => {
    const {
      total_reservations,
      total_nights,
      longer_stay_reservations,
      shorter_stay_reservations,
    } = data?.occupancy_status;

    setOccupancyData([
      ["Type", "", { role: "style" }, { role: "annotation" }],
      [
        "Total Reservations",
        total_reservations || 0,
        "color: #F05C24",
        `${total_reservations || 0} Reservations`,
      ],
      [
        "Longer Stay Reservations",
        longer_stay_reservations || 0,
        "color: #42B9E6",
        `${longer_stay_reservations || 0} Reservations`,
      ],
      [
        "Shorter Stay Reservations",
        shorter_stay_reservations || 0,
        "color: #57B95A",
        `${shorter_stay_reservations || 0} Reservations`,
      ],
      [
        "Total Nights Occupied",
        total_nights || 0,
        "color: #EE4A85",
        `${total_nights || 0} Nights`,
      ],
    ]);
  };

  useEffect(() => {
    options.length &&
      Object.keys(selectedDateVal).length &&
      !initialLoaded &&
      onSearch();
    // eslint-disable-next-line
  }, [options.length, selectedDateVal]);

  const onSearch = () => {
    if (!dateError && Object.keys(selectedDateVal).length && options.length) {
      dispatch(getData({ units: options.map((unit) => unit.id) }));

      dispatch(
        getDateDependData({
          ...selectedDateVal,
          units: options.map((unit) => unit.id),
        })
      );

      dispatch(
        getMaintenanceData({
          ...selectedDateVal,
          units: options.map((unit) => unit.id),
        })
      );

      dispatch(getAnalyticsData(selectedDateVal));
      setInitialLoading(true);
    }
    // else if (dateError) {
    //   dispatch(
    //     setToastState({
    //       type: "danger",
    //       message: "Please select valid from date",
    //       dismiss: 3500,
    //     })
    //   );
    // }
  };

  const renderStatus = (type: any) => {
    const tagStyle =
      type === "Pencil"
        ? "yellow"
        : type === "Confirmed"
        ? "green"
        : type === "Cancelled"
        ? "red"
        : type === "Arrived"
        ? "dark-green"
        : "paleYellow";
    return <span className={`tag ${tagStyle}`}>{type}</span>;
  };

  const columns = [
    {
      Header: "ID",
      accessor: "reservation_id", // accessor is the "key" in the data
    },
    {
      Header: "Unit Name",
      accessor: "room.name",
    },
    {
      Header: "Check-in Date",
      accessor: "arrival_date",
      Cell: (props: any) => (
        <span>
          {props.value ? moment(props.value).format("YYYY-MM-DD") : "-"}
        </span>
      ),
    },
    {
      Header: "Check-out Date",
      accessor: "departure_date",
      Cell: (props: any) => (
        <span>
          {props.value ? moment(props.value).format("YYYY-MM-DD") : "-"}
        </span>
      ),
    },
    {
      Header: "Date of Booking",
      accessor: "booking_date",
      Cell: (props: any) => (
        <span>
          {props.value ? moment(props.value).format("YYYY-MM-DD") : "-"}
        </span>
      ),
    },
    {
      Header: "Number of Nights",
      accessor: "length_of_stay",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props: any) => <span>{renderStatus(props.value)}</span>,
    },
    {
      Header: "Amount (AED)",
      accessor: "amount",
    },
  ];

  const [isRevenueEqual, setIsRevenueEqual] = useState(false);

  useEffect(() => {
    setIsRevenueEqual(
      !unitLoading &&
        unitData?.revenue?.gross_revenue > "0" &&
        unitData?.revenue?.gross_revenue === unitData?.revenue?.nett_revenue
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitData?.revenue?.gross_revenue]);

  return (
    <div className="dashboard-main">
      <div className="db-card-wrap">
        <DashboardCard
          loading={unitLoading}
          title={unitData?.units_count || 0}
          total="Total Units"
          iconUrl={{ icon: unitIcon, color: "#ee4a85" }}
        />

        <DashboardCard
          loading={unitLoading}
          title={unitData?.reservation_count || 0}
          total="Reservations"
          showLast={true}
          iconUrl={{ icon: reservationIcon, color: "#3b62ac" }}
        />

        <DashboardCard
          loading={unitLoading}
          price={true}
          title={unitData?.revenue?.gross_revenue || 0}
          showLast={true}
          total="Gross Revenue"
          iconUrl={{ icon: revenueIcon, color: "#41b9e6" }}
          percentage={unitData?.revenue?.gross_growth_or_dip || 0}
        />

        <DashboardCard
          showInfo={true}
          price={isRevenueEqual ? false : true}
          loading={unitLoading}
          title={
            isRevenueEqual
              ? "Statement under process"
              : unitData?.revenue?.nett_revenue || 0
          }
          showLast={true}
          total="Earned Income"
          iconUrl={{ icon: earnedIcon, color: "#57b95a" }}
          percentage={unitData?.revenue?.nett_growth_or_dip || 0}
          isRevenueEqual={isRevenueEqual}
        />
      </div>

      <div className="filter-wrap">
        <div className="field-wrapper">
          <MultiDatePicker selectedDate={getSelectedDates} />
        </div>

        <div className="field-wrapper multiselect-units">
          <span className="mr-3">Units: </span>{" "}
          <MultiSelect placeholder="Select Units" />
          <button
            className={`search-btn  ${dateError ? "is-disabled" : ""}`} // disable if date range is invalid
            onClick={onSearch}
          >
            Search
          </button>
        </div>
      </div>

      <div className="db-rating">
        {selectedDateVal && (
          <BarChartGraph
            loading={loading}
            data={data?.reservation_stats}
            filterDate={selectedDateVal}
          />
        )}
        <RadialChart loading={loading} data={viewsData} />
      </div>

      <div className="db-rating">
        <OccupancyCard loading={loading} data={occupancyData} />
        <PieChartCard loading={maintenanceLoading} data={pieData} />
      </div>

      <div className="db-rating">
        {data?.recent_reservations && (
          <TableCard
            config={columns}
            tableData={data?.recent_reservations}
            dateFilter={selectedDateVal}
            unitsFilter={options.map((unit) => unit.id)}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
