export const formattedTitle = (
  title: string,
  highlight: string,
  titleStyle: string,
  highlightStyle: string,
  addDot: boolean
) => {
  if (highlight && highlight.length > 0 && title.toLowerCase().includes(highlight.toLowerCase())) {
    let startIndex = title.toLowerCase().indexOf(highlight.toLowerCase());
    let endIndex = title.toLowerCase().indexOf(highlight.toLowerCase()) + highlight.toLowerCase().length;

    return (
      <p className="ellipsis">
        <span className={`${titleStyle}`}>{title.substring(0, startIndex)}</span>
        <span className={`${highlightStyle}`}>{title.substring(startIndex, endIndex)}</span>
        <span className={`${titleStyle}`}>{title.substring(endIndex, title.length)}{addDot && !title.includes('.') ? '.' : null}</span>
      </p>
    );
  }
  return (
    title ? <p className="ellipsis"><span className={`${titleStyle}`}>{title}{addDot && !title.includes('.') ? '.' : null}</span></p> : null
  );
};
