import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";

const initialDataState: any[] = [];
export interface MultiSelectState {
    options: any[];
}

export const initialState: MultiSelectState = {
    options: initialDataState
};

const multiSelectSlice = createSlice({
    name: "multiSelect",
    initialState,
    reducers: {
        updateSelection: (state, { payload }) => {
            state.options = payload;
        },
        resetAll: (state) => {
            state.options = [];
        }
    }
});

export const { updateSelection, resetAll } = multiSelectSlice.actions;
export const multiSelectSelector = (state: RootState) => state.multiSelect;
const multiSelectReducer = multiSelectSlice.reducer;
export default multiSelectReducer;
