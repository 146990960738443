import { useContext } from "react";
import { Context } from "components/app/Wrapper";

export const classSwitch = (defaultValue: string) => {
   
    const contDir = localStorage.getItem('contentDir');

    const replaceObj: { [key: string]: string } = { r: 'l', right: 'left', l: 'r', left: 'right' };
   
    if (JSON.parse(contDir || "null") === "rtl") {
        return defaultValue.replace(/r|right|l|left/gi, obj => replaceObj[obj]);
    } else {
        return defaultValue;
    }
}

export const DirectionCheck = () => {
    const context: any = useContext(Context);
    if (context.locale === "ar") {
        return "rtl";
    } else {
        return "ltr";
    }
}