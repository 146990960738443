import React, { FunctionComponent } from 'react';
import './occupancy.scss';
import { Chart } from "react-google-charts";
import SearchSkeleton from 'features/search-property/components/SearchSkeleton/SearchSkeleton';

const OccupancyCard: FunctionComponent<any> = ({ data, loading }) => {
    return (
        <div className="occupancy-card">
            <h4>Occupancy Stats</h4>
            <div className="chart-details">Number of guest reservations and total nights occupied</div>
            {loading ? <SearchSkeleton /> :
                <>
                    <div className="chart-container">
                        <Chart
                            chartType="BarChart"
                            width={"100%"}
                            height={'400px'}
                            data={data}
                            options={{
                                vAxis: {
                                    minValue: 1.0,
                                    viewWindow: {
                                        min: 0
                                    },
                                    baseline: 0
                                },
                                hAxis: {
                                    minValue: 1.0,
                                    viewWindow: {
                                        min: 0
                                    },
                                    baseline: 0
                                },
                                legend: 'none',
                                bar: { groupWidth: "25%" },
                                chartArea: { width: '30%' },
                                displayAnnotations: true,
                                bars: 'horizontal',
                            }}
                        />

                    </div>
                    <div className="legend">
                        <div>
                            <span className="square short">20 days and below are short stays</span>
                        </div>
                        <div>
                            <span className="square long">21 days and above are long stays</span>
                        </div>
                    </div>
                </>
            }
        </div>
    )

}

export default OccupancyCard;