import TextField from "components/Inputs/TextField";
import TextArea from "components/TextArea/TextArea";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useAppDispatch, useAppSelector } from "store/store";
import { profile } from "features/profile/slices/profile.slice";
import { useTranslation } from "react-i18next";
import './PropertyForm.scss';
import useClickOutside from "hooks/useClickOutside";
import { clearState, listPropertyDetails, listPropertySelector } from "features/list-property/list-property.slice";
import { setToastState } from "components/Toast/Toast.slice";
import ReCAPTCHA from 'react-google-recaptcha';
import TagManager from 'react-gtm-module';

export type PropertyFormInputs = {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    address: string;
    property_name: string;
    property_type: string;
    square_feet: number | string;
    no_of_bedrooms: number | string;
    no_of_bathrooms: number | string;
};

const PropertyForm: React.FC = () => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues
    } = useForm<PropertyFormInputs>();

    const [isSubmitted, setSubmitted] = useState(false);
    const [isSubmit, setSubmit] = useState(false);
    const [phone, setPhone] = useState('');
    const [profileDropDownActive, setProfileDropDownStatus] = useState(false);
    const [dropdownText, setDropdownText] = useState("Villa");

    const node = React.useRef<HTMLDivElement>(null);
    useClickOutside(node, () => { setProfileDropDownStatus(false); });
    
    const { data:userData, loading } = useAppSelector(profile);

    const dispatch = useAppDispatch();
    const { isFetching, isSuccess, isError, successMessage, errorMessage } = useAppSelector(listPropertySelector);
    
    const recaptchaRef: any = React.createRef();
    const [captcha, setCaptcha] = useState();
    
    useEffect(() => {
        if(userData) {
            setValue("first_name", userData.first_name);
            setValue("last_name", userData.last_name);
            setValue("email", !userData.is_email_verified && userData.is_social_media_login ? ''  : userData.email);
            setPhone(userData.phone_number || '');
            setValue("property_type", "villa");
        }
    }, [userData, setValue]);

    useEffect(() => {
        if (isError) {
          dispatch(
            setToastState({ type: "danger", message: errorMessage, dismiss: 3500 })
          );
          dispatch(clearState());
          recaptchaRef.current.reset();
        }
        // eslint-disable-next-line
      }, [isError, dispatch, errorMessage]);
    
      useEffect(() => {
        if (isSuccess) {
            dispatch(
                setToastState({
                    type: "success",
                    message: successMessage,
                    dismiss: 3500,
                })
            );
    
            const tagManagerArgs = {
                gtmId: process.env.REACT_APP_GMT_ID || '',
                dataLayer: {
                    Property_name: getValues("property_name"),
                    Property_type: dropdownText,
                    Property_bedrooms: getValues("no_of_bedrooms"),
                    Property_bathrooms:  getValues("no_of_bathrooms")
                },
                events: {
                    event: 'conversion_owner'
                }
            }
    
            TagManager.initialize(tagManagerArgs);
            
            dispatch(clearState());
            // Reset form values
            if(userData) {
                setValue("first_name", userData.first_name);
                setValue("last_name", userData.last_name);
                setValue("email", !userData.is_email_verified && userData.is_social_media_login ? ''  : userData.email);
                setPhone(userData.phone_number);
            }
            else {
                setValue("first_name", "");
                setValue("last_name", "");
                setValue("email", "");
                setPhone('+971');
            }
            setValue("address", "");
            setValue("no_of_bathrooms", "1");
            setValue("no_of_bedrooms", "1");
            setValue("phone_number", "");
            setValue("property_name", "");
            setValue("square_feet", "");
            setValue("property_type", "villa");
            recaptchaRef.current.reset();
        }
        // eslint-disable-next-line
      }, [isSuccess, dispatch, successMessage]);
    
    const onSubmit: SubmitHandler<PropertyFormInputs> = (data) => {
        setSubmitted(true);
        if (captcha && phone?.length >= 10 && phone?.length < 15) {
            const { first_name, last_name, email, ...rest } = data;
            let formData = {
                owner_name: `${userData?.first_name || first_name} ${userData?.last_name || last_name}`, 
                ...rest,
                phone_number: phone,
                email: email || userData?.email
            };
            !Object.keys(formData).includes("property_type") && (formData = {...formData, property_type: "villa"})
            dispatch(listPropertyDetails(formData));
            setSubmitted(false);
        }
    }

    const dropdownValue = (option:string) => {
        setDropdownText(`${option[0].toUpperCase()}${option.slice(1)}`);
        setValue("property_type", option.toLocaleLowerCase());
        setProfileDropDownStatus(false);
    }

    const captchaChange = (value: any) => {
        setCaptcha(value);
    }

    return <div className="property-form spacing">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="form-heading mb-4">  {t('beHost.personal')}</div>
            <div className="row mt-0">
                <TextField
                    label={t('register.firstname')}
                    placeholder={t('register.firstname')}
                    register={register}
                    name="first_name"
                    type="text"
                    errors={errors}
                    rules={{
                        required: true,
                        maxLength: 50,
                        minLength: 1
                    }}
                    disabled={userData?.first_name ? true : false}
                    defaultValue={userData?.first_name || ""}
                />

                <TextField
                    label={t('register.lastname')}
                    placeholder={t('register.lastname')}
                    register={register}
                    name="last_name"
                    type="text"
                    errors={errors}
                    rules={{
                        required: true,
                        maxLength: 50,
                        minLength: 1
                    }}
                    disabled={userData?.last_name ? true : false}
                    defaultValue={userData?.last_name || ""}
                />
            </div>

            {/* Email should be prefilled */}
            <div className="email-field">
                <TextField
                    label={t('common.email')}
                    placeholder={t('common.email')}
                    register={register}
                    name="email"
                    type="email"
                    errors={errors}
                    rules={{
                        required: true,
                        pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    }}
                    disabled={userData?.is_email_verified ? true : false}
                    defaultValue={userData?.email || ""}
                />
            </div>

            <div className={`field text-field ${userData?.phone_number ? 'is-disabled phone-input': ''}`} style={{marginTop: "2.5rem"}}>
                <PhoneInput
                    containerClass={`${(phone?.length < 4 && isSubmit) ? 'apply-error' : ''} 
                    ${(phone?.length > 4 && (phone?.length < 10 || phone?.length >=15)
                            && isSubmit) ? 'apply-error' : ''}`}
                    inputClass={`input phone-no ${userData?.phone_number ? 'property-ph': ''}`}
                    countryCodeEditable={false}
                    country={'ae'}
                    value={phone}
                    onChange={(phone: any) => setPhone(phone as any)}
                    placeholder={t('footer.contact.phone')}
                    specialLabel={t('register.phone')}
                    preferredCountries={['ae']}
                    inputProps={{
                        name: 'phone',
                        required: true
                    }}
                    defaultMask='..................'
                    disabled={userData?.phone_number ? true: false}
                    // disableDropdown={userData?.phone_number ? true: false}
                />
                {phone?.length < 4 && isSubmit && <p className="help is-danger">{t('errmsg.phonerequire')}</p>}
                {phone?.length > 4 && (phone?.length < 10 || phone?.length >=15)
                    && isSubmit && <p className="help is-danger">{t('errmsg.phoneinvalid')}</p>}
            </div>

            <div className="form-heading mb-4 mt-4">  {t('beHost.property')}</div>
            <TextField
                label={t('beHost.propName')}
                placeholder={t('beHost.propName')}
                register={register}
                name="property_name"
                type="text"
                errors={errors}
                rules={{
                    required: true
                }}
            />
          
            <div className="row mt-0 dropdown-type">
                <div
                    className={`dropdown ${profileDropDownActive ? "is-active": ""}`}
                    ref={node as any}
                >
                    <div className="dropdown-trigger">
                        <button
                            className="img-btn"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu3"
                            type="button"
                            onClick={() => setProfileDropDownStatus(!profileDropDownActive)}
                        >
                            {dropdownText !== "Property Type" ? <span className="option-label">{t('beHost.proType')}</span> : null}

                            {dropdownText === "Property Type" ? t(dropdownText) : <span className="option">{t(dropdownText)}</span>}   
                            {!profileDropDownActive ? <i className="fas fa-caret-down"></i>: <i className="fas fa-caret-up"></i>}
                        </button>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu3" role="menu" style={{left: "unset", width: "100%"}}>
                        <div className="dropdown-content">
                            <button className="dropdown-item logout" onClick={_ => dropdownValue(t("villa"))} type="button">
                                <div className="dropdown-link-item">
                                    <span>{t('beHost.villa')}</span>
                                </div>
                            </button>
                            <button className="dropdown-item logout" onClick={_ => dropdownValue(t("flat"))} type="button">
                                <div className="dropdown-link-item">
                                    <span>{t('beHost.flat')}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                <TextField
                    label={t('beHost.sqrfeet')}
                    placeholder={t('beHost.sqrfeet')}
                    register={register}
                    name="square_feet"
                    type="number"
                    errors={errors}
                    rules={{
                        required: true,
                        min: 50,
                        max: 2000000000
                    }}
                    min={50}
                    max={2000000000}
                />
            </div>

            <div className="row mt-0">
                <TextField
                    label={t('common.numberBed')}
                    placeholder={t('common.numberBed')}
                    register={register}
                    name="no_of_bedrooms"
                    type="number"
                    errors={errors}
                    rules={{
                        required: true,
                        min: 1,
                        max: 10
                    }}
                    min={1}
                    max={10}
                    defaultValue={"1"}
                />

                {/* Should be dropdown */}
                <TextField
                    label={t('common.numberBath')}
                    placeholder={t('common.numberBath')}
                    register={register}
                    name="no_of_bathrooms"
                    type="number"
                    errors={errors}
                    rules={{
                        required: true,
                        min: 1,
                        max: 10
                    }}
                    min={1}
                    max={10}
                    defaultValue={"1"}
                />
            </div>

            <TextArea
                label={t('beHost.addr')}
                placeholder={t('beHost.addr')}
                register={register}
                name="address"
                errors={errors}
                rules={{
                    required: true,
                    maxLength: 200
                }}
            />

            <div className="field text-area mt-2">
                <ReCAPTCHA
                    ref={recaptchaRef as any}
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}
                    onChange={captchaChange}
                />
            </div>
            {!captcha && isSubmitted && (
                <p className="help is-danger mb-4 error-msg">{t('errmsg.human')}</p>
            )}

            <div className="field is-grouped">
                <div className="control">
                    <button
                        className={`button is-link send-btn ${loading || isFetching ? "is-loading is-disabled" : ""}`}
                        onClick={() => { setSubmit(true) }}
                        type="submit"
                    >
                  {t('beHost.submit')}
                    </button>
                </div>
            </div>
      </form>
    </div>
}

export default PropertyForm;
