import React, { FunctionComponent } from 'react';
import './Modal.scss';
import close from 'assets/images/modal-close.png';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    modalContent: JSX.Element;
    buttonName?: string;
    title?: string;
    hideCloseBtn?: boolean;
    subTitle?: string;
}

const Modal: FunctionComponent<ModalProps> = ({
    isShown,
    title,
    subTitle,
    hide,
    modalContent,
    buttonName,
    hideCloseBtn
}) => {

    const closeModal = () => {
        hide();
    }

    return (
        <div className={`modal ${isShown && 'is-active'}`}>
            <div className="modal-background background-banner"></div>
            <div className="modal-card modal-window">
                <header className={`modal-card-head modal-header ${hideCloseBtn ? 'less-padding' : ''} `}>
                <p className={`modal-card-title card-title ${hideCloseBtn ? 'heading-center' : ''}`}>{title}</p>
                    {!hideCloseBtn && <button className="close-btn" onClick={closeModal}><img src={close} alt="close" /></button> }
                </header>
                <section className="modal-card-body mod-content">
                    <div className='sub-title'> {subTitle}</div>
                    {modalContent}
                </section>
            </div>
        </div>
    )

}

export default Modal;