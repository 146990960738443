import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";

export const initialState = {
  isAboutFetched: false,
  about_us: {
    maintitle: "",
    maintitledesc: "",
    subcontentlistdesc1: "",
    subcontentlistdesc2: "",
    subcontentlistdesc3: "",
    subcontentlistdesc4: "",
    subcontentlisttitle1: "",
    subcontentlisttitle2: "",
    subcontentlisttitle3: "",
    subcontentlisttitle4: "",
    subcontenttitle1: "",
    subcontenttitle1desc1: "",
    subcontenttitle1desc2: "",
    subcontenttitle1highlight: "",
    subcontenttitle2: "",
    subcontenttitle2desc: "",
    subcontenttitle2highlight: "",
    subcontenttitle3: "",
    subcontenttitle3Desc1: "",
    subcontenttitle3Desc2: "",
    subcontenttitle3highlight: ""
  },
  meta: {
    content: "",
    keyWord: "",
    name: ""
  }
};

export const aboutUsData: any = createAsyncThunk(
  "aboutUs",
  async () => {
    const rsp: any = await get((endPoints.STATIC.ABOUT_US));
    return {
      data: rsp.data.data
    };
  }
);
const aboutUsSlice = createSlice({
  name: "aboutUs",
  initialState,
  reducers: {
  },
  extraReducers: {
    [aboutUsData.pending]: (state, action) => {
      state.isAboutFetched = false;
    },
    [aboutUsData.fulfilled]: (state, action) => {
      state.isAboutFetched = true;
      const { data } = action.payload;
      state.about_us = data.data;
      state.meta = data.meta;
    },
    [aboutUsData.rejected]: (state, action) => {
      state.isAboutFetched = true;
      state.about_us = initialState.about_us;
      state.meta = initialState.meta;
    },
  },
});

export const aboutUsSelector = (state: RootState) => state.aboutUs;
const aboutUsReducer = aboutUsSlice.reducer;
export default aboutUsReducer;
