import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import "features/search-property/SearchProperty.scss";
import SearchDropDown from "./Search-DropDown/SearchDropDown";
import { useAppDispatch, useAppSelector } from "store/store";
import guest from "assets/icons/search/guest.svg";
import AddGuest from "../../AddGuest/AddGuest";
import {
  currentlyActive,
  updateSelection,
  searchSelector,
} from "features/search-property/searchproperty.slice";

import {
  guestSelector,
} from "../../AddGuest/addguest.slice";
import useClickOutside from "hooks/useClickOutside";
import { Context } from "components/app/Wrapper";

const AddGuestDropDown: React.FC = () => {
  const context: any = useContext(Context);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isNowActive } = useAppSelector(searchSelector);
  const { adults, children, infants } = useAppSelector(guestSelector);
  const [show, setAddGuesst] = useState(false);
  const node = React.useRef<HTMLDivElement>(null);
  const toggle = () => {
    setAddGuesst(!show);
  }
  useEffect(() => {
    if (show) {
      dispatch(currentlyActive("GUESTS"));
    } else {
      dispatch(currentlyActive(""));
    }
  }, [show, dispatch, context.locale]);

  useClickOutside(node, () => { setAddGuesst(false); })
  useEffect(() => {
    dispatch(
      updateSelection({
        category: "currentGuests",
        value: {
          adult: adults
        },
        master: "guests",
      })
    );
  }, [adults, children, infants, dispatch, context.locale]);
  const guestSelected = () => {
    if (adults !== 0) {
      return `${adults} ${t('search.guests')}`;
    }
    return "";
  };
  return (
    <div ref={node as any}>
      <SearchDropDown
        name="GUESTS"
        title={t('search.for')}
        onClick={toggle}
        toggleView={isNowActive}
        subtitle={guestSelected() || t('search.guests')}
        image={guest}
        contentClass="guest-dropdown n-calendar guest-dd"
        selectedValue={guestSelected()}
      >
        <AddGuest></AddGuest>
      </SearchDropDown>
    </div>
  );
};

export default AddGuestDropDown;
