import { IOwnerPropertyList } from "features/owner/owner.interface";
import { ownerProperties, ownerSelector } from "features/owner/owner.slice";
import useClickOutside from "hooks/useClickOutside";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { initialState, unitsSingleSelector, updateUnitSelection } from "./units-dropdown.slice";

import "../multi-select/MultiSelect.scss";

interface ISingleSelect {
    placeholder: string;
}

const UnitsDropdown: React.FC<ISingleSelect> = ({ placeholder }) => {
    const [toggle, setToggle] = useState(false);
    const [searchText, setSearchText] = useState("");
    const node = React.useRef<HTMLDivElement>(null);
    useClickOutside(node, () => { setToggle(false); });
    const dispatch = useAppDispatch();

    const { options: selectedUnit } = useAppSelector(unitsSingleSelector);
    const { data, isFetching, isSuccess } = useAppSelector(ownerSelector);

    useEffect(() => {
        dispatch(ownerProperties());
    }, [dispatch])


    const addOrRemoveUnits = (unit: IOwnerPropertyList, action: "add" | "remove") => {
        if (action === "add") {
            dispatch(updateUnitSelection(unit));
        } else {
            dispatch(updateUnitSelection(initialState.options));
        }
    }

    const optionSelection = (unit: IOwnerPropertyList) => selectedUnit.id !== unit.id ? addOrRemoveUnits(unit, "add") : addOrRemoveUnits(unit, "remove");

    const tagSelection = (unit: IOwnerPropertyList, e: any) => {
        e.stopPropagation();
        showOrCollapseMenu("tag")
        addOrRemoveUnits(unit, "remove");
    }

    const showOrCollapseMenu = (action: "dropdown" | "tag") => {
        if (action === "dropdown") {
            setToggle(!toggle);
            setSearchText("");
        }
    }

    const onSearch = (text: string) => setSearchText(text.toLowerCase());


    useEffect(() => {
        if (isSuccess && data.length > 0) {
            dispatch(updateUnitSelection(data.slice(0, 1)[0]))
        }
        // eslint-disable-next-line
    }, [isSuccess])

    if (isSuccess && data.length === 0) {
        return <div className={`multiSelectOptions dropdown`} onClick={_ => showOrCollapseMenu("dropdown")} ref={node}>
            <div className="dropdown-trigger">
                <div className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span>No units available</span>
                </div>
            </div>
        </div>
    }
    
    return <div className={`multiSelectOptions units-dropdown dropdown ${toggle ? 'is-active': ''}`} onClick={_ => showOrCollapseMenu("dropdown")} ref={node}>
        <div className="dropdown-trigger">
            <div className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                {(isFetching && !isSuccess) ? 
                    <span className="mb-0">{placeholder}</span> : 
                    (selectedUnit.name ?
                        <span className="tag mb-0" onClick={e => tagSelection(selectedUnit, e)}>{selectedUnit.name} <i className="fas fa-close ml-2" aria-hidden="true"></i></span>
                    : 
                    <span className="mb-0">{placeholder}</span>)
                }
                <span className="icon is-small">
                    {toggle ? <i className="fas fa-angle-up" aria-hidden="true"></i> : <i className="fas fa-angle-down" aria-hidden="true"></i>}
                </span>
            </div>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="field mb-0 mt-2" onClick={e => e.stopPropagation()}>
                <p className="control has-icons-right">
                    <input className="input" type="text" placeholder="Search Unit" onInput={(e: any) => onSearch(e.target.value)} value={searchText ? searchText : ""} />
                    <span className="icon is-small is-right">
                        <i className="fas fa-search"></i>
                    </span>
                </p>
            </div>
            <div className="dropdown-content mt-2">
                {
                    searchText ?
                        data
                        .filter((unit:any) => unit?.name.toLowerCase().includes(searchText))
                        .map((unit: any) => <span 
                            className={`dropdown-item pt-2 pb-2 unit-list-item ${selectedUnit.id === unit.id ? 'selected-unit': ''}`} 
                            onClick={_ => optionSelection(unit)}
                        >
                        {unit?.name}
                        </span>)
                    :
                        data.map((unit: any) => <span 
                            style={{fontSize: "14px"}}
                            className={`dropdown-item pt-2 pb-2 unit-list-item ${selectedUnit.id === unit.id ? 'selected-unit': ''}`} 
                            onClick={_ => optionSelection(unit)}
                        >
                        {unit?.name}
                        </span>)
                }
            </div>
        </div>
    </div>;
}

export default UnitsDropdown;
