import React from "react";
import "./Legend.scss";

const Legend: React.FC<any> = () => {
  return (
    <>
      <div className="legend-holder">
        <div className="legend-holder">
          <span className="legend-vacated legend"></span>
          <span className="legend-text">Vacated</span>
        </div>
        <div className="legend-holder">
          <span className="legend-occupied legend"></span>
          <span className="legend-text">Arrived</span>
        </div>
        <div className="legend-holder">
          <span className="legend-upcoming legend"></span>
          <span className="legend-text">Upcoming</span>
        </div>
      </div>
    </>
  );
};

export default Legend;
