import React, { FunctionComponent } from "react";
import "./dashboardCard.scss";
import SearchSkeleton from "features/search-property/components/SearchSkeleton/SearchSkeleton";
const DashboardCard: FunctionComponent<any> = ({
  title,
  total,
  iconUrl,
  percentage,
  loading,
  showInfo,
  price,
  showLast,
  isRevenueEqual,
}) => {
  const renderPercentage = () => {
    if (percentage) {
      //   let currentValue = false; // For future reference

      if (percentage > 0) {
        // currentValue = true; // For future reference
        return (
          <div className={`percentage green`}>
            ({percentage}% ↑ than previous month )
          </div>
        );
      } else {
        return null;
        // currentValue = false;
      }

      // For future reference
      //   if (percentage !== 0) {
      //     return (
      //       <div className={`percentage ${currentValue ? "green" : "orange"}`}>
      //         {" "}
      //         ({percentage}% {currentValue ? "↑" : "↓"} than previous month )
      //       </div>
      //     );
      //   }
    }
  };

  const renderTitle = () => {
    if (price && title) {
      return `AED ${title}`;
    } else {
      return title;
    }
  };

  return (
    <div className="db-card">
      {loading ? (
        <SearchSkeleton></SearchSkeleton>
      ) : (
        <>
          <div className="icon-wrap" style={{ backgroundColor: iconUrl.color }}>
            <img src={iconUrl.icon} alt="icon" />
          </div>
          <div className="db-card-content">
            <div className={`card-title ${isRevenueEqual ? "statement" : ""}`}>
              {renderTitle()}{" "}
            </div>
            {!isRevenueEqual && renderPercentage()}
            <div className="total-count">
              {total}{" "}
              {showLast ? (
                <span className="sub-title">(Last Month)</span>
              ) : (
                <></>
              )}
            </div>
            {showInfo && (
              <span className="info">Calculated by the 15th of next month</span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardCard;
