import React, { useState, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import store from "store/store";
import i18n from "locale/translation";
import useLocalStorage from "hooks/useLocalStorage";

export const Context = React.createContext({});

let local = navigator.language;
let direction = "ltr";
if (local.includes("ar")) {
  local = "ar";
  direction="rtl";
} else {
  local = "en";
}

const Wrapper = (props: any) => {
  const [lang, setLang] = useLocalStorage("lang", local);
  // eslint-disable-next-line
  const [contDir, setContDir] = useLocalStorage("contentDir", direction);
  const rtlSwitch = () => {
    if (lang === "ar") {
      setContDir("rtl")
    } else {
      setContDir("ltr")
    }
  };
  useEffect(() => {
    setLang(lang || "en");
    i18n.changeLanguage(lang);
    rtlSwitch();
  });

  const [locale, setLocale] = useState(lang);
  function selectLanguage(locale: string): void {
    const newLocale: string = locale;
    setLocale(newLocale);
    setLang(newLocale || "en");
    i18n.changeLanguage(newLocale);
    rtlSwitch();
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>{props.children}</Provider>
      </I18nextProvider>
    </Context.Provider>
  );
};

export default Wrapper;
