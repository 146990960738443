import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTPMSG } from 'config/index';
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { put } from "services/api.service";

interface Response {
    loading: boolean;
    error: boolean;
    success: boolean;
    errorMessage: string;
    status: string;
    successMessage: string
}

interface Request {
    email: string;
    old_password: string;
    new_password: string;
    reenter_password: string;
}

const intialState: Response = {
    loading: false,
    error: false,
    errorMessage: '',
    status: 'pending',
    successMessage: '',
    success: false
}

export const changePassword: any = createAsyncThunk(
    "changePassword", async (payload: Request, { rejectWithValue }) => {
        try {
            const response: any = await put(payload, endPoints.USERS.CHANGEPASSWORD);
            return response.data;
        } catch (e:any) {
            return rejectWithValue(e.response.data);
        }
    })


const changePasswordSlice = createSlice({
    name: 'changePasswordAndVerifyNow',
    initialState: intialState,
    reducers: {
        clearState: (state) => {
            return intialState;
        },
    },
    extraReducers: {
        [changePassword.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = HTTPMSG[payload?.message || 'ERROR'];;
        },
        [changePassword.pending]: (state) => {
            state.loading = true;
        },
        [changePassword.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.status = "fulfilled";
            state.success = true;
            state.successMessage = HTTPMSG[payload.message];
        }
    }
});

export const { clearState } = changePasswordSlice.actions;
export const changePasswordStatus = (state: RootState) => state.changePassword;
const changePasswordReducer = changePasswordSlice.reducer;
export default changePasswordReducer;
