import SearchSkeleton from "features/search-property/components/SearchSkeleton/SearchSkeleton";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import "../../../dashboard/components/tableCard/table.scss";
import ReactPaginate from "react-paginate";
import "./ReservationTable.scss";

const ReservationTable: FunctionComponent<any> = ({
  config,
  tableData,
  loading,
}) => {
  const columns: any = useMemo(() => config, [config]);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const data = useMemo(() => currentItems, [currentItems]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );
  const [itemsPerPage, setItemPage] = useState(10);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(tableData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(tableData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, tableData]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % tableData?.length;
    setItemOffset(newOffset);
  };
  
  useEffect(()=>{
    setItemOffset(0);
  },[columns])
  
  return (
    <div className="table-card">
      <div className="table-card-header">
        <h4>
          {/* Should display 0 when table has no data */}
          Showing {tableData?.length ? itemOffset + 1 : 0} to {itemOffset + currentItems?.length} of{" "}
          {tableData?.length} entries
        </h4>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemPage(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <SearchSkeleton />
      ) : (
        <>
          <div className="table-wrap">
            <table {...getTableProps()} className="table is-fullwidth">
            <thead className="table-header">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}{" "}
                        <span className="table-sort-icon">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <i className="fa fa-sort-down"></i>
                              : <i className="fa fa-sort-up"></i>
                            : <i className="fa fa-sort"></i>}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>


          </div>
            {tableData.length === 0 && (
              <div className="no-data">No reservation found</div>
            )}
            {tableData.length !== 0 && (
              <ReactPaginate
                marginPagesDisplayed={10}
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                containerClassName="pagination"
                activeClassName="active"
                pageCount={pageCount}
                previousLabel="< previous"
              />
            )}
        </>
      )}
    </div>
  );
};

export default ReservationTable;
