import React, { useEffect, useState, useContext } from "react";
import "features/search-property/SearchProperty.scss";
import SearchDropDownAC from "./Search-DropDown/SearchDropDownAC";
import location from "assets/icons/search/location.svg";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "store/store";
import { currentlyActive, updateSelection, searchSelector, } from "features/search-property/searchproperty.slice";
import { locationsSelector, clearState, fetchTopLocations, } from "features/locations/locations.slice";
import { setToastState } from "components/Toast/Toast.slice";
import useClickOutside from "hooks/useClickOutside";
import { classSwitch } from "utils/classnameSwitch";
import { Context } from "components/app/Wrapper";

const LocationsDropdown: React.FC = () => {
    const context: any = useContext(Context);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { placesService } = useGoogle({ apiKey: process.env.REACT_APP_MAP_TOKEN });

    const { isNowActive, locations, locationsLists, isSearching } = useAppSelector(searchSelector);
    const { isError, errorMessage, locationList } = useAppSelector(locationsSelector);

    const [showloca, setLocation] = useState(false);
    const node = React.useRef<HTMLDivElement>(null);
    const toggleLOcations = () => setLocation(!showloca);

    useEffect(() => {
        showloca ? dispatch(currentlyActive("LOCATIONS")) : dispatch(currentlyActive(""));
    }, [showloca, dispatch, context.locale]);

    useEffect(() => {
        dispatch(fetchTopLocations())
    }, [dispatch, context.locale]);

    useEffect(() => {
        if (isError) {
            dispatch(setToastState({ type: "danger", message: errorMessage, dismiss: 3500 }));
            dispatch(clearState());
        }
    }, [isError, dispatch, errorMessage]);
    
    useClickOutside(node, () => {
        setLocation(false);
    });

    const selectLocation = (e: any) => {
        if (
            e.target.dataset.category === "gplace" &&
            e.target.dataset.placeid !== ""
        ) {
            placesService?.getDetails(
                {
                    placeId: e.target.dataset.placeid,
                },
                (placeDetails) => {
                    dispatch(
                        updateSelection({
                            category: "currentLocation",
                            value: e.target.title,
                            master: "locations",
                            lat: placeDetails?.geometry?.location?.lat(),
                            long: placeDetails?.geometry?.location?.lng(),
                            id: null
                        })
                    );
                }
            );
        } else if(e.target.dataset.category === 'popular') {
            const findId = locationList.find((item)=> item.name === e.target.title);
            dispatch(
                updateSelection({
                    category: "currentLocation",
                    value: e.target.title,
                    master: "locations",
                    id: findId?.id
                })
            );

        }else {
            dispatch(
                updateSelection({
                    category: "currentLocation",
                    value: e.target.title,
                    master: "locations",
                    id: e.target.dataset.id
                })
            );
        }
        toggleLOcations();
    };

    return (
        <div ref={node as any}>
            <SearchDropDownAC
                name="LOCATIONS"
                title={t('search.forStay')}
                subtitle={locations.currentLocation || "Location"}
                onClick={toggleLOcations}
                toggleView={isNowActive}
                image={location}
                contentClass={`n-calendar ${isSearching && locationsLists.length === 0 ? 'hidden' : ''}`}
                selectedValue={locations.currentLocation}
            >
                <>
                    {!isSearching && (
                        <div className="popular-locations">{t('bookAStay.popularLocation')}</div>
                    )}

                    {!isSearching && (
                        <>
                            {locationList.map((item: any, index: number) => (
                                <div
                                    className="item autoc-item"
                                    key={index}
                                    onClick={selectLocation}
                                    title={item.name}
                                    data-lat={item.latitude}
                                    data-long={item.longitude}
                                    data-category="popular"
                                    data-id={item.id}
                                >
                                    <div
                                        className="autoc-title"
                                        title={item.name}
                                        data-lat={item.latitude}
                                        data-long={item.longitude}
                                        data-category="popular"
                                        data-id={item.id}
                                    >
                                        <img
                                            src={location}
                                            alt="Location Icon"
                                            className={classSwitch("mr-3")}
                                        ></img>
                                        {item.name}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    {isSearching && (
                        <>
                            {locationsLists.map((item: any, index: number) => (
                                <div
                                    className="item autoc-item"
                                    key={index}
                                    onClick={selectLocation}
                                    title={item.structured_formatting.main_text}
                                    data-placeid={item.place_id ? item.place_id : ""}
                                    data-category= {item.place_id ? 'gplace' : 'popular'}
                                >
                                    <div
                                        className="autoc-title"
                                        title={item.structured_formatting.main_text}
                                        data-placeid={item.place_id ? item.place_id : ""}
                                        data-category= {item.place_id ? 'gplace' : 'popular'}
                                    >
                                        <img
                                            src={location}
                                            alt="Location Icon"
                                            className={classSwitch("mr-3")}
                                        ></img>
                                        {item.structured_formatting.main_text}
                                    </div>
                                    <div
                                        className="autoc-subtitle"
                                        title={item.structured_formatting.main_text}
                                        data-placeid={item.place_id ? item.place_id : ""}
                                        data-category= {item.place_id ? 'gplace' : 'popular'}
                                    >
                                        {item.structured_formatting.secondary_text}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </>
            </SearchDropDownAC>
        </div>
    );
};

export default LocationsDropdown;
