import "../../Home.scss";
import Button from "components/Button/Button";
import CarouselArea from "./Carousel";
import { useAppSelector } from "store/store";
import { locationsSelector } from "features/locations/locations.slice";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AreasBanner = () => {
  const { locationList, isSuccess } = useAppSelector(locationsSelector);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <div className="home-areas-banner">
        <div className="columns is-mobile">
          <div className="column is-2 is-hidden-mobile spacer-div"></div>
          <div className="column content-areas-banner">
            <div className="columns">
              <div className="column brand-quote is-half">
                <h1 className="home-areas-left">
                  <span className="highlights">{t('home.banner.exp')} </span>
                    {t('home.banner.dimension')}
                  <p>
                    <span className="highlights">{t('home.banner.comfort')} </span>{" "}
                    {t('home.banner.withus')}
                  </p>
                </h1>
                <h4 className="pt-3 pb-5 hg-desc">
                  {t('home.banner.sub1')}
                  <p>{t('home.banner.sub2')}</p>{" "}
                  {t('home.banner.sub3')}
                </h4>
                <Button
                  buttonText={t('home.banner.button')}
                  isPrimary={true}
                  classes="book-now-areas"
                  onClick={_ => history.push('/search-results')}
                ></Button>
              </div>
            </div>
          </div>
        </div>

        {isSuccess && (
          <CarouselArea locations={locationList}></CarouselArea>
        )}
      </div>
    </div>
  );
};

export default AreasBanner;
