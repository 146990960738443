import React, { FunctionComponent } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Dashboard from './dashboard/Dashboard';
import Reservation from './reservation/Reservation';
import AddProperty from './add-property/AddProperty';
import OwnerReport from './reports/OwnerReport';

const Owner: FunctionComponent = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}`} exact component={Dashboard} />
            <Route path={`${path}/reservation`} exact component={Reservation} />
            <Route path={`${path}/add-property`} exact component={AddProperty} />
            <Route path={`${path}/report`} exact component={OwnerReport} />
        </Switch>
    )
}

export default Owner;