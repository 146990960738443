import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "api/api";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";

interface CheckoutState {
  fetchedReservId: boolean;
  reservationid: string;
  checkoutErrors: string;
  fetchedCheckout: boolean;
  checkoutdetails: any;
  fetchingReserID: boolean;
  isBookingReady: boolean;
  expiry_time: string;
  reservationData: any;
}

export const initialState: CheckoutState = {
  fetchedReservId: false,
  fetchingReserID: false,
  reservationid: "",
  checkoutErrors: "",
  fetchedCheckout: false,
  checkoutdetails: {},
  isBookingReady: false,
  expiry_time: "",
  reservationData: {},
};
export const getReservationId: any = createAsyncThunk(
  "getreservationid",
  async (roomInfo, { rejectWithValue }) => {
    try {
      const response: any = await API.post(
        endPoints.CHECKOUT.BOOKNOW,
        roomInfo
      );
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);
export const getCheckoutDetails: any = createAsyncThunk(
  "getcheckoutdetail",
  async (reservid, { rejectWithValue }) => {
    try {
      const response: any = await API.post(
        endPoints.CHECKOUT.CHECKOUT,
        reservid
      );
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);
const checkoutSlice = createSlice({
  name: "managecheckout",
  initialState,
  reducers: {
    clearState: (state) => {
      state.reservationid = "";
      state.checkoutErrors = "";
      state.checkoutdetails = {};
      return state;
    },
    bookingReady: (state, { payload }) => {
      state.isBookingReady = payload;
      return state;
    },
  },
  extraReducers: {
    [getReservationId.rejected]: (state, { payload }) => {
      state.fetchedReservId = true;
      state.fetchingReserID = false;
      state.reservationid = "";
      state.checkoutErrors = payload.message;
    },
    [getReservationId.pending]: (state) => {
      state.fetchingReserID = true;
      state.checkoutErrors = "";
    },
    [getReservationId.fulfilled]: (state, { payload }) => {
      state.fetchingReserID = false;
      state.fetchedReservId = true;
      state.reservationid = payload.data.id;
      state.expiry_time = payload.data.expiry_time;
      state.reservationData = payload.data;
    },

    [getCheckoutDetails.rejected]: (state, { payload }) => {
      state.fetchingReserID = false;
      state.fetchedCheckout = true;
      state.checkoutdetails = "";
      state.checkoutErrors = payload.message;
    },
    [getCheckoutDetails.pending]: (state) => {
      state.fetchingReserID = true;
    },
    [getCheckoutDetails.fulfilled]: (state, { payload }) => {
      state.fetchingReserID = false;
      state.fetchedCheckout = true;
      state.checkoutdetails = payload.data;
    },
  },
});

export const { clearState, bookingReady } = checkoutSlice.actions;
export const checkoutSelector = (state: RootState) => state.checkout;
const checkoutReducer = checkoutSlice.reducer;
export default checkoutReducer;
