import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { HTTPMSG } from "config";
import { IMembership, IStayDetail } from "./stay-details.interface";
import { API } from "api/api";

interface StayDetailState {
  stayDetail: IStayDetail;
  isFetched: boolean;
  errorMessage: string;
  isPriceFetched: "fetching" | "success" | "error" | "idle";
  fetchComplete: boolean;
  price: IMembership | any;
  currentRoom: string;
  isWishlisted: boolean;
  wishlists: [];
  bookNowSelected: boolean;
  priceSelected: any;
  updateDates: boolean;
  isAccordianOpen: boolean;
  fundBtnSelected: string;
}

const data: any = {},
  price: any = {};

export const initialState: StayDetailState = {
  stayDetail: data,
  isFetched: false,
  errorMessage: "",
  isPriceFetched: "idle",
  price: price,
  fetchComplete: false,
  currentRoom: "",
  isWishlisted: false,
  wishlists: [],
  bookNowSelected: false,
  priceSelected: {},
  updateDates: false,
  isAccordianOpen: false,
  fundBtnSelected: ""
};

const fetchRoomDetailsAPI = (id: string) => {
  return new Promise((resolve, reject) => {
    if (id) {
      API.get(`${endPoints.STAY_DETAIL.ROOM_DETAILS}?room_category_slug=${id}`)
        .then((response) => resolve(response))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    }
  });
};

const fetchPricingDetailsAPI = (data: any) => {
  const body = data;
  return new Promise((resolve, reject) => {
    API.post(endPoints.STAY_DETAIL.RATE, body)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchRoomDetails: any = createAsyncThunk(
  "fetchStayDetails",
  async (id: string, { rejectWithValue }) => {
    try {
      const response: any = await fetchRoomDetailsAPI(id);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const fetchPricingDetails: any = createAsyncThunk(
  "pricingDetails",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await fetchPricingDetailsAPI(data);
      return response.data;
    } catch (e: any) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

const formatPriceData = (data: IMembership) => {
  // Member price available
  if (data?.member) {
    // Member refundable available but not non-refundable
    data.member.refundable &&
      !data.non_member.refundable &&
      (data.non_member.refundable = data.member.refundable);
    // Member refundable not available but non-refundable is available
    !data.member?.refundable &&
      data.non_member.refundable &&
      (data.member.refundable = data.non_member.refundable);
  }
  // Non-Member price available
  if (data?.non_member) {
    // Non-Member refundable available but not non-refundable
    data.member.non_refundable &&
      !data.non_member.non_refundable &&
      (data.non_member.non_refundable = data.member.non_refundable);
    // Non-Member refundable not available but non-refundable is available
    !data.member?.non_refundable &&
      data.non_member.non_refundable &&
      (data.member.non_refundable = data.non_member.non_refundable);
  }
  return data;
};

const stayDetailSlice = createSlice({
  name: "stayDetails",
  initialState,
  reducers: {
    clearFetchStatus: (state) => {
      state.fetchComplete = false;
    },
    setPriceOpen: (state, { payload }) => {
      state.isAccordianOpen = payload;
    },
    clearErrorMsg: (state) => {
      state.errorMessage = "";
    },
    updateWishlisted: (state, action) => {
      state.isWishlisted = action.payload;
    },
    setbookNowSelected: (state) => {
      state.bookNowSelected = true;
    },
    clearbookNowSelected: (state) => {
      state.bookNowSelected = false;
    },
    setPriceSelected: (state, action) => {
      state.priceSelected = action.payload;
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setStayDetails: (state, action) => {
      state.stayDetail = action.payload;
    },
    updateDates: (state, { payload }) => {
      state.updateDates = payload;
    },
    setCurrentRoom: (state, { payload }) => {
      state.currentRoom = payload;
    },
    currentFundBtnSelected: (state, { payload }) => {
      state.fundBtnSelected = payload;
    },
    clearStayDetails: (state) => {
      state.currentRoom = "";
      state.stayDetail = data;
      state.price = price;
      state.priceSelected = {};
      state.isFetched = false;
      state.isPriceFetched = "idle";
    }
  },
  extraReducers: {
    [fetchRoomDetails.pending]: (state, { payload }) => {
      state.isFetched = false;
    },
    [fetchRoomDetails.rejected]: (state, { payload }) => {
      state.isFetched = false;
      state.errorMessage = HTTPMSG[payload?.message || "INTERNAL_ERROR"];
    },
    [fetchRoomDetails.fulfilled]: (state, { payload }) => {
      state.isFetched = true;
      const { data } = payload;
      state.stayDetail = data;
      state.currentRoom = data.id;
    },
    [fetchPricingDetails.pending]: (state, { payload }) => {
      state.isPriceFetched = "fetching";
      state.errorMessage = "";
    },
    [fetchPricingDetails.rejected]: (state, { payload }) => {
      state.isPriceFetched = "error";
      state.errorMessage = HTTPMSG[payload?.message || "INTERNAL_ERROR"];
    },
    [fetchPricingDetails.fulfilled]: (state, { payload }) => {
      state.isPriceFetched = "success";
      const { data } = payload;
      state.price = formatPriceData(data);
      state.fetchComplete = true;
    },
  },
});

export const {
  clearFetchStatus,
  clearErrorMsg,
  updateWishlisted,
  setbookNowSelected,
  clearbookNowSelected,
  setPriceSelected,
  updateDates,
  setPriceOpen,
  setCurrentRoom,
  currentFundBtnSelected,
  clearStayDetails,
  setPrice,
  setStayDetails
} = stayDetailSlice.actions;
export const stayDetailSelector = (state: RootState) => state.stayDetail;
const stayDetailReducer = stayDetailSlice.reducer;
export default stayDetailReducer;
