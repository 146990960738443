import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { post} from "services/api.service";

const initialState: any = {
    loading: false,
    error: false,
    status: 'pending',
    data: null,
    hasLoaded: false
}

export const getData: any = createAsyncThunk(
    "getData", async (payload: Request, { rejectWithValue }) => {
        try {
            const response: any = await post(payload, endPoints.DASHBOARD.FETCHDATA);
            return response.data;
        } catch (e:any) {
            return rejectWithValue(e.response.data);
        }
    })


const getDataSlice = createSlice({
    name: 'getData',
    initialState: initialState,
    reducers: {
        clearState: (state) => {
            return initialState;
        },
    },
    extraReducers: {
        [getData.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
        },
        [getData.pending]: (state) => {
            state.loading = true;
        },
        [getData.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.status = "fulfilled";
            state.hasLoaded = true;
        }
    }
});

export const { clearState } = getDataSlice.actions;
export const getDataValue = (state: RootState) => state.getUnitData;
const getDataReducer = getDataSlice.reducer;
export default getDataReducer;
