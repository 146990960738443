import React from "react";
import { useTranslation } from "react-i18next";
import './TopBar.scss';
import Tabs from "features/profile/components/tabs/Tabs";
import { useAppSelector } from "store/store";
import { profile } from "features/profile/slices/profile.slice";
// import LoyaltyPoints from "features/profile/components/LoyaltyPoints";

interface TopBarProps {
    isRightContent?: boolean;
}

const fetchTitle = (selectedTab: string) => {
    
    switch(selectedTab) {
        case "personal-information": return "common.profile";
        case "coupons": return "profile.coupon";
        case "bookings": return "profile.booking";
        case "wishlist": return "profile.wishlist";
        default : return "common.profile";
    }
}

const TopBar: React.FC<TopBarProps> = ({isRightContent}) => {
    const { t } = useTranslation();
    const { selectedTab } = useAppSelector(profile);
    
    return (
        <div className="top-bar layout-banner-img layout-banner-mask" style={{minHeight: "auto", textAlign: "center"}}>
            <div className="top-content content-wrap">
                <h2 className="con-title">{t(fetchTitle(selectedTab))}</h2>
                {/* {isRightContent ? <LoyaltyPoints /> : null } */}
            </div>
            <div className="tabs-for-mobile">
                <Tabs />
            </div>
        </div>
    )
}

export default TopBar;
