import { API } from "api/api";
import { endPoints } from "api/endPoints";

export interface ForgotPasswordUserProps {
  email: string;
}

const forgotPassword = (data: ForgotPasswordUserProps) => {
  const body = data;
  return new Promise((resolve, reject) => {
    API.post(endPoints.USERS.FORGOT_PASSWORD, body)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
};

export const ForgotPasswordService = {
  forgotPassword: forgotPassword
};