import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";

interface LocationsState {
  locations: [];
  isLocationsFetched: boolean;
}

export const initialState: LocationsState = {
  locations: [],
  isLocationsFetched: false,
};
export const getLocations: any = createAsyncThunk(
  "locations",
  async (category: string) => {
    const rsp: any = await get((endPoints.HOME as any)[category]);
    return {
      data: rsp.data.data.locations,
    };
  }
);

const locationsSlice = createSlice({
  name: "areas",
  initialState,

  reducers: {},
  extraReducers: {
    [getLocations.pending]: (state, action) => {
      state.isLocationsFetched = false;
    },
    [getLocations.fulfilled]: (state, action) => {
      state.isLocationsFetched = true;
      state.locations = action.payload.data.locations;
    },
    [getLocations.rejected]: (state, action) => {
      state.isLocationsFetched = true;
      state.locations = [];
    },
  },
});
export const locationsSelector = (state: RootState) => state.locations;
const locationsReducer = locationsSlice.reducer;
export default locationsReducer;
