import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
interface IOffers {
  id: string;
  slug: string;
  property_name: string;
  description_2: string;
  description_1: string;
  description_3: string;
  image: { url: string };
  maximum_discount: string;
  expiry: string;
  property: {
    name: string,
    description: string,
  };
  offer_price: string;
  offer_description: {
    description_1: string
  };
  "arrival_date_gt": string;
  "departure_date_lt": string;
  "length_of_stay": number;
}
interface HomeState {
  offers: IOffers[];
  isOfersFetched: boolean;
}
export const initialState: HomeState = {
  offers: [],
  isOfersFetched: false,
};
//Handle mutliple landing request
export const getOffers: any = createAsyncThunk(
  "featuredOffers",
  async () => {
    const rsp: any = await get((endPoints.HOME.OFFERS));
    return {
      data: rsp.data.data
    };
  }
);

const offersSlice = createSlice({
  name: "userrating",
  initialState,

  reducers: {},
  extraReducers: {
    [getOffers.pending]: (state, action) => {
      state.isOfersFetched = false;
    },
    [getOffers.rejected]: (state, action) => {
      state.isOfersFetched = false;
      state.offers = [];
    },
    [getOffers.fulfilled]: (state, action) => {
      state.isOfersFetched = true;
      const { data } = action.payload;
      state.offers = data;
    },
  },
});

export const offerSelector = (state: RootState) => state.featuredOffers;
const offerReducer = offersSlice.reducer;
export default offerReducer;
