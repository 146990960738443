import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./Testimonials.scss";
import quotes from "assets/images/testimonials/quotes.png";
import Rating from "components/Rating/Rating";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppDispatch, useAppSelector } from "store/store";
import { getTestimonials, testiminialSelector } from "./testimonails.slice";
import profile from "assets/images/profile.png";
import { Context } from "components/app/Wrapper";
import { classSwitch, DirectionCheck } from "utils/classnameSwitch";

const images = require.context("assets/images/testimonials", false, /\.png$/);

const Testimonials = () => {
  const context: any = useContext(Context);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { testimonals, isTestimonailsFetched } =
    useAppSelector(testiminialSelector);
  useEffect(() => {
    dispatch(getTestimonials("TESTIMONIALS"));
  }, [dispatch, context.locale]);
  let imgindex = 1;

  const deviceReady = () => {
    let devicesetting: { [key: string]: any } = {};
    if (window.innerWidth < 580) {
      devicesetting = {
        dots: true,
        centerMode: true,
        className: "center",
        infinite: true,
        slidesToShow: 1,
        centerPadding: "10px",
        speed: 500,
        autoplay: true,
      };
    } else if (window.innerWidth > 580 && window.innerWidth < 1000) {
      devicesetting = {
        dots: true,
        centerMode: true,
        className: "center",
        infinite: true,
        slidesToShow: 1,
        centerPadding: "100px",
        speed: 500,
        autoplay: true,

        initialSlide: 1,
      };
    } else if (window.innerWidth > 1000 && window.innerWidth < 1300) {
      devicesetting = {
        dots: true,
        infinite: true,
        slidesToShow:  testimonals.length === 2 ? 2 : testimonals.length === 1 ? 2 : 3,
        speed: 500,
        autoplay: true,
      };
    } else if (window.innerWidth > 1300 && window.innerWidth < 1470) {
      devicesetting = {
        dots: true,
        className: "center",
        infinite: true,
        slidesToShow: testimonals.length ===1 ? 1 : testimonals.length ===2 ? 2 : 3,
        speed: 500,
        autoplay: true,
        initialSlide: 1,
      };
    } else if (window.innerWidth > 1470) {
      devicesetting = {
        dots: true,
        infinite: true,
        slidesToShow: testimonals.length ===1 ? 1 : testimonals.length === 2 ? 2 : 3,
        speed: 500,
        autoplay: true,
      };
    } else {
      devicesetting = {
        dots: true,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "300px",
        slidesToShow: 1,
        autoplay: true,
        speed: 500,
      };
    }
    devicesetting.rtl = context.locale === "ar"
    return devicesetting;
  };
  const [dimensions, setDimensions] = useState(deviceReady());
  useEffect(() => {
    function handleResize() {
      setDimensions(deviceReady());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(()=>{
    setDimensions(deviceReady());
     // eslint-disable-next-line
},[testimonals])

  const settings = {
    ...dimensions,
  };
  return (
    <>
      {testimonals.length ? (
        <div className="section home-testimonials" style={{ marginBottom: "40px" }}>
          <h1 className="has-text-centered all-title">
            {t('home.testimonials.title')}
          </h1>
          <div className="mt-4 pb-4">
            <div dir="ltr">
              <img
                src={quotes}
                alt="Quotation"
                className="upperQuotes"
              ></img>
            </div>
          </div>
          {isTestimonailsFetched &&
            <Slider {...settings}>
              {testimonals.map((item, index) => {
                if (imgindex > 2 && imgindex !== 1) {
                  imgindex = 1;
                }
                else {
                  ++imgindex;
                }
                return (
                  <div key={index} dir={DirectionCheck()}>
                    <div
                      key={index}
                      style={{
                        backgroundImage: `url(${images(`./image${imgindex}.png`)
                          .default
                          })`,
                      }}
                      className="testimonial-content"
                    >
                      <div>
                        <div className="align-start">
                          {item.user_image && <img className="user-img-testi"
                            src={item.user_image}
                            alt={item.username}
                          ></img>}
                          {!item.user_image && <img className="user-img-testi"
                            src={profile}
                            alt={item.username}
                          ></img>}
                          <span className={`${classSwitch('ml-3')} fw-500`}>{`${item.username}`}</span>
                        </div>
                        <div className="mt-4 review-title">"{item.review_title}"</div>
                        <div className="mtop-2 stay-content mb-3">
                          <span className="fw-500"> {t('home.testimonials.stayedAt')} </span>
                          <span className="stay fw-500">{item.room_category.nice_name || item.room_category.name}</span>
                        </div>
                        <div className="mb-3">
                          <Rating ratings={+item.overall_rating}></Rating>
                        </div>
                        <div className="testimonial-about">{item.review_description}</div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>}
          <div className="mt-4 pb-4">
            <div className="lowerQuotes">
              <img src={quotes} alt="Quotation"></img>
            </div>
          </div>
        </div>) : <> </>
      }
    </>

  );
};

export default Testimonials;
