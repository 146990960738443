import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";

export const initialState = {
  isDataFetched: false,
  ourServices: {
    maintitle: "",
    maintitledesc: "",
    subtitle: "",
    guest_fields: [],
    host_fields: []
  },
  meta: {
    content: "",
    keyWord: "",
    name: ""
  }
};

export const ourServicesData: any = createAsyncThunk(
  "ourServices",
  async () => {
    const rsp: any = await get((endPoints.STATIC.OUR_SERVICES));
    return {
      data: rsp.data.data
    };
  }
);
const ourServicesSlice = createSlice({
  name: "ourServices",
  initialState,
  reducers: {
  },
  extraReducers: {
    [ourServicesData.pending]: (state, action) => {
      state.isDataFetched = false;
    },
    [ourServicesData.fulfilled]: (state, action) => {
      state.isDataFetched = true;
      const { data } = action.payload;
      state.ourServices = data.data;
      state.meta = data.meta;
    },
    [ourServicesData.rejected]: (state, action) => {
      state.isDataFetched = true;
      state.ourServices = initialState.ourServices;
      state.meta = initialState.meta;
    },
  },
});

export const ourServicesSelector = (state: RootState) => state.ourServices;
const ourServicesReducer = ourServicesSlice.reducer;
export default ourServicesReducer;
