import React from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import './Tabs.scss';
import { profile, setTab } from "features/profile/slices/profile.slice";
import { useTranslation } from "react-i18next";
const Tabs: React.FC =(props: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { selectedTab } = useAppSelector(profile);
    
    const setSelectedTab = (key: string) => {
        dispatch(setTab(key));
    }

    return(
        <ul className="tabs-section">
            <span onClick={_ => setSelectedTab("personal-information")} className={`tab-item ${selectedTab.includes("personal-information") ? "selected": ""}`}>
                <i className="fas fa-user-circle"></i> <span>{t("profile.personal")}</span>
            </span>
            <span onClick={_ => setSelectedTab("coupons")} className={`tab-item ${selectedTab.includes("coupons") ? "selected": ""}`}>
                <i className="fas fa-tag"></i><span>{t("profile.coupon")}</span>
            </span>
            <span onClick={_ => setSelectedTab("bookings")} className={`tab-item ${selectedTab.includes("bookings") ? "selected": ""}`}>
                <i className="far fa-calendar-check"></i> <span>{t("profile.bookings")}</span>
            </span>
            <span  onClick={_ => setSelectedTab("wishlist")} className={`tab-item ${selectedTab.includes("wishlist") ? "selected": ""}`}>
                <i className="fas fa-heart"></i> <span>{t("profile.wishlist")}</span>
            </span>
        </ul>
    )
}

export default Tabs;
