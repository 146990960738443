import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTPMSG } from 'config/index';
import { endPoints } from "api/endPoints";
import { get, put } from "services/api.service";
import { RootState } from "store/rootReducer";
import { emailDecode } from "utils/encryption";

interface Response {
    loading: boolean;
    error: boolean;
    success: boolean;
    errorMessage: string;
    status: string;
    successMessage: string;
    data: any;
    selectedTab: string;
    isOwner: boolean;
    inOwnerDashboard: boolean;
    fetchingPoints: boolean;
    pointsFetchFailed: boolean;
    pointsFetchSuccess: boolean;
    loyaltyData: ILoyaltyPoints;
    displayLoyaltyError: boolean;
}

interface ILoyaltyPoints {
    enrolled: boolean;
    guestId: number;
    loyalty_level: string;
    points: string;
}

const initialState: Response = {
    loading: false,
    error: false,
    errorMessage: '',
    status: 'pending',
    successMessage: '',
    success: false,
    data: null,
    selectedTab: "personal-information",
    isOwner: false,
    inOwnerDashboard: false,
    fetchingPoints: false,
    pointsFetchFailed: false,
    pointsFetchSuccess: false,
    loyaltyData: {
        enrolled: false,
        guestId: 0,
        loyalty_level: "",
        points: "0"
    },
    displayLoyaltyError: false
}

export const fetchUserDetails: any = createAsyncThunk(
    "getProfile", async (payload: any, { rejectWithValue }) => {
        try {
            const response: any = await get(`${endPoints.USERS.PROFILE}`);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    })

export const UpdateUserDetails: any = createAsyncThunk(
    "updateProfile", async (payload: any, { rejectWithValue }) => {
        try {
            const response: any = await put(payload, `${endPoints.USERS.PROFILE}`);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    })

export const fetchLoyaltyPoints: any = createAsyncThunk(
    "loyaltyPoints", async (_, { rejectWithValue }) => {
        try {
            const response: any = await get(`${endPoints.USERS.LOYALTY_POINTS}`);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    }
);

const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        setTab: (state, { payload }) => {
            state.selectedTab = payload;
            localStorage.setItem("selectedTab", payload);
            return state;
        },
        isOwnerDashboard: (state, { payload }) => {
            state.inOwnerDashboard = payload;
            return state;
        },
        clearProfileState: (state) => {
            state.loading = false;
            state.error = false;
            state.errorMessage = '';
            state.status = 'pending';
            state.successMessage = '';
            state.success = false;
            return state;
        },
        clearProfileError: (state) => {
            state.displayLoyaltyError = true;
            return state;
        }
    },
    extraReducers: {
        [fetchUserDetails.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = HTTPMSG[payload?.message || 'ERROR'];
        },
        [fetchUserDetails.pending]: (state) => {
            state.loading = true;
        },
        [fetchUserDetails.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.status = "fulfilled";
            state.isOwner = payload.data.role.includes(2);
            state.data = { ...payload.data, email: emailDecode(payload?.data?.email) };
            localStorage.setItem(`${window.location.host}email_verified`, payload.data.is_email_verified);
        },
        [UpdateUserDetails.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = HTTPMSG[payload?.message || 'ERROR'];
        },
        [UpdateUserDetails.pending]: (state) => {
            state.loading = true;
        },
        [UpdateUserDetails.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.status = "fulfilled";
            state.data = { ...payload.data, email: emailDecode(payload?.data?.email) };;
            state.success = true;
            state.successMessage = HTTPMSG[payload?.message];
        },
        [fetchLoyaltyPoints.rejected]: (state, { payload }) => {
            state.fetchingPoints = false;
            state.pointsFetchFailed = true;
            state.errorMessage = payload?.message || 'ERROR';
            state.displayLoyaltyError = false;
        },
        [fetchLoyaltyPoints.pending]: (state) => {
            state.fetchingPoints = true;
        },
        [fetchLoyaltyPoints.fulfilled]: (state, { payload }) => {
            state.fetchingPoints = false;
            state.pointsFetchSuccess = true;
            state.loyaltyData = payload.data;
        },
    }
});

export const { setTab, clearProfileState,isOwnerDashboard, clearProfileError } = profileSlice.actions;
export const profile = (state: RootState) => state.profile;
const profileReducer = profileSlice.reducer;
export default profileReducer;
