import React from "react";
import "./ToggleSwitch.scss";
import { ReactComponent as ListIcon } from "assets/icons/search/list.svg"
import { ReactComponent as Calendar } from "assets/icons/search/calendar.svg"

const ToggleSwitch: React.FC<any> = ({ onSelect, selectedValue }) => {

  const handleChange = (evt: any, type: string) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (evt.target.checked) {
      onSelect({ selected: type })
    }
  }
  return (
    <>
      <div className="toggle-wrapper-owner">
        <input type="radio" name="slider" id="home" onChange={e => handleChange(e, 'scheduler')} checked={!selectedValue} />
        <input type="radio" name="slider" id="blog" onChange={e => handleChange(e, 'table')} checked={selectedValue} />
        <nav>
          <label htmlFor="home" className="home">
            <Calendar />
            Cal.
          </label>
          <label htmlFor="blog" className="blog">
            <ListIcon /> List
          </label>
          <div className="slider"></div>
        </nav>
      </div>
    </>
  );
};

export default ToggleSwitch;
