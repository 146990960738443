import SearchSkeleton from 'features/search-property/components/SearchSkeleton/SearchSkeleton';
import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useTable } from 'react-table'
import './table.scss';

const TableCard: FunctionComponent<any> = ({ config, tableData, loading,dateFilter,unitsFilter }) => {

    const history = useHistory();

    const data = useMemo(
        () => tableData,
        [tableData]
    );
    const columns: any = useMemo(
        () => config,
        [config]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    const navigateToReservation = () => {
        history.push(
            {
                pathname: '/owner/reservation',
                state: { 
                    dateFilter,
                    unitsFilter 
                }
            }
        );
        
    }

    return (
        <div className="table-card">
            <div className="table-card-header">
                <h4>Recent 10 Reservations</h4>
                <h4 className="view-all-link" onClick={navigateToReservation}>View All</h4>
            </div>
            {loading ? <SearchSkeleton /> :
                <>
                <div className="table-wrap">
                        <table {...getTableProps()} className="table is-fullwidth">
                            <thead className="table-header">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps()}
                                            >
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {data.length === 0 && <div className="no-data">No reservation found</div>}
                </div>
                   
                </>
            }
        </div>
    )
}

export default TableCard;