export const endPoints = {
  USERS: {
    LOGIN: "/api/user/login/",
    RESET_PASSWORD: "/api/user/reset_password/",
    FORGOT_PASSWORD: "/api/user/forgot_password/",
    SIGNUP: "/api/user/sign_up/",
    LOGINVIAGOOGLE: "/api/user/google_social_auth/",
    LOGINVIAFACEBOOK: "/api/user/facebook_social_auth/",
    PROFILE: "/api/user/user_profile/",
    CHANGEPASSWORD: "api/user/change_password/",
    VERIFYNOW: "/api/user/verify_now/",
    LOGOUT: "/api/user/logout/",
    SUBSCRIPTION: "/api/user/email_subscription/",
    EMAILVERIFICATION: "/api/user/email_verification/",
    REFRESHTOKEN: "/api/user/refresh_token/",
    GUEST_CHECKOUT: "/api/user/guest_checkout/",
    VERIFY_OTP: "/api/user/verify_otp/",
    LOYALTY_POINTS: "/api/user/loyalty_points",
  },
  CONTACT_US: "/api/contact_us/",
  HOME: {
    ATTRACTIONS: "/api/properties/featured_attractions/",
    OFFERS: "/api/payment/top_offers/",
    LOCATIONS: "/api/properties/locations/",
    TESTIMONIALS: "/api/properties/featured_user_rating/",
    PROPERTIES: "/api/properties/featured_properties/",
    LIST_PROPERTY: "/api/properties/list_a_property/",
    AGENT_REGISTRATION: "/api/agent_registration/",
  },
  FEATURED_STAY: "/api/properties/featured_staycations/",
  AMENITIES: "/api/properties/amenities/",
  PAYMENT: {
    ACTIVE_COUPONS: "/api/payment/active_coupons/",
  },
  SEARCH: "/api/properties/fetch/",
  OFFERS: {
    ALL: "/api/payment/offers/",
    MONTHLY: "/api/payment/monthly_offers/",
  },
  STAY_RESULTS: {
    FETCHALL: "/api/properties/fetch/",
  },
  STAY_DETAIL: {
    ROOM_DETAILS: "/api/properties/room_details/",
    RATE: "/api/properties/room_rate/",
  },
  WISHLIST: "/api/properties/wishlist/",
  BOOKING: "/api/booking/my_bookings",
  CHECKOUT: {
    BOOKNOW: "/api/booking/",
    CHECKOUT: "api/payment/checkout/",
    UPDATEPAY: "/api/payment/payment_update/",
  },
  CANCELBOOKING: "/api/booking/cancel_booking/",
  DASHBOARD: {
    GETUNITS: "/api/properties/owner_units",
    DATEDEPEND: "/api/admin_user/dashboard/dates",
    FETCHDATA: "/api/admin_user/dashboard/unit",
    ANALYTICS: "/api/analytics/",
    REPORTS: "/api/admin_user/owner_reports",
    MAINTENANCE: "/api/admin_user/dashboard/maintenance_cost",
  },
  RESERVATIONS: {
    LIST: "/api/admin_user/owner_reservations",
  },
  REFERRAL: {
    ACTIVE: "/api/is_referral_active/",
    REFER_FRIEND: "/api/user/refer_a_friend/",
  },
  WEB_CHECK_IN: {
    BOOKING_DETAILS: "/api/booking/pre_check_in/",
    CHECK_IN: "/api/booking/pre_check_in/",
  },
  STATIC: {
    ABOUT_US: "/api/static_page/?type=1",
    OUR_SERVICES: "/api/static_page/?type=3",
    CANCELLATION: "/api/static_page/?type=4",
    PAYMENT_REFUND: "/api/static_page/?type=5",
  },
};
