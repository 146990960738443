import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import "features/search-property/SearchProperty.scss";
import SearchDropDown from "./Search-DropDown/SearchDropDown";
import { Calendar } from "react-date-range";
import { useAppDispatch, useAppSelector } from "store/store";
import checkincal from "assets/icons/search/checkin.svg";
import { format } from "date-fns";
import {
  currentlyActive,
  updateSelection,
  searchSelector,
} from "features/search-property/searchproperty.slice";
import useClickOutside from "hooks/useClickOutside";
import { useLocation } from "react-router";
import { updateDates } from "features/stay-details/stay-details.slice";
import { Context } from "components/app/Wrapper";

const CheckInDropDown: React.FC = () => {
  const context: any = useContext(Context);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isNowActive, checkin, checkout } = useAppSelector(searchSelector);
  const [fromdate, setFromDate] = useState(checkin.currentFrom !== '' ? new Date(checkin.currentFrom) : null);
  const [show, setCheckin] = useState(false);
  const node = React.useRef<HTMLDivElement>(null);
  // eslint-disable-next-line
  const [yesterday, setYesterday] = useState(new Date());
  const location = useLocation().pathname;

  useEffect(() => {
    checkout.currentTo ? setYesterday(new Date(checkout.currentTo)) : setYesterday(new Date());
  }, [checkout.currentTo])
  const toggle = () => {
    setCheckin(!show);
  }
  useEffect(() => {
    if (show) {
      dispatch(currentlyActive("CHECKIN"));
    } else {
      dispatch(currentlyActive(""));
    }
  }, [show, dispatch, context.locale]);

  // Set selected date/today
  useEffect(() => {
    checkin.currentFrom ? setFromDate(new Date(checkin.currentFrom)) : setYesterday(new Date());
  }, [checkin.currentFrom]);

  useClickOutside(node, () => { setCheckin(false); })
  const handleValueChange = (date: any) => {
    const formattedDate = format(new Date(date), "dd LLL, yyyy");
    const requestDate = format(new Date(date), 'yyyy-MM-dd');
    setFromDate(date);
    dispatch(
      updateSelection({
        category: "currentFrom",
        value: formattedDate,
        formattedD: requestDate,
        master: "checkin",
      })
    );
    toggle();
    if (location.includes('/stay-details')) {
      dispatch(updateDates(false));
    }
  };
  return (

    <div ref={node as any} dir='ltr' className="checkIn">
      <SearchDropDown
        name="CHECKIN"
        title={t('search.from')}
        onClick={toggle}
        toggleView={isNowActive}
        subtitle={checkin.currentFrom || t("search.checkIn")}
        image={checkincal}
        selectedValue={checkin.currentFrom}
      >
        <Calendar
          onChange={(item: any) => handleValueChange(item)}
          date={fromdate as any}
          minDate={new Date()}
        />
      </SearchDropDown>
    </div>
  );
};

export default CheckInDropDown;
