import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "services/get.service";
import { endPoints } from "api/endPoints";
import { ITestimonials } from "./testimonial.interface";
import { RootState } from "store/rootReducer";

interface HomeState {
  testimonals: ITestimonials[];
  isTestimonailsFetched: boolean;
}
export const initialState: HomeState = {
  testimonals: [],
  isTestimonailsFetched: false,
};
export const getTestimonials: any = createAsyncThunk(
  "userTestimonials",
  async (category: string) => {
    const rsp: any = await get((endPoints.HOME as any)[category]);
    return {
      data: rsp.data.data.user_ratings,
      category,
    };
  }
);

const testimonialSlice = createSlice({
  name: "userrating",
  initialState,

  reducers: {},
  extraReducers: {
    [getTestimonials.fulfilled]: (state, action) => {
      state.isTestimonailsFetched = true;
      const { data } = action.payload;
      state.testimonals = data;
    },
  },
});

export const testiminialSelector = (state: RootState) => state.testimonial;
const testimonialReducer = testimonialSlice.reducer;
export default testimonialReducer;
