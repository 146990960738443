import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "api/api";
import { endPoints } from "api/endPoints";
import { RootState } from "store/rootReducer";
import { WishlistsProps } from "./wishlist.interface";

interface WishlistsState {
  fetchedWishlist: boolean;
  wishlists: WishlistsProps[];
  fetchingWishlist: boolean;
}

export const initialState: WishlistsState = {
  fetchedWishlist: false,
  wishlists: [],
  fetchingWishlist: false,
};

export const getWishlists: any = createAsyncThunk(
  "fetchwishlist",
  async (id: string) => {
    const rsp: any = await API.get(endPoints.WISHLIST);
    return {
      data: rsp.data.data,
    };
  }
);
const wishListsSlice = createSlice({
  name: "manageWishLists",
  initialState,
  reducers: {},
  extraReducers: {
    [getWishlists.rejected]: (state, { payload }) => {
      state.fetchingWishlist = false;
      state.fetchedWishlist = true;
      state.wishlists = [];
    },
    [getWishlists.pending]: (state) => {
      state.fetchingWishlist = true;
    },
    [getWishlists.fulfilled]: (state, { payload }) => {
      state.fetchingWishlist = false;
      state.fetchedWishlist = true;
      state.wishlists = payload.data.wishlist_room_categories;
    },
  },
});

export const wishListProfileSelector = (state: RootState) =>
  state.profilewishlist;
const wishListProfileReducer = wishListsSlice.reducer;
export default wishListProfileReducer;
