import React from "react";
import Routes from "routes/Router";
import { Router } from "react-router-dom";
import history from "routes/history";
import "./App.scss";
import Toast from "components/Toast/Toast";
import PhoneSVG from "assets/icons/Phone.svg";
import WhatsappSVG from "assets/icons/whatsapp.svg";

const App: React.FC = (props) => {
  return (
    <section>
      <Router history={history}>
        <Routes />
        <Toast />
      </Router>
      <div className="widget-background">
      <a
        className="phone-widget"
        href="tel:+97142100450"
        target="_blank"
        rel="noreferrer"
      >
        <img src={PhoneSVG} alt="phone call icon" />
      </a>
      <a
        className="whatsapp-widget"
        href="https://api.whatsapp.com/send?phone=97142100450"
        target="_blank"
        rel="noreferrer"
      >
        <img src={WhatsappSVG} alt="whatsapp chat icon" />
      </a>
      </div>
    </section>
  );
};

export default App;
